import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React from "react";
import "./CreateEmployee.sass";
import GenericModal from "components/General/GenericModal/GenericModal";
import generateRandomPassword from "helpers/generate_password";
import { useAppContext } from "contexts/AppContext";
import { checkResponse, getResponseError } from "helpers/requests";
import toast from "react-hot-toast";
import { formatDate } from "helpers/date_formaters";

export default function CreateEmployee() {
  // Variables globales
  const { pharmacy, userToken } = useAppContext();
  // Variables del form
  const [name, setName] = React.useState("");
  const [lastName, setlastName] = React.useState("");
  const [role, setRole] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  // Navigate
  const navigate = useNavigate();
  function goToUsersPage() {
    navigate(-1);
  }

  // Crear usuario

  async function createUser() {
    try {
      // Generar contraseña temporal
      const tempPassword = generateRandomPassword();
      setPassword(tempPassword);
      // Crear body del request
      const body = {
        pharmacy_id: pharmacy.pharmacy_id,
        name,
        last_name: lastName,
        mail: email,
        password: tempPassword,
        role,
        employee_photo: null,
        phone_num: "-",
        created_at: formatDate(new Date()),
      };
      // request headers
      const config = {
        headers: {
          authToken: userToken,
        },
      };

      // // Hacer peticion
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/auth/pos/create-employee`;
      const res = await axios.post(url, body, config);
      // // Levantar errrores en caso de que los haya
      console.log(res);
      if (!checkResponse(res)) throw res;

      setIsModalOpen(true);
    } catch (res) {
      console.log("CREATE EMPLOYEE ERROR:", res);
      toast.error(getResponseError(res));
    }
  }

  return (
    <div className="register-employee">
      <PageContainer fitWidth>
        {/* Go Back Btn */}
        <BackPageBtn noMargin />

        {/* Título e instrucciones */}
        <div className="header">
          <h1>Añadir Empleado</h1>
          <p>
            Al crear la cuenta se generara una contraseña temporal, porfavor
            compartela con el usuario para que pueda iniciar sesión más
            adelante.
          </p>
        </div>

        {/* Created account modal */}
        {isModalOpen && (
          <GenericModal
            title="Cuenta Creada"
            hideCancelButton
            onContinue={() => goToUsersPage()}
          >
            <p>
              Antes de continuar, asegurate de compartir los datos de la cuenta
              con{" "}
              <b>
                {name} {lastName}
              </b>
            </p>
            <br />
            <p>
              <b>Correo Electrónico: </b>
              {email}
            </p>
            <br />
            <p>
              <b>Contraseña: </b>
              {password}
            </p>
          </GenericModal>
        )}

        {/* Formularios */}
        <div className="forms">
          {/* Formulario de Datos */}
          <form className="data_form">
            <TextField
              label="Nombre"
              value={name}
              variant="outlined"
              className="text_input"
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label="Apellido"
              value={lastName}
              variant="outlined"
              className="text_input"
              onChange={(e) => setlastName(e.target.value)}
            />
            <TextField
              label="Correo Electrónico"
              variant="outlined"
              value={email}
              className="text_input"
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControl className="select_input">
              <InputLabel id="role-label">Rol</InputLabel>
              <Select
                labelId="role-label"
                label="Rol"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="pharmacy_admin">
                  Administrador de Farmacia
                </MenuItem>
                <MenuItem value="employee">Empleado</MenuItem>
              </Select>
            </FormControl>
          </form>
        </div>
        <div className="btn_container">
          <Button variant="contained" size="large" onClick={() => createUser()}>
            Crear cuenta
          </Button>
        </div>
      </PageContainer>
    </div>
  );
}
