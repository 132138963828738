import React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import LoadingComponent from "components/General/LoadingComponent/LoadingComponent";
import "./CheckoutForm.sass";
import { Button } from "@mui/material";
import toast from "react-hot-toast";
// import EmptyStateScreen from "components/General/EmptyStateScreen/EmptyStateScreen";
import { useAppContext } from "contexts/AppContext";
import { formatDate } from "helpers/date_formaters";
import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";
import { useNavigate } from "react-router-dom";

function CheckoutForm({ isLoading, ammount }) {
  const { pharmacy, userToken } = useAppContext();
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  async function registerSuccesfullPaymentInDb() {
    try {
      // Generar contraseña temporal
      const floatPaymentAmmount = parseFloat(ammount);

      // Crear body del request
      const body = {
        ammount: floatPaymentAmmount,
        movement_type: "payment",
        created_at: formatDate(new Date()),
      };
      // request headers
      const config = {
        headers: {
          authToken: userToken,
        },
      };

      // Hacer peticion
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/account-movements/${pharmacy.pharmacy_id}`;
      const res = await axios.post(url, body, config);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;
      // Decrementar la deuda de la farmacia
      pharmacy.debt -= floatPaymentAmmount;
      pharmacy.status = "active";
    } catch (error) {
      console.log("CREATE MOVEMENT ERROR:", error);
      toast.error(getResponseError(error));
    }
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/my-pharmacy/account-statement",
      },
      redirect: "if_required",
    });

    if (result.error) {
      toast.error(result.error.message);
    } else {
      // Registrar pago exitoso en db
      await registerSuccesfullPaymentInDb();
      navigate(-1);
      toast.success("Se realizo el pago correctamente");
    }
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <div className="checkout-body">
      <div className="img_container">
        <img src="/imgs/login_doctors.svg" alt="Doctors" />
        <p>¡Gracias por usar MF360!</p>
      </div>
      <form className="checkout-form" onSubmit={handleSubmit}>
        <PaymentElement />
        <Button
          variant="contained"
          size="large"
          disabled={!stripe || isLoading}
          onClick={handleSubmit}
          className="pay-btn"
        >
          Pagar ${ammount}
        </Button>
      </form>
    </div>
  );
}

export default CheckoutForm;
