import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import PageContainer from "components/General/PageContainer/PageContainer";
import ToolsBar from "components/General/ToolsBar/ToolsBar";
import { useAppContext } from "contexts/AppContext";
import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import LoadingComponent from "components/General/LoadingComponent/LoadingComponent";
import EditIcon from "@mui/icons-material/Edit";
import "./CashRegisters.sass";
import toast from "react-hot-toast";
import GenericModal from "components/General/GenericModal/GenericModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { validateInputs } from "helpers/requests";
import {
  addCashRegister,
  editCashRegister,
  getCashRegisters,
  setCashRegisterActiveStatus,
} from "./cashRegistersActions";

export default function CashRegisters() {
  // Vaiables
  const { pharmacy, userToken } = useAppContext();
  const [cashRegisters, setCashRegisters] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [editItemData, setEditItemData] = React.useState(null);

  // Modals
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [addInputValue, setAddInputValue] = React.useState("");
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [editInputValue, setEditInputValue] = React.useState("");
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [printerType, setPrinterType] = React.useState("usb");
  const [printerIpAddress, setPrinterIpAddress] = React.useState("");

  // Add cash register handler
  async function addCashRegisterHandler() {
    // Validate
    if (!validateInputs([addInputValue, printerType])) {
      toast.error("Debes llenar todos los campos");
      return;
    }
    if (printerType === "wifi" && !validateInputs([printerIpAddress])) {
      toast.error("Debes llenar todos los campos");
      return;
    }

    // Make request
    const ipAddress = printerType === "wifi" ? printerIpAddress : null;
    const res = await addCashRegister(
      pharmacy.pharmacy_id,
      userToken,
      addInputValue,
      printerType,
      ipAddress
    );
    if (!res.success) {
      toast.error(res.msg);
      return;
    }
    toast.success("Caja registradora añadida");

    // Update context
    setCashRegisters([
      ...cashRegisters,
      {
        cash_register_id: res?.cash_register_id,
        name: addInputValue,
        printer_type: printerType,
        printer_ip_address: ipAddress,
      },
    ]);
    setAddInputValue("");
    setPrinterType("usb");
    setPrinterIpAddress("");
    setShowAddModal(false);
  }

  // Edit cash register handler
  async function editInputValueHandler() {
    // Validate
    if (!validateInputs([editInputValue, printerType])) {
      toast.error("Debes llenar todos los campos");
      return;
    }
    if (printerType === "wifi" && !validateInputs([printerIpAddress])) {
      toast.error("Debes llenar todos los campos");
      return;
    }

    // Make request
    const ipAddress = printerType === "wifi" ? printerIpAddress : null;
    const res = await editCashRegister(
      editItemData.cash_register_id,
      editInputValue,
      printerType,
      ipAddress,
      userToken
    );
    if (!res.success) {
      toast.error(res.msg);
      return;
    }
    toast.success("Caja registradora editada correctamente");

    // Cambiar caja en el contexto
    const filteredCashRegisters = cashRegisters.filter(
      (c) => c.cash_register_id !== editItemData.cash_register_id
    );
    setCashRegisters([
      ...filteredCashRegisters,
      {
        ...editItemData,
        name: editInputValue,
        printer_type: printerType,
        printer_ip_address: ipAddress,
      },
    ]);
    setEditInputValue("");
    setPrinterType("usb");
    setPrinterIpAddress("");
    setShowEditModal(false);
  }

  // Edit cash register handler
  async function deleteCashRegisterHandler() {
    // Validate
    if (!validateInputs([deleteId])) {
      toast.error("Hubo un error eliminando la caja");
      return;
    }

    const res = await setCashRegisterActiveStatus(deleteId, 0, userToken);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }
    toast.success("Caja registradora eliminada");

    // Cambiar cajas en el contexto
    const filteredCashRegisters = cashRegisters.filter(
      (c) => c.cash_register_id !== deleteId
    );
    setCashRegisters(filteredCashRegisters);
    setDeleteId(null);
    setShowDeleteModal(false);
  }

  // Get cash registers handler
  async function getCashRegistersHandler() {
    const res = await getCashRegisters(pharmacy.pharmacy_id, userToken);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }
    setCashRegisters(res.data);
    setLoading(false);
  }

  // On render
  React.useEffect(() => {
    getCashRegistersHandler();
  }, []);

  return (
    <PageContainer>
      {/* ToolsBar */}
      <ToolsBar>
        <Button
          variant="outlined"
          size="small"
          className="tool_button"
          startIcon={<AddIcon />}
          onClick={() => setShowAddModal(true)}
        >
          Añadir Caja
        </Button>
        <Link to="/cash-registers/closure-requests">
          <Button variant="outlined" size="small" className="tool_button">
            Solicitudes de Cierre
          </Button>
        </Link>
      </ToolsBar>

      {/* Container */}
      <div className="container">
        {!loading ? (
          <div className="cash_registers_list">
            <div className="headers">
              <p className="c1">Nombre de Caja</p>
            </div>

            {/* Cash Registers List */}
            {cashRegisters?.length > 0 ? (
              cashRegisters?.map((c) => (
                <div className="item" key={c.cash_register_id}>
                  <p className="c1">{c.name}</p>

                  <div className="c2">
                    <IconButton
                      variant="solid"
                      onClick={() => {
                        setEditInputValue(c.name);
                        setPrinterType(c.printer_type);
                        setPrinterIpAddress(c.printer_ip_address || "");
                        setEditItemData(c);
                        setShowEditModal(true);
                      }}
                    >
                      <EditIcon className="icon" />
                    </IconButton>
                    <IconButton
                      variant="solid"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setDeleteId(c.cash_register_id);
                      }}
                    >
                      <DeleteIcon className="icon" />
                    </IconButton>
                  </div>
                </div>
              ))
            ) : (
              <p className="no_data">No hay cajas registradoras</p>
            )}
          </div>
        ) : (
          <LoadingComponent />
        )}
      </div>

      {/* Add Cash Register Modal */}
      {showAddModal && (
        <GenericModal
          title="Agregar Caja Registradora"
          onContinue={addCashRegisterHandler}
          onCancel={() => setShowAddModal(false)}
        >
          <TextField
            sx={{ marginBottom: "20px" }}
            fullWidth
            label="Nombre de Caja Registradora"
            variant="outlined"
            value={addInputValue}
            onChange={(e) => setAddInputValue(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="printer_type_add">Tipo de Impresora</InputLabel>
            <Select
              labelId="printer_type_add"
              label="Tipo de Impresora"
              value={printerType}
              onChange={(e) => setPrinterType(e.target.value)}
            >
              <MenuItem value="usb">USB</MenuItem>
              <MenuItem value="wifi">Wifi</MenuItem>
              <MenuItem value="bluetooth">Bluetooth</MenuItem>
            </Select>
          </FormControl>
          {printerType === "wifi" && (
            <TextField
              sx={{ marginTop: "20px" }}
              fullWidth
              label="Dirección IP de Impresora"
              variant="outlined"
              value={printerIpAddress}
              onChange={(e) => setPrinterIpAddress(e.target.value)}
            />
          )}
        </GenericModal>
      )}

      {/* Edit Cash Register Modal */}
      {showEditModal && (
        <GenericModal
          title="Editar Caja Registradora"
          onContinue={editInputValueHandler}
          onCancel={() => setShowEditModal(false)}
        >
          <TextField
            sx={{ marginBottom: "20px" }}
            fullWidth
            label="Nombre de Caja Registradora"
            variant="outlined"
            value={editInputValue}
            onChange={(e) => setEditInputValue(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="printer_type_add">Tipo de Impresora</InputLabel>
            <Select
              labelId="printer_type_add"
              label="Tipo de Impresora"
              value={printerType}
              onChange={(e) => setPrinterType(e.target.value)}
            >
              <MenuItem value="usb">USB</MenuItem>
              <MenuItem value="wifi">Wifi</MenuItem>
              <MenuItem value="bluetooth">Bluetooth</MenuItem>
            </Select>
          </FormControl>
          {printerType === "wifi" && (
            <TextField
              sx={{ marginTop: "20px" }}
              fullWidth
              label="Dirección IP de Impresora"
              variant="outlined"
              value={printerIpAddress}
              onChange={(e) => setPrinterIpAddress(e.target.value)}
            />
          )}
        </GenericModal>
      )}

      {/* Delete Cash Register Modal */}
      {showDeleteModal && (
        <GenericModal
          title="Eliminar Caja Registradora"
          onContinue={deleteCashRegisterHandler}
          onCancel={() => setShowDeleteModal(false)}
        >
          <p className="label">¿Está seguro que quiere eliminar esta caja?</p>
        </GenericModal>
      )}
    </PageContainer>
  );
}
