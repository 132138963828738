/* eslint-disable react/jsx-props-no-spreading */
import { Add } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { useAppContext } from "contexts/AppContext";
import getProductData from "helpers/get_product";
import { validateInputs } from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import shortid from "shortid";
import "./AddProducts.sass";

export default function AddProducts({ products, setProducts }) {
  // Variables
  const { inventory } = useAppContext();

  // Inputs
  const [sku, setSku] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [cost, setCost] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [batch, setBatch] = React.useState("");
  const [expiracyDate, setExpiracyDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );

  // Add product
  async function addProduct() {
    // Validate fields
    if (!validateInputs([sku, quantity, cost, price, batch, expiracyDate])) {
      toast.error("Debes llenar todos los campos");
      return;
    }

    const productRes = await getProductData(sku, inventory);

    if (!productRes.success || !productRes.product) {
      toast.error("No se encontró un producto con ese código de barras");
      return;
    }

    const newProduct = {
      ...productRes.product,
      price: parseFloat(price),
      cost: parseFloat(cost),
      quantity: parseFloat(quantity),
      batch,
      expiracy_date: expiracyDate,
    };
    const newProducts = [
      ...products,
      { ...newProduct, key: shortid.generate() },
    ];
    localStorage.setItem("MF360-Invoice-Products", JSON.stringify(newProducts));
    setProducts(newProducts);

    setSku("");
    setPrice("");
    setCost("");
    setQuantity("");
    setBatch("");
    setExpiracyDate(new Date().toISOString().split("T")[0]);
  }

  return (
    <div className="add_invoice_product">
      {/* Title */}
      <h3>Añadir Producto</h3>

      {/* Inputs */}
      <div className="product-form">
        <div className="inputs">
          <TextField
            label="Código de Barras"
            variant="outlined"
            className="text_input"
            value={sku}
            size="small"
            onChange={(e) => setSku(e.target.value)}
            fullWidth
          />
          <TextField
            label="Cantidad"
            variant="outlined"
            className="text_input"
            size="small"
            value={quantity}
            type="number"
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
          />
          <TextField
            label="Costo Unitario"
            variant="outlined"
            className="text_input"
            type="number"
            size="small"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            fullWidth
          />
          <TextField
            label="Precio de Venta"
            variant="outlined"
            className="text_input"
            value={price}
            type="number"
            size="small"
            onChange={(e) => setPrice(e.target.value)}
            fullWidth
          />
          <TextField
            label="Lote"
            variant="outlined"
            className="text_input"
            value={batch}
            size="small"
            onChange={(e) => setBatch(e.target.value)}
            fullWidth
          />
          <TextField
            label="Fecha de Expiración"
            variant="outlined"
            className="text_input"
            value={expiracyDate}
            type="date"
            size="small"
            onChange={(e) => setExpiracyDate(e.target.value)}
            fullWidth
          />
        </div>
      </div>

      {/* Button */}
      <div className="btn_container">
        <Button
          variant="contained"
          color="primary"
          className="add_btn"
          onClick={addProduct}
          startIcon={<Add />}
          fullWidth
        >
          Añadir Producto
        </Button>
      </div>
    </div>
  );
}
