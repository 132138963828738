import { Button, TextField } from "@mui/material";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./ChangePassword.sass";

export default function ChangePassword({ newUserMode, nextNewUserStep }) {
  // Variables
  const { user, userToken } = useAppContext();
  const [curPassword, setCurPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  // Tools
  const navigate = useNavigate();

  // Change password
  async function changePassword() {
    try {
      // Validate fields
      if (!validateInputs([curPassword, newPassword, confirmPassword])) {
        toast.error("Debes llenar todos los campos");
        return;
      }

      // Check passwords match
      if (newPassword !== confirmPassword) {
        toast.error("Las contraseñas no coinciden");
        return;
      }

      // Make request
      const headers = { authToken: userToken };
      const url = `${process.env.REACT_APP_API_URL}/employees/${user.employee_id}/password`;
      const body = {
        cur_password: curPassword,
        new_password: newPassword,
      };
      const res = await axios.put(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;

      user.status = "active";

      // Go back or go next step
      toast.success("Contraseña actualizada correctamente");
      if (newUserMode) nextNewUserStep();
      else navigate(-1);
    } catch (res) {
      console.log("CHANGE PASSWORD ERROR:", res);
      toast.error(
        getResponseError(res, "Hubo un error actualizando la contraseña")
      );
    }
  }

  return (
    <PageContainer fitWidth>
      <div className="change_password">
        {/* Back Button */}
        {!newUserMode && (
          <div className="back_btn_container">
            <BackPageBtn noMargin />
          </div>
        )}

        {/* Title */}
        <h1>Cambiar Contraseña</h1>

        {/* Info */}
        <div className="card-info">
          <div className="row">
            <TextField
              type="password"
              label="Contraseña Actual"
              variant="standard"
              className="input"
              value={curPassword}
              onChange={(e) => setCurPassword(e.target.value)}
            />

            <TextField
              type="password"
              label="Nueva Contraseña"
              variant="standard"
              className="input"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>

          <div className="row">
            <TextField
              type="password"
              label="Confirmar Contraseña"
              variant="standard"
              className="input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>

        <Button onClick={changePassword} variant="contained" className="button">
          Cambiar Contraseña
        </Button>
      </div>
    </PageContainer>
  );
}
