import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import "./InvoiceForm.sass";

export default function InvoiceForm({
  providers,
  invoiceProvider,
  setInvoiceProvider,
  invoiceTotalCost,
  setInvoiceTotalCost,
  invoiceDate,
  setInvoiceDate,
}) {
  return (
    <div className="invoice_form">
      {/* Title */}
      <h3>Datos de Factura</h3>

      {/* Inputs */}
      <div className="invoice_inputs">
        <FormControl fullWidth size="small">
          <InputLabel id="provider-select">Proveedor</InputLabel>
          <Select
            labelId="provider-select"
            label="Proveedor"
            value={invoiceProvider}
            onChange={(e) => setInvoiceProvider(e.target.value)}
          >
            {providers?.map((p) => (
              <MenuItem key={p.provider_id} value={p.provider_id}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Costo Total"
          variant="outlined"
          type="number"
          size="small"
          value={invoiceTotalCost}
          onChange={(e) => setInvoiceTotalCost(e.target.value)}
        />

        <TextField
          label="Fecha de la Factura"
          variant="outlined"
          type="date"
          size="small"
          value={invoiceDate}
          onChange={(e) => setInvoiceDate(e.target.value)}
        />
      </div>
    </div>
  );
}
