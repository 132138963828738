/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import "./SalesTable.sass";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAppContext } from "contexts/AppContext";
import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";
import toast from "react-hot-toast";
import ChangePageBtn from "components/General/ChangePageBtn/ChangePageBtn";
import DownloadIcon from "@mui/icons-material/Download";
import {
  formatDate,
  getDateString,
  getTimeFromDate,
} from "helpers/date_formaters";
import { Button, IconButton } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { downloadExcel } from "helpers/excel";

export default function SalesTable() {
  const { pharmacy, userToken, setOrderOnView } = useAppContext();
  const [initialDate, setInitialDate] = React.useState(new Date());
  const [finalDate, setFinalDate] = React.useState(new Date());
  const [sales, setSales] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [onView, setOnView] = React.useState(sales);

  const navigate = useNavigate();

  // Navegar a detalles
  function navigateToDetails(order) {
    setOrderOnView(order);
    navigate("/orders/order-details");
  }

  async function getSales(initDate, endDate = undefined) {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const queryParams = {
        pharmacy_id: pharmacy.pharmacy_id,
        initial_date: initDate,
        without_products: true,
      };
      if (endDate) queryParams.end_date = endDate;
      const config = {
        headers: {
          authToken: userToken,
        },
        params: queryParams,
      };
      const url = `${apiUrl}/orders/pharmacy/sales`;
      const res = await axios.get(url, config);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;
      setSales(res.data.body.orders);
    } catch (res) {
      console.log("GET SALES ERROR:", res);
      toast.error(getResponseError(res));
    }
  }

  function downloadExcelHandler() {
    // Set Labels
    const data = [];
    const headers = [
      "Empleado",
      "Total sin IVA",
      "IVA",
      "Total con IVA",
      "Fecha",
      "Hora",
    ];
    data.push(headers);

    // For each row
    sales.forEach((sale) => {
      const row = [];
      row.push(sale?.header?.name);
      row.push(sale?.header?.total_ammount);
      row.push(sale?.header?.iva || 0);
      row.push(sale?.header?.total_ammount + (sale?.header?.iva || 0));
      row.push(getDateString(sale?.header?.ordered_date, false));
      row.push(getTimeFromDate(sale?.header?.ordered_date, false));
      data.push(row);
    });

    downloadExcel(data, "Ventas");
  }

  // Obtener las ventas cada vez que cambien las fechas de filtro
  React.useEffect(() => {
    // Si no hay fecha incial borrar ventas
    if (!initialDate) {
      setSales([]);
      return;
    }

    if (finalDate !== null) {
      const formatedFinalDate = formatDate(finalDate, false);
      const formatedInitialDate = formatDate(initialDate, false);
      getSales(formatedInitialDate, formatedFinalDate);
    }
  }, [initialDate, finalDate]);

  // Cambiar las ventas cada vez que se cambie de pagina
  React.useEffect(() => {
    const salesPerPage = 10;
    // Calcular desde donde se muestran los productos basandose en el num de pagina
    // Ej: page: 2   from: (2*5) - 5 = 5;
    const from = page * salesPerPage - salesPerPage;
    setOnView(Object.values(sales).slice(from, from + salesPerPage));
  }, [page, sales]);

  return (
    <div className="sales-table">
      {/* seleccion de fechas para filtros  */}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="header">
          <h2>Consultar Ventas</h2>
          <div className="date-pickers">
            <div className="picker">
              <DatePicker
                label="Fecha inicial"
                value={initialDate}
                onChange={(newValue) => {
                  setInitialDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </div>
            <div className="picker">
              <DatePicker
                label="Fecha final"
                value={finalDate}
                onChange={(newValue) => {
                  setFinalDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </div>
            {sales.length > 0 && (
              <div className="download_excel_btn">
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  onClick={downloadExcelHandler}
                >
                  Descargar
                </Button>
              </div>
            )}
          </div>
        </div>
      </LocalizationProvider>
      <hr />

      {/* Tabla de ventas  */}
      {onView.length > 0 ? (
        <table className="sales-table">
          <thead className="sales-header">
            <tr>
              <th scope="col">Ticket</th>
              <th scope="col">Empleado</th>
              <th scope="col">Total sin Iva</th>
              <th scope="col">Iva</th>
              <th scope="col">Total con Iva</th>
              <th scope="col">Fecha</th>
              <th scope="col">Hora</th>
              <th scope="col">Detalles</th>
            </tr>
          </thead>
          <tbody id="employees">
            {onView.map((sale) => (
              <tr key={sale?.header?.order_id}>
                <td>{sale?.header?.order_id}</td>
                <td>{sale?.header?.name}</td>
                <td>$ {sale?.header?.total_ammount}</td>
                <td>$ {sale?.header?.iva || 0}</td>
                <td>
                  $ {sale?.header?.total_ammount + (sale?.header?.iva || 0)}
                </td>
                <td>{getDateString(sale?.header?.ordered_date, false)}</td>
                <td>{getTimeFromDate(sale?.header?.ordered_date, false)}</td>
                <td className="details">
                  <IconButton
                    variant="solid"
                    onClick={() => navigateToDetails(sale)}
                  >
                    <Visibility className="icon" />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-sales">
          <img
            height={300}
            src="/imgs/no-sales.png"
            alt=""
            className="no-sales"
          />
          <h3>No se encontraron ventas en estas fechas</h3>
          <p>Intenta seleccionar un rango de fechas diferente</p>
        </div>
      )}

      {/* Botones para cambiar de pagina  */}
      {sales.length >= 1 && (
        <ChangePageBtn page={page} setPage={setPage} data={sales} />
      )}
    </div>
  );
}
