import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./BackPageBtn.sass";

export default function BackPageBtn({ path, absolute, noMargin }) {
  // Tools
  const navigate = useNavigate();

  // Go back page
  function navigateBack() {
    if (path) navigate(path);
    else navigate(-1);
  }

  return (
    <div
      className={`back_btn_container ${absolute && "absolute"} ${
        noMargin && "no_margin"
      }`}
    >
      <IconButton onClick={navigateBack} className="back_btn">
        <ArrowBackIcon />
      </IconButton>
    </div>
  );
}
