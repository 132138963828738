import { saveAs } from "file-saver";
import XLSX from "xlsx";

// DOWNLOAD EXCEL - Create an excel Blob to download the tickets data
export async function downloadExcel(data, title) {
  // Create workbook
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: title || "Reporte MF360",
    Subject: "Reporte MF360",
    Author: "MF360",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push("Datos");
  const ws = XLSX.utils.aoa_to_sheet(data);
  wb.Sheets.Datos = ws;
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; // eslint-disable-line no-bitwise
    return buf;
  }
  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${title || "Reporte_MF360"}.xlsx`
  );
}

export const x = true;
