import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./AddressView.sass";
import { Button } from "@mui/material";
import { checkResponse, getResponseError } from "helpers/requests";
import { useAppContext } from "contexts/AppContext";
import { translateAddressType } from "helpers/translators";
import GenericModal from "components/General/GenericModal/GenericModal";
import toast from "react-hot-toast";

export default function AddressView() {
  // Variables
  const { type, id } = useParams();
  const { setAddressOnView } = useAppContext();
  const [address, setAddress] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);

  // Tools
  const navigate = useNavigate();

  // Get address
  async function getAddress() {
    try {
      // Make request
      const params = { adress_type: type };
      const url = `https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/adress/${id}`;
      const res = await axios.get(url, { params });

      // Check response
      if (!checkResponse(res)) throw new Error(res);

      // If there is an address
      if (res?.data?.body?.adress) {
        setAddress(res?.data?.body?.adress);
      }

      // If there is no address
      else {
        setAddress(null);
      }

      // Set lodaded true
      setLoaded(true);
    } catch (res) {
      console.log("GET ADDRESS ERROR:", res);
      toast.error(
        getResponseError(res, "Hubo un error obteniendo la dirección")
      );
    }
  }

  // Get address on render
  React.useEffect(() => {
    getAddress();
  }, []);

  return (
    loaded &&
    (address ? (
      <PageContainer fitWidth>
        <div className="address_view">
          {/* Back Button */}
          <div className="back_btn_container">
            <BackPageBtn noMargin />
          </div>

          {/* Title */}
          <h1>Dirección de {translateAddressType(address.adress_type)}</h1>

          {/* Info */}
          <div className="card-info">
            <div className="row">
              <div className="field">
                <p className="field-title">Calle</p>
                <p>{address.street || "-"}</p>
              </div>
              <div className="field">
                <p className="field-title">Número Exterior</p>
                <p>{address.exterior_number || "-"}</p>
              </div>
            </div>

            <div className="row">
              <div className="field">
                <p className="field-title">Número Interior</p>
                <p>{address.interior_number || "-"}</p>
              </div>
              <div className="field">
                <p className="field-title">Ciudad o Municipio</p>
                {address.city || "-"}
              </div>
            </div>

            <div className="row">
              <div className="field">
                <p className="field-title">Estado</p>
                <p>{address.state || "-"}</p>
              </div>
              <div className="field">
                <p className="field-title">Código Postal</p>
                {address.postal_code || "-"}
              </div>
            </div>

            <div className="row">
              <div className="field">
                <p className="field-title">Número Telefónico 1</p>
                <p>{address.phone_num1 || "-"}</p>
              </div>
              <div className="field">
                <p className="field-title">Número Telefónico 2</p>
                {address.phone_num2 || "-"}
              </div>
            </div>
          </div>
          <div className="bottom">
            <Button
              variant="contained"
              onClick={() => {
                setAddressOnView(address);
                navigate(`/address/${type}/${id}/edit`);
              }}
            >
              Editar Dirección
            </Button>
          </div>
        </div>
      </PageContainer>
    ) : (
      <GenericModal
        title="Sin Dirección"
        onContinue={() => {
          navigate(`/address/${type}/${id}/create`);
        }}
        onCancel={() => navigate(-1)}
      >
        <p className="label">No se encontró la dirección</p>
        <p className="label">¿Desea crear una nueva dirección?</p>
      </GenericModal>
    ))
  );
}
