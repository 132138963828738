import { Button } from "@mui/material";
import React from "react";
import "./GenericModal.sass";

export default function GenericModal({
  title,
  children,
  onContinue,
  onCancel,
  hideCancelButton,
}) {
  return (
    <div className="generic_modal_background">
      <div className="modal">
        <div className="modal_title">
          <h1>{title}</h1>
        </div>
        <div className="body">{children}</div>
        <div className="actions">
          <Button onClick={() => onContinue()}>Continuar</Button>
          {!hideCancelButton && (
            <Button color="error" onClick={() => onCancel()}>
              Cancelar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
