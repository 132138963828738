import PageContainer from "components/General/PageContainer/PageContainer";
import "./OrdersPage.sass";
import { Visibility } from "@mui/icons-material";
import { React, useState, useEffect } from "react";
import {
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { checkResponse, getResponseError } from "helpers/requests";
import axios from "axios";
import { translateOrderStatus } from "helpers/translators";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "contexts/AppContext";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import toast from "react-hot-toast";
import EmptyStateScreen from "components/General/EmptyStateScreen/EmptyStateScreen";

export default function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const { setOrderOnView, pharmacy } = useAppContext();

  // Funcion para obtener las ordenes de la api
  async function getOrders() {
    try {
      const url = `https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/orders/pharmacy/${pharmacy.pharmacy_id}?filter=${filter}`;
      const res = await axios.get(url);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;

      setOrders(res?.data?.body?.orders);
      setLoading(false);
    } catch (res) {
      console.log("GET ORDERS ERROR:", res);
      toast.error(
        getResponseError(res),
        "Hubo un error obteniendo las ordenes"
      );
    }
  }

  // Use effect para que la peticion solo se al cambiar el filtro de busqueda
  useEffect(() => {
    getOrders();
  }, [filter]);
  return (
    <PageContainer>
      <div className="orders_page">
        {/* ordenar por filtro */}
        <div className="select-filter">
          <FormControl className="filter-form">
            <InputLabel id="filter-label">Estado:</InputLabel>
            <Select
              labelId="filer-label"
              label="Estado:"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              <MenuItem value="delivered">Entregado</MenuItem>
              <MenuItem value="canceled">Cancelado</MenuItem>
              <MenuItem value="pending">Pendiente</MenuItem>
              <MenuItem value="on way">En camino</MenuItem>
            </Select>
          </FormControl>
        </div>
        {loading ? (
          <LoadingTable nCols={6} />
        ) : (
          <OrdersBody orders={orders} setOrderOnView={setOrderOnView} />
        )}
      </div>
    </PageContainer>
  );
}

function OrdersBody({ orders, setOrderOnView }) {
  const navigate = useNavigate();

  // Navegar a detalles
  function navigateToDetails(order) {
    setOrderOnView(order);
    navigate("/orders/order-details");
  }
  if (orders !== undefined && orders.length >= 1) {
    return (
      <table className="orders-table">
        <thead className="orders-header">
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Estado</th>
            <th scope="col">Total</th>
            <th scope="col">Fecha</th>
            <th scope="col">Hora</th>
            <th scope="col">Detalles</th>
          </tr>
        </thead>
        <tbody id="employees">
          {orders.map((order) => (
            <tr key={order.header.order_id}>
              <td>{order.header.order_id}</td>
              <td
                className={
                  order.header.status === "on way"
                    ? "on-way"
                    : order.header.status
                }
              >
                {translateOrderStatus(order.header.status)}
              </td>
              <td>{`$${order.header.total_ammount}`}</td>
              <td>{order.header.ordered_date.substring(0, 10)}</td>
              <td>{order.header.ordered_date.substring(11, 16)}</td>
              <td>
                <IconButton
                  variant="solid"
                  onClick={() => navigateToDetails(order)}
                >
                  <Visibility className="icon" />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <EmptyStateScreen
      title="No haz recibido ninguna orden"
      subtitle="Cuando algun cliente realize un pedido en internet lo podrás observar aqui"
      imgSrc="/imgs/no-orders.png"
    />
  );
}
