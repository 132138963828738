import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import PageContainer from "components/General/PageContainer/PageContainer";
import React from "react";
import "./TermsAndConditions.sass";

export default function TermsAndConditions({ newUserMode, nextNewUserStep }) {
  const [accepted, setAccepted] = React.useState(false);

  return (
    <PageContainer fitWidth>
      <div className="terms_and_conditions">
        <h1>Términos y Condiciones</h1>

        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Omnis et
          reiciendis blanditiis, modi assumenda accusantium ullam nam corporis
          ut magnam ratione voluptate expedita fuga delectus tenetur nesciunt
          aliquam fugit commodi incidunt, unde nisi. Temporibus in deserunt
          animi consequuntur odio nisi magni quam. Voluptatum animi repudiandae
          incidunt consequuntur aliquam pariatur, beatae blanditiis explicabo
          laudantium, omnis eveniet enim voluptate impedit, ea quia officia
          perspiciatis alias velit soluta veritatis libero at illo placeat.
          Error, expedita repellat. Dolor aliquid, dolorum ab vero provident
          ullam rerum quaerat nobis laboriosam illum alias sit autem dolorem
          vitae velit adipisci saepe doloribus itaque eaque facere porro
          debitis. Aliquid dolorem praesentium a consequuntur! Quibusdam nulla
          dolorum quaerat totam facere ullam blanditiis ipsam quod asperiores.
          Natus consectetur omnis quasi repellat, nesciunt tempore similique
          aperiam nulla maxime dolores odit quibusdam, corrupti dolorum eius
          ullam maiores quos laboriosam suscipit iure amet ipsam dolor est in?
          Nemo placeat sunt modi, dolores obcaecati facilis.
        </p>

        {newUserMode && (
          <>
            <FormGroup sx={{ width: "100%", mb: "20px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accepted}
                    onChange={(event) => setAccepted(event.target.checked)}
                  />
                }
                label="Aceptar Términos y Condiciones"
              />
            </FormGroup>

            <Button
              className="continue_button"
              variant="contained"
              disabled={!accepted}
              onClick={nextNewUserStep}
            >
              Continuar
            </Button>
          </>
        )}
      </div>
    </PageContainer>
  );
}
