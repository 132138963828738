import PageContainer from "components/General/PageContainer/PageContainer";
import React from "react";
import "./EmployeeDetails.sass";
import { Button } from "@mui/material";
import { useAppContext } from "contexts/AppContext";
import { translateEmployeeStatus, translateRole } from "helpers/translators";
import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import GenericModal from "components/General/GenericModal/GenericModal";
import GoToItemDetailsPageBtn from "components/General/GoToItemDetailsPageBtn/GoToItemDetailsPageBtn";
import toast from "react-hot-toast";

export default function EmployeeDetails() {
  const { userOnView, setUserOnView } = useAppContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  async function disableAccount() {
    try {
      const url = `https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/employees/${userOnView.employee_id}`;
      const res = await axios.delete(url);

      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;
      userOnView.status = "inactive";
      setUserOnView(userOnView);
      toast.success("Se elimino el empleado correctamente");
    } catch (res) {
      console.log("DISABLE EMPLOYEE ERROR:", res);
      toast.error(
        getResponseError(res),
        "Hubo un error desactivando la cuenta"
      );
    }
  }

  return (
    <div className="container-body">
      <PageContainer>
        {/* boton de regreso */}
        <BackPageBtn />
        <div className="emp-image">
          {/* imagen de empleado */}
          <img
            src={
              userOnView.employee_photo
                ? userOnView.employee_photo
                : "/imgs/no_user.jpg"
            }
            alt="employee"
          />
        </div>
        <div className="card-info">
          <div className="row">
            <div className="field">
              <p className="field-title">Nombre</p>
              <p>{userOnView.name}</p>
            </div>
            <div className="field">
              <p className="field-title">Apellidos</p>
              <p>{userOnView.last_name}</p>
            </div>
          </div>
          <div className="row">
            <div className="field">
              <p className="field-title">Correo Electrónico</p>
              <p>{userOnView.mail}</p>
            </div>
            <div className="field">
              <p className="field-title">Teléfono</p>
              <p>{userOnView.phone_num ? userOnView.phone_num : "-"}</p>
            </div>
          </div>
          <div className="row">
            <div className="field">
              <p className="field-title">Rol</p>
              <p>{userOnView.role ? translateRole(userOnView.role) : "-"}</p>
            </div>
            <div className="field">
              <p className="field-title">Estado</p>
              <p>
                {userOnView.status
                  ? translateEmployeeStatus(userOnView.status)
                  : "-"}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="field">
              <p className="field-title">Fecha de Creación</p>
              <p>
                {userOnView.created_at
                  ? userOnView.created_at.substring(0, 10)
                  : "-"}
              </p>
            </div>
            {userOnView.status !== "pending" && (
              <div className="field">
                <p className="field-title">Dirección</p>
                <GoToItemDetailsPageBtn
                  item="Dirección"
                  path={`/address/employee/${userOnView.employee_id}`}
                />
              </div>
            )}
          </div>
        </div>
        <div className="bottom">
          <Button
            variant="contained"
            className="disable-acc"
            onClick={() => setShowConfirmModal(true)}
          >
            Desactivar cuenta
          </Button>
        </div>
      </PageContainer>

      {/* Confirm Modal */}
      {showConfirmModal && (
        <GenericModal
          title="Eliminar Usuario"
          onCancel={() => setShowConfirmModal(false)}
          onContinue={disableAccount}
        >
          <p className="label">
            ¿Estás seguro que quieres eliminar este usuario?
          </p>
        </GenericModal>
      )}
    </div>
  );
}
