import { Button } from "@mui/material";
import GoToItemDetailsPageBtn from "components/General/GoToItemDetailsPageBtn/GoToItemDetailsPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import ToolsBar from "components/General/ToolsBar/ToolsBar";
import { useAppContext } from "contexts/AppContext";
import { translatePharmacyStatus } from "helpers/translators";
import React from "react";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import "./MyPharmacy.sass";

export default function MyPharmacy() {
  // Variables
  const { pharmacy, user } = useAppContext();

  // Roles
  const [isNabuAdmin] = React.useState(user.role === "nabu");
  const [isPharmacyOwner] = React.useState(user.role === "pharmacy_owner");

  return (
    <PageContainer fitWidth>
      {/* ToolsBar */}
      {isNabuAdmin ||
        (isPharmacyOwner && (
          <ToolsBar noMargin>
            <Link to="/my-pharmacy/edit" className="link">
              <Button
                variant="outlined"
                className="tool_button"
                size="small"
                startIcon={<EditIcon />}
              >
                Editar Farmacia
              </Button>
            </Link>
          </ToolsBar>
        ))}

      <div className="my_pharmacy">
        {/* Pharmacy Img */}
        <img
          src={pharmacy?.logo_url || "/imgs/no_pharmacy?.jpg"}
          alt="Pharmacy"
        />

        {/* Info */}
        <div className="card-info">
          <div className="row">
            <div className="field">
              <p className="field-title">Nombre de Farmacia</p>
              <p>{pharmacy?.pharmacy_name || "Sin información"}</p>
            </div>
            <div className="field">
              <p className="field-title">Estado</p>
              <p>
                {pharmacy?.status
                  ? translatePharmacyStatus(pharmacy?.status)
                  : "Sin información"}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="field">
              <p className="field-title">Fecha de Creación</p>
              <p>
                {pharmacy?.created_at
                  ? pharmacy?.created_at.substring(0, 10)
                  : "-"}
              </p>
            </div>
            {user.role === "pharmacy_owner" && (
              <div className="field">
                <p className="field-title">Dirección</p>
                <GoToItemDetailsPageBtn
                  item="Dirección"
                  path={`/address/pharmacy/${pharmacy?.adress_id}`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
