import { Button } from "@mui/material";
import LoadingComponent from "components/General/LoadingComponent/LoadingComponent";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import { getCashRegisters } from "pages/pos/CashRegisters/cashRegistersActions";
import React from "react";
import GenericModal from "components/General/GenericModal/GenericModal";
import toast from "react-hot-toast";
import "./SelectPhyisicalCashRegister.sass";
import { encriptar } from "helpers/encrypt";
import { getCashRegisterLastOperation } from "contexts/AppActions";

export default function SelectPhyisicalCashRegister() {
  // Variables
  const {
    pharmacy,
    userToken,
    setPhysicalCashRegister,
    setIsOpenCashRegister,
  } = useAppContext();
  const [cashRegisters, setCashRegisters] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [selectedCashRegister, setSelectedCashRegister] = React.useState(true);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  // Select cash Register Handler
  async function selectedCashRegisterHandler() {
    // Save in LS cash register data
    const lsKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;
    localStorage.setItem(
      "mf360-pcr",
      await encriptar(lsKey, selectedCashRegister?.cash_register_id)
    );

    // Set data in context
    setPhysicalCashRegister(selectedCashRegister);

    // Obten la ultima operación de esa caja
    getCashRegisterLastOperation(
      userToken,
      selectedCashRegister.cash_register_id,
      pharmacy.pharmacy_id,
      setIsOpenCashRegister
    );
  }

  // Get cash registers handler
  async function getCashRegistersHandler() {
    const res = await getCashRegisters(pharmacy?.pharmacy_id, userToken);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }
    setCashRegisters(res.data);
    setLoading(false);
  }

  // On render
  React.useEffect(() => {
    getCashRegistersHandler();
  }, []);

  return (
    <PageContainer fitWidth>
      <div className="select_pcr">
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            {/* Titles */}
            <h1>Seleccionar Caja Registradora</h1>
            <h2>
              No hay una caja registradora seleccionada para esta computadora,
              por favor selecciona que caja es:
            </h2>

            {/* Cash Registers List */}
            <div className="cash_registers_list">
              <div className="headers">
                <p className="c1">Nombre de Caja</p>
              </div>
              {cashRegisters?.length > 0 ? (
                cashRegisters?.map((c) => (
                  <div className="item" key={c.cash_register_id}>
                    <p className="c1">{c.name}</p>

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        setShowConfirmModal(true);
                        setSelectedCashRegister(c);
                      }}
                    >
                      Seleccionar
                    </Button>
                  </div>
                ))
              ) : (
                <p className="no_data">No hay cajas registradoras</p>
              )}
            </div>
          </>
        )}
      </div>

      {/* Confirm Selection Modal */}
      {showConfirmModal && (
        <GenericModal
          title="Confirmar Seleccion de Caja"
          onContinue={selectedCashRegisterHandler}
          onCancel={() => setShowConfirmModal(false)}
        >
          <p className="label">
            ¿Está seguro que &quot;{selectedCashRegister.name}
            &quot; se usará para las ventas de esta computadora?
          </p>
        </GenericModal>
      )}
    </PageContainer>
  );
}
