import React from "react";
import "./LoadingComponent.sass";

export default function LoadingComponent() {
  return (
    <div className="loading_component">
      <img src="/imgs/loading.gif" alt="Cargando" />
    </div>
  );
}
