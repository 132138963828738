import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import { formatDate } from "helpers/date_formaters";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./AddProvider.sass";

export default function AddProvider() {
  const { user, userToken, pharmacy } = useAppContext();
  const [isNabuAdmin] = React.useState(user.role === "nabu");
  const [isAnafarmexAdmin] = React.useState(user.role === "anafarmex");
  const [providerType, setProviderType] = React.useState("pharmacy");
  const [name, setName] = React.useState("");
  const navigate = useNavigate();

  async function addProvider() {
    try {
      // Validate
      if (!validateInputs([name])) {
        toast.error("Debes llenar todos los campos");
        return;
      }

      // Decide if it will be general or pharmacy
      const body = {
        name,
        created_at: formatDate(new Date()),
      };
      if (isNabuAdmin) {
        // If its nabu admin it can decide whether general or pharmacy
        if (providerType === "pharmacy") {
          body.type = "pharmacy";
          body.pharmacy_id = pharmacy.pharmacy_id;
        } else {
          body.type = "general";
        }
      } else if (isAnafarmexAdmin) {
        body.type = "general";
      } else {
        body.type = "pharmacy";
        body.pharmacy_id = pharmacy.pharmacy_id;
      }

      // Make request
      const headers = { authToken: userToken };
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/providers`;
      const res = await axios.post(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;

      // Success
      toast.success("Proveedor creado correctamente");
      navigate("/providers");
    } catch (error) {
      console.log("ADD PROVIDER ERROR: ", error);
      toast.error(
        getResponseError(error, "Hubo un error añadiendo el proveedor")
      );
    }
  }

  return (
    <PageContainer fitWidth>
      <BackPageBtn noMargin />

      <div className="add_provider">
        <h1 className="title">Añadir Proveedor</h1>

        <form>
          <div className="row">
            <TextField
              label="Nombre"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {isNabuAdmin && (
            <div className="row">
              <FormControl className="input">
                <InputLabel id="type_select">Tipo</InputLabel>
                <Select
                  labelId="type_select"
                  label="Tipo"
                  value={providerType}
                  onChange={(e) => setProviderType(e.target.value)}
                >
                  <MenuItem value="pharmacy">Farmacia Actual</MenuItem>
                  <MenuItem value="general">General</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          <div className="btn_container">
            <Button variant="contained" size="large" onClick={addProvider}>
              Añadir
            </Button>
          </div>
        </form>
      </div>
    </PageContainer>
  );
}
