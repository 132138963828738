import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { CHART_COLORS } from "helpers/charts";
import "./PieGraph.sass";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieGraph({ title, chartData, chartLabels, width }) {
  const options = {
    responsive: true,
    plugins: {
      labels: {
        render: "percentage",
        fontColor: "white",
        precision: 2,
      },
      legend: {
        position: "right",
      },
    },
  };

  const data = {
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        backgroundColor: chartData?.map(
          (item, i) => CHART_COLORS[i % CHART_COLORS.length]
        ),
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="graph_container pie_graph" style={{ width: `${width}%` }}>
      <h1>{title}</h1>

      <div className="graph_div">
        <Pie data={data} options={options} width="400px" />
      </div>
    </div>
  );
}
