import toast from "react-hot-toast";
import "./OrderDetails.sass";
import OrderHeader from "components/Orders/OrderHeader/OrderHeader";
import { checkResponse } from "helpers/requests";
import { useAppContext } from "contexts/AppContext";
import axios from "axios";
import React from "react";
import OrderedProducts from "components/Orders/OrderedProducts/OrderedProducts";

export default function OrderDetails() {
  const { orderOnView, userToken, setOrderOnView } = useAppContext();
  const [orderLoaded, setOrderLoaded] = React.useState(false);

  async function getOrderData() {
    try {
      // Make request
      const order_id = orderOnView?.header?.order_id;
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/orders/${order_id}`;
      const res = await axios.get(url, { headers: { authToken: userToken } });

      // Check respose
      if (!checkResponse(res)) throw res;

      // Set order on view
      setOrderOnView(res?.data?.body);
      setOrderLoaded(true);
    } catch (error) {
      console.log("GET ORDER DATA ERROR:", error);
      toast.error("Hubo un error obteniendo los datos de la venta");
    }
  }

  React.useEffect(() => {
    getOrderData();
  }, []);

  return (
    <div className="order-details-pg">
      {/* header de la orden */}
      <OrderHeader />
      {/* productos de la orden */}
      {orderLoaded && <OrderedProducts />}
    </div>
  );
}
