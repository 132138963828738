/* eslint-disable prefer-const */
import React from "react";
import PageContainer from "components/General/PageContainer/PageContainer";
import "./TotalProducts.sass";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppContext } from "contexts/AppContext";
import printTicket from "helpers/print_ticket";
import { formatDate } from "helpers/date_formaters";
import toast from "react-hot-toast";
import PaymentsIcon from "@mui/icons-material/Payments";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import makePosSale from "./make_sale";
import SuccessSaleModal from "./SuccessSaleModal";

export default function TotalProducts({ products, setProducts, setResetSale }) {
  const {
    user,
    userToken,
    pharmacy,
    inventory,
    physicalCashRegister,
    batches,
  } = useAppContext();

  // Variables
  const [total, setTotal] = React.useState(0);
  const [paid, setPaid] = React.useState(0);
  const [change, setChange] = React.useState(0);
  const [tempSaleData, setTempSaleData] = React.useState({});
  const [disableSaleBtn, setDisableSaleBtn] = React.useState(false);
  const [showSuccessSaleModal, setShowSuccessSaleModal] = React.useState(false);

  // Input values
  const [cashMoney, setCashMoney] = React.useState("");
  const [cardVisaMoney, setCardVisaMoney] = React.useState("");
  const [cardMCMoney, setCardMCMoney] = React.useState("");
  const [cardAMEXMoney, setCardAMEXMoney] = React.useState("");
  const [voucherMoney, setVoucherMoney] = React.useState("");

  // Delete product
  function deleteProduct(key) {
    setProducts(products.filter((p) => p.key !== key));
  }

  // Update paid
  React.useEffect(() => {
    const cashNumber = cashMoney !== "" ? parseFloat(cashMoney) : 0;
    const cardVisaNumber = cardVisaMoney !== "" ? parseFloat(cardVisaMoney) : 0;
    const cardMCNumber = cardMCMoney !== "" ? parseFloat(cardMCMoney) : 0;
    const cardAMEXNumber = cardAMEXMoney !== "" ? parseFloat(cardAMEXMoney) : 0;
    const voucherNumber = voucherMoney !== "" ? parseFloat(voucherMoney) : 0;

    setPaid(
      cashNumber +
        cardVisaNumber +
        cardMCNumber +
        cardAMEXNumber +
        voucherNumber
    );
  }, [cashMoney, cardVisaMoney, cardMCMoney, cardAMEXMoney, voucherMoney]);

  // Update total
  React.useEffect(() => {
    let sum = 0;
    products.forEach((p) => {
      sum += p.price;
    });
    setTotal(sum);
  }, [products]);

  // Update change
  React.useEffect(() => {
    setChange(paid - total);
  }, [paid]);

  // Submit sale
  async function handleSubmitSale() {
    // Validar inputs antes de hacer request
    if (total === 0) {
      return;
    }
    if (paid < total) {
      toast.error("La cantidad pagada es menor al total de la compra");
      setDisableSaleBtn(false);
      return;
    }
    if (products.length <= 0) {
      toast.error("No hay productos seleccionados");
      setDisableSaleBtn(false);
      return;
    }

    // Deshabilitar botón hasta que se tenga respuesta
    setDisableSaleBtn(true);

    // Obtener datos de cada producto
    const orderedItems = [];
    products.forEach(async (product) => {
      // Añadir datos
      const item = {
        item_id: product.item_id,
        quantity: 1,
        badge_id: product.batch_id || null,
        has_iva_charge: product.has_iva_charge,
        price: product.price,
      };

      if (product.requires_prescription) {
        item.req_pres = true;
        item.doctor_name = product.doctorName;
        item.proffesional_license = product.professionalLicense;
        item.prescription_number = product.prescriptionNumber;
      }
      orderedItems.push(item);
    });

    // Crear una lista de objetos ordenados con su id y cantidad
    const totalBatches = {};
    products.forEach((product) => {
      // Iterar cada producto e ir sumando cada vez que aparezca un lote
      if (product.batch_id) {
        const curQuantity = totalBatches[product.batch_id];
        totalBatches[product.batch_id] = curQuantity ? curQuantity + 1 : 1;
      }
    });

    const orderedBatches = [];
    Object.keys(totalBatches).forEach((batch_id) => {
      // Añadir objeto con el id de producto y la cantidad total
      orderedBatches.push({
        badge_id: parseInt(batch_id, 10),
        quantity: totalBatches[batch_id],
      });
    });

    // Crear el objeto que se mandara en el request
    const body = {
      employee_id: user.employee_id,
      pharmacy_id: pharmacy.pharmacy_id,
      cash_register_id: physicalCashRegister.cash_register_id,
      total_ammount: total,
      cash_ammount: cashMoney !== "" ? parseFloat(cashMoney) : 0,
      visa_ammount: cardVisaMoney !== "" ? parseFloat(cardVisaMoney) : 0,
      mc_ammount: cardMCMoney !== "" ? parseFloat(cardMCMoney) : 0,
      amex_ammount: cardAMEXMoney !== "" ? parseFloat(cardAMEXMoney) : 0,
      voucher_ammount: voucherMoney !== "" ? parseFloat(voucherMoney) : 0,
      ordered_items: orderedItems,
      ordered_batches: orderedBatches,
      ordered_date: formatDate(new Date()),
    };

    const { msg, iva, without_iva, success, ticket_id } = await makePosSale(
      body,
      userToken,
      change
    );

    if (success) {
      // Actualizar inventario con nuevas cantidades
      products.forEach((product) => {
        const currentQuantity = inventory[product.sku].quantity;
        inventory[product.sku].quantity = currentQuantity - 1;
      });

      // Actualizar lotes con nuevas cantidades
      orderedBatches.forEach((batchPair) => {
        for (let i = 0; i < batches.length; i++) {
          if (batches[i].badge_id === batchPair.badge_id) {
            // Restar las piezas
            let newQuantity = batches[i].quantity - batchPair.quantity;

            // Si es más chico que 0, pon 0
            if (newQuantity < 0) newQuantity = 0;

            batches[i].quantity = newQuantity; // Actualizar

            break;
          }
        }
      });

      // Imprimir ticket
      const paymentMethods = {
        cashMoney,
        cardAMEXMoney,
        cardVisaMoney,
        cardMCMoney,
        voucherMoney,
      };
      printTicket(
        total,
        products,
        user,
        pharmacy,
        physicalCashRegister,
        iva,
        without_iva,
        ticket_id,
        paymentMethods
      );

      // Guardar total, pagado y cambio en variables temporales porque las siguientes líneas borran el valor original
      const saleData = {
        total,
        paid,
        change,
        cashMoney: cashMoney !== "" ? parseFloat(cashMoney) : 0,
        cardVisaMoney: cardVisaMoney !== "" ? parseFloat(cardVisaMoney) : 0,
        cardMCMoney: cardMCMoney !== "" ? parseFloat(cardMCMoney) : 0,
        cardAMEXMoney: cardAMEXMoney !== "" ? parseFloat(cardAMEXMoney) : 0,
        voucherMoney: voucherMoney !== "" ? parseFloat(voucherMoney) : 0,
      };
      setTempSaleData(saleData);

      // Mostrar mensaje de éxito
      setShowSuccessSaleModal(true);
      setResetSale((cur) => !cur);
      setProducts([]);
      setCashMoney("");
      setCardVisaMoney("");
      setCardMCMoney("");
      setCardAMEXMoney("");
      setVoucherMoney("");
      localStorage.removeItem("MF360-Sale-Products");
      setDisableSaleBtn(false);
      return;
    }

    setDisableSaleBtn(false);
    toast.error(msg);
  }

  return (
    <div className="total_products cash_register_side">
      <PageContainer>
        {/* Title */}
        <h1>Total de Productos</h1>

        {/* Products */}
        <div className="products">
          {products?.length > 0 ? (
            products.map((p) => (
              <div className="product" key={p.key}>
                <p className="name">{p.product_name}</p>
                <div className="right">
                  <p className="pice">${p.price}</p>
                  <button type="button" onClick={() => deleteProduct(p.key)}>
                    <CloseIcon className="icon" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="no_products">No hay productos</p>
          )}
        </div>

        {/* Pay Card */}
        <div className="pay_card">
          {/* Totals */}
          <div className="totals">
            <div>
              <p className="label">Total</p>
              <p className="value">${total}</p>
            </div>

            <div>
              <p className="label">Pagado</p>
              <p className="value">${paid}</p>
            </div>

            <div>
              <p className="label">Cambio</p>
              <p className={`value ${change < 0 && "red"}`}>${change}</p>
            </div>
          </div>

          {/* Inputs */}
          <div className="inputs">
            <div className="input_container">
              <div className="label">
                <PaymentsIcon className="icon" />
                <p>Efectivo</p>
              </div>
              <input
                type="number"
                className="money_input"
                value={cashMoney}
                onChange={(e) => setCashMoney(e.target.value)}
              />
            </div>

            <hr />

            <div className="input_container">
              <div className="label">
                <CreditCardIcon className="icon" />
                <p>Visa</p>
              </div>
              <input
                type="text"
                className="money_input"
                value={cardVisaMoney}
                onChange={(e) => setCardVisaMoney(e.target.value)}
              />
            </div>

            <div className="input_container">
              <div className="label">
                <CreditCardIcon className="icon" />
                <p>Master Card</p>
              </div>
              <input
                type="number"
                className="money_input"
                value={cardMCMoney}
                onChange={(e) => setCardMCMoney(e.target.value)}
              />
            </div>

            <div className="input_container">
              <div className="label">
                <CreditCardIcon className="icon" />
                <p>American Express</p>
              </div>
              <input
                type="number"
                className="money_input"
                value={cardAMEXMoney}
                onChange={(e) => setCardAMEXMoney(e.target.value)}
              />
            </div>

            <hr />

            <div className="input_container">
              <div className="label">
                <RequestPageIcon className="icon" />
                <p>Vales</p>
              </div>
              <input
                type="number"
                className="money_input"
                value={voucherMoney}
                onChange={(e) => setVoucherMoney(e.target.value)}
              />
            </div>
          </div>

          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={handleSubmitSale}
            disabled={disableSaleBtn}
          >
            Realizar Venta
          </Button>
        </div>
      </PageContainer>

      {/* Confirm Modal */}
      {showSuccessSaleModal && (
        <SuccessSaleModal
          tempSaleData={tempSaleData}
          closeModal={() => setShowSuccessSaleModal(false)}
        />
      )}
    </div>
  );
}
