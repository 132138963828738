/* eslint-disable no-unused-vars */
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import React from "react";
import {
  getSessionSales,
  getSessionResults,
} from "pages/pos/CloseCashRegister/actionsCloseCashRegister";
import "./ClosureRequestView.sass";
import toast from "react-hot-toast";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import PaymentsIcon from "@mui/icons-material/Payments";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LoadingComponent from "components/General/LoadingComponent/LoadingComponent";
import { useNavigate } from "react-router-dom";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import { checkResponse, getResponseError } from "helpers/requests";
import axios from "axios";
import ShowBills from "components/CashRegister/ShowBills/ShowBills";

export default function ClosureRequestView() {
  // Variables
  const {
    closureRequestOnView: closure,
    setClosureRequestOnView,
    userToken,
    pharmacy,
    physicalCashRegister,
  } = useAppContext();
  const [sessionTotal, setSessionTotal] = React.useState(null);
  const [openOp, setOpenOp] = React.useState(null);
  const [cashTotal, setCashTotal] = React.useState(null);
  const [cashPositive, setCashPositive] = React.useState(null);
  const [cardVisaTotal, setCardVisaTotal] = React.useState(null);
  const [cardMCTotal, setCardMCTotal] = React.useState(null);
  const [cardAMEXTotal, setCardAMEXTotal] = React.useState(null);
  const [voucherTotal, setVoucherTotal] = React.useState(null);
  const [withdrawalTotal, setWithdrawalTotal] = React.useState(null);
  const [productReturnsTotal, setProductReturnsTotal] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const navigate = useNavigate();

  // Validate closure
  async function validateClosure() {
    try {
      // Make requests
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/cash-register/closure-requests/validate`;
      const body = { id: closure.operation_id };
      const headers = { authToken: userToken };
      const res = await axios.put(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;

      // Proceder
      toast.success("Cierre validado");
      navigate("/cash-registers/closure-requests");
    } catch (error) {
      console.log("VALIDATE CLOSURE ERROR:", error);
      toast.error(getResponseError(error, "Hubo un error validando el cierre"));
    }
  }

  // Get results of the session
  async function getSessionResultsHandler() {
    try {
      // Get last operation
      const { sales, openOperation } = await getSessionSales(
        userToken,
        closure.cash_register_id,
        pharmacy.pharmacy_id,
        closure.operation_date
      );
      setOpenOp(openOperation);

      // Get results of the session
      const totals = getSessionResults(sales);
      setSessionTotal(totals.sessionTotal);
      setCashTotal(totals.cashTotal);
      setCashPositive(totals.cashPositiveTotal);
      setCardVisaTotal(totals.cardVisaTotal);
      setCardMCTotal(totals.cardMCTotal);
      setCardAMEXTotal(totals.cardAMEXTotal);
      setVoucherTotal(totals.voucherTotal);
      setWithdrawalTotal(totals.withdrawalTotal);
      setProductReturnsTotal(totals.productReturnsTotal);
      setLoaded(true);

      // Add values to the closure request on context
      setClosureRequestOnView({ ...closure, totals, openOperation });
    } catch (error) {
      console.error(error);
      toast.error("Hubo un error obteniendo los datos de cierre");
    }
  }

  // On render
  React.useEffect(() => {
    getSessionResultsHandler();
  }, []);

  return (
    <PageContainer fitWidth>
      <div className="closure_request">
        {loaded ? (
          <>
            {/* Back Btn */}
            <BackPageBtn noMargin />

            {/* Title */}
            <h1>Validar Cierre de Caja</h1>

            {/* General Info */}
            <div className="section">
              <h2>Información del Cierre</h2>

              <div className="info_item">
                <p>Fecha de Apertura:</p>
                <span>{openOp?.operation_date || "Sin información"}</span>
              </div>
              <div className="info_item">
                <p>Monto de Apertura:</p>
                <span>${openOp?.opening_ammount || "Sin información"}</span>
              </div>
              <div className="info_item">
                <p>Empleado:</p>
                <span>
                  {closure?.name || "Sin nombre"}{" "}
                  {closure?.last_name || "Sin apellido"}
                </span>
              </div>
              <div className="info_item">
                <p>Fecha de Cierre:</p>
                <span>{closure?.operation_date || "Sin información"}</span>
              </div>
              <hr className="divider" />
              <div className="info_item">
                <p>Ganancia Total:</p>
                <span>${sessionTotal}</span>
              </div>
              <div className="info_item">
                <p>Total Actual en Caja:</p>
                <span>
                  $
                  {sessionTotal + openOp?.opening_ammount
                    ? sessionTotal + openOp?.opening_ammount
                    : "Sin información"}
                </span>
              </div>
            </div>

            {/* Totals */}
            <div className="section">
              <h2>Totales Registrados</h2>

              <div className="total_item">
                <p>
                  <AddCircleIcon className="icon" />
                  Ingresos en Efectivo:
                </p>
                <span>${cashPositive}</span>
              </div>
              <div className="total_item">
                <p>
                  <AddCircleIcon className="icon" />
                  Monto de Apertura:
                </p>
                <span>${openOp?.opening_ammount}</span>
              </div>
              <div className="total_item withdrawal">
                <p>
                  <RemoveCircleIcon className="icon" />
                  Retiros en Efectivo:
                </p>
                <span>${withdrawalTotal}</span>
              </div>
              {productReturnsTotal > 0 && (
                <div className="total_item withdrawal">
                  <p>
                    <RemoveCircleIcon className="icon" />
                    Devolución de Productos:
                  </p>
                  <span>${productReturnsTotal}</span>
                </div>
              )}

              <hr className="divider" />
              <div className="total_item">
                <p>
                  <PaymentsIcon className="icon" />
                  Total en Efectivo:
                </p>
                <span>
                  $
                  {cashTotal + openOp?.opening_ammount
                    ? cashTotal + openOp?.opening_ammount
                    : "Sin información"}
                </span>
              </div>
              <div className="total_item">
                <p>
                  <CreditCardIcon className="icon" /> Total en Visa:
                </p>
                <span>${cardVisaTotal}</span>
              </div>
              <div className="total_item">
                <p>
                  <CreditCardIcon className="icon" /> Total en Master Card:
                </p>
                <span>${cardMCTotal}</span>
              </div>
              <div className="total_item">
                <p>
                  <CreditCardIcon className="icon" /> Total en American Express:
                </p>
                <span>${cardAMEXTotal}</span>
              </div>
              <div className="total_item">
                <p>
                  <RequestPageIcon className="icon" />
                  Total en Vales:
                </p>
                <span>${voucherTotal}</span>
              </div>
            </div>

            {/* Bills */}
            {closure?.bills_count && (
              <div className="section">
                <h2>Cuenta de Billetes y Monedas</h2>
                <ShowBills billsCount={closure?.bills_count} />
              </div>
            )}

            {/* Buttons */}
            <div className="buttons">
              <Button
                variant="outlined"
                fullWidth
                className="btn"
                color="error"
                onClick={() => {
                  navigate("/cash-registers/closure-requests/difference");
                }}
              >
                Registrar differencia
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="btn"
                onClick={validateClosure}
              >
                VALIDAR CIERRE
              </Button>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </PageContainer>
  );
}
