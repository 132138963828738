import axios from "axios";
import ContactFooter from "components/Landing/ContactFooter/ContactFooter";
import ContactLayout from "components/Landing/ContactLayout/ContactLayout";
import React from "react";
import toast from "react-hot-toast";

export default function Contact() {
  // Variables de contacto
  const [name, setName] = React.useState("");
  const [phoneNum, setPhoneNum] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [comments, setComments] = React.useState("");

  async function contact(e) {
    try {
      e.preventDefault();

      const body = {
        name,
        email,
        phone: phoneNum,
        comments,
        type: "contact",
      };
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/emails/contact`;
      const res = await axios.post(url, body);

      if (res.status === 200) {
        toast.success("Hemos recibido tu solicitud.");
        setName("");
        setPhoneNum("");
        setEmail("");
        setComments("");
        return;
      }
      console.log(res.data);
      throw "Error al enviar correo";
    } catch (error) {
      console.log("SEND EMAIL ERROR: error");
      toast.error("Intentalo de nuevo");
    }
  }

  return (
    <>
      <ContactLayout>
        <div className="right_side">
          <form className="form" id="create-form" onSubmit={(e) => contact(e)}>
            {/* Title */}
            <h1>Contactanos</h1>
            <p>
              Deja tus datos a continuación y nos ponemos en contacto en menos
              de 24 horas
            </p>

            {/* Pharmacy Data */}
            <h2>Datos de contacto</h2>

            <div className="input_container">
              <div className="input">
                <p>Nombre y Apellido</p>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
              </div>

              <div className="input">
                <p>Numero Telefonico</p>
                <input
                  type="text"
                  onChange={(e) => setPhoneNum(e.target.value)}
                  value={phoneNum}
                  required
                />
              </div>
            </div>
            <div className="input">
              <p className="input_p">Correo Electronico</p>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </div>
            <div className="input">
              <p className="input_p">Comentarios</p>
              <textarea
                type="text"
                rows="5"
                onChange={(e) => setComments(e.target.value)}
                value={comments}
                required
              />
            </div>

            <div className="btn_container">
              <button type="submit" className="create_btn">
                Contactar
              </button>
            </div>
          </form>
        </div>
      </ContactLayout>
      <ContactFooter />
    </>
  );
}
