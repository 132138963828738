import { Button } from "@mui/material";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import { getDateString } from "helpers/date_formaters";
import React from "react";
import shortid from "shortid";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadExcel } from "helpers/excel";

function KardexTable({ products, isLoading, allProducts }) {
  function downloadExcelHandler() {
    // Set Labels
    const data = [];
    const headers = [
      "Producto",
      "Código de Barras",
      "Laboratorio",
      "Lote",
      "Fecha de Caducidad",
      "Fecha de Venta",
      "Receta Médica",
      "Doctor",
      "Cédula Profesional",
      "Dirección",
    ];
    data.push(headers);

    // For each row
    allProducts.forEach((product) => {
      const row = [];
      row.push(product.product_name);
      row.push(product?.sku);
      row.push(product?.laboratory);
      row.push(product?.badge);
      row.push(getDateString(product?.expiracy_date, false));
      row.push(getDateString(product?.ordered_date, false));
      row.push(product?.prescription_number || "-");
      row.push(product?.doctor);
      row.push(product?.professional_license);
      row.push(product?.prescription_address);
      data.push(row);
    });

    downloadExcel(data, "Kardex");
  }

  return (
    <div>
      {isLoading ? (
        <LoadingTable nCols={5} />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={downloadExcelHandler}
            >
              Descargar Excel
            </Button>
          </div>
          <div style={{ width: "100%", overflowX: "scroll" }}>
            <table className="product_table">
              <thead className="product_header">
                <tr>
                  <th scope="col">Producto</th>
                  <th scope="col">Codigo de Barras</th>
                  <th scope="col name">Laboratorio</th>
                  <th scope="col name">Lote</th>
                  <th scope="col">Fecha de Caducidad</th>
                  <th scope="col name">Fecha de Venta</th>
                  <th scope="col">Receta Medica</th>
                  <th scope="col">Doctor</th>
                  <th scope="col">Cedula Profesional</th>
                  <th scope="col">Dirección</th>
                </tr>
              </thead>
              <tbody id="products">
                {products.map((product) => (
                  <tr key={shortid.generate()}>
                    <td>
                      <div className="img-text" style={{ width: "200px" }}>
                        <img
                          src={product.photo_url ?? "/imgs/no-image.png"}
                          alt="product"
                        />
                        <p>{product?.product_name}</p>
                      </div>
                    </td>
                    <td>{product?.sku}</td>
                    <td>
                      <p style={{ width: "120px" }}>{product?.laboratory}</p>
                    </td>
                    <td>
                      <p style={{ width: "120px" }}>{product?.badge}</p>
                    </td>
                    <td>{getDateString(product?.expiracy_date, false)}</td>
                    <td>{getDateString(product?.ordered_date, false)}</td>
                    <td>{product?.prescription_number || "-"}</td>
                    <td>
                      <p style={{ width: "130px" }}>{product?.doctor}</p>
                    </td>
                    <td>
                      <p style={{ width: "150px" }}>
                        {product?.professional_license}
                      </p>
                    </td>
                    <td>
                      <p style={{ width: "250px" }}>
                        {product?.prescription_address ||
                          "Dirección no registrada"}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default KardexTable;
