import React from "react";
import PageContainer from "components/General/PageContainer/PageContainer";
import "./ProductDetails.sass";
import { Autocomplete, Button, TextField } from "@mui/material";
import shortid from "shortid";
import { useAppContext } from "contexts/AppContext";
import { getResponseError, validateInputs } from "helpers/requests";
import GenericModal from "components/General/GenericModal/GenericModal";
import toast from "react-hot-toast";
import { getProductBatches } from "helpers/get_product";

export default function ProductDetails({ products, setProducts, resetSale }) {
  // Variables
  const { inventory, batches } = useAppContext();

  const [product, setProduct] = React.useState(null);
  const [sku, setSku] = React.useState("");
  const [batchInput, setBatchInput] = React.useState("");
  const [batchSelected, setBatchSelected] = React.useState(null);
  const [batchOptions, setBatchOptions] = React.useState([]);

  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [doctorName, setDoctorName] = React.useState("");
  const [professionalLicense, setProfessionalLicense] = React.useState("");
  const [prescriptionNumber, setPrescriptionNumber] = React.useState("");

  // Para borrar todo en caso de que se realize una compra
  React.useEffect(() => {
    setProduct(null);
    setDoctorName("");
    setProfessionalLicense("");
  }, [resetSale]);

  function handleCancelModal() {
    setShowConfirmModal(false);
    setProduct(null);
    setBatchOptions([]);
    setBatchInput("");
    setBatchSelected(null);
  }

  function handleConfirmModal() {
    if (!validateInputs([doctorName, professionalLicense])) {
      toast.error("Debes llenar los campos de la receta");
      return;
    }
    setProduct({
      ...product,
      doctorName,
      professionalLicense,
      prescriptionNumber,
    });
    setShowConfirmModal(false);
  }

  // Search product
  function getProduct(e) {
    try {
      // Prevent default
      e.preventDefault();

      // Get product
      const res = inventory[sku];
      setSku("");

      // Validate product
      if (!res) throw new Error("No se encontró producto con ese SKU");
      setProduct(res);

      // Set values
      if (res.requires_prescription === 1) {
        setShowConfirmModal(true);
      }

      // Generate batch option strings
      const batchesOptionsRes = getProductBatches(res.item_id, batches);

      if (batchesOptionsRes.length <= 0) {
        toast.error("El producto no tiene ningún lote asociado");
        return;
      }

      setBatchSelected(batchesOptionsRes[0]);
      setBatchOptions(batchesOptionsRes);
    } catch (error) {
      setSku("");
      setProduct(null);
      setBatchOptions([]);
      setBatchInput("");
      setBatchSelected(null);
      console.log("SEARCH PRODUCT ERROR:", error);
      toast.error(
        getResponseError(
          error,
          "Hubo un error obteniendo los datos del producto"
        )
      );
    }
  }

  function addProduct(e) {
    e.preventDefault();

    if (!batchSelected) {
      toast.error("Debes seleccionar un lote");
      return;
    }

    const productToAdd = {
      ...product,
      key: shortid.generate(),
    };
    if (batchSelected) productToAdd.batch_id = batchSelected.batch_id;
    const newProducts = [...products, productToAdd];
    localStorage.setItem("MF360-Sale-Products", JSON.stringify(newProducts));
    setProducts(newProducts);
    setProduct(null);
    setBatchOptions([]);
    setBatchInput("");
    setBatchSelected(null);
  }

  return (
    <PageContainer>
      <form onSubmit={getProduct} className="sku_form">
        <TextField
          id="sku-reader"
          fullWidth
          label="Código de Barras"
          className="input"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
        />
      </form>

      <h1>Detalles de Producto</h1>

      {product ? (
        <div className="details">
          <div className="detail product_name">
            <p className="label">Nombre:</p>
            <p className="value">{product.product_name || "Sin información"}</p>
          </div>

          <div className="detail">
            <p className="label">Código de Barras:</p>
            <p className="value">{product.sku || "Sin información"}</p>
          </div>

          <div className="detail price">
            <p className="label">Precio:</p>
            <p className="value">$ {product.price || "Sin información"}</p>
          </div>

          <hr className="details_divider" />

          {/* Batch */}
          <form className="add_product_form" onSubmit={addProduct}>
            <Autocomplete
              disablePortal
              value={batchSelected}
              onChange={(event, newValue) => {
                setBatchSelected(newValue);
              }}
              inputValue={batchInput}
              onInputChange={(event, newInputValue) => {
                setBatchInput(newInputValue);
              }}
              options={batchOptions}
              sx={{ marginBottom: "15px" }}
              renderInput={(params) => (
                <TextField {...params} label="Lote" fullWidth />
              )}
            />
            <div className="add_to_products_btn">
              <Button type="submit" variant="contained" size="large">
                Aceptar
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <p className="no_product">Sin producto</p>
      )}
      {/* Confirm Modal */}
      {showConfirmModal && (
        <GenericModal
          title="Producto Requiere Receta Médica"
          onCancel={handleCancelModal}
          onContinue={handleConfirmModal}
        >
          <p className="label">Ingresa los datos de la receta médica</p>
          <TextField
            label="Nombre del Doctor"
            fullWidth
            sx={{ marginTop: "30px", marginBottom: "15px" }}
            value={doctorName}
            onChange={(e) => setDoctorName(e.target.value)}
          />
          <TextField
            label="Cédula Profesional"
            fullWidth
            value={professionalLicense}
            onChange={(e) => setProfessionalLicense(e.target.value)}
          />
          <TextField
            label="Folio de Receta "
            fullWidth
            sx={{ marginTop: "15px", marginBottom: "15px" }}
            value={prescriptionNumber}
            onChange={(e) => setPrescriptionNumber(e.target.value)}
          />
        </GenericModal>
      )}
    </PageContainer>
  );
}
