import { Button, TextField } from "@mui/material";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import { checkResponse, validateInputs } from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./EditMyPharmacy.sass";
import fileService from "services/fileService";

export default function EditMyPharmacy() {
  // Variables
  const { pharmacy, setPharmacy, userToken } = useAppContext();
  const [updateImg, setUpdateImg] = React.useState(false);
  const navigate = useNavigate();

  // Input values
  const [imgFile, setImgFile] = React.useState(null);
  const [name, setName] = React.useState(
    pharmacy.pharmacy_name ? pharmacy.pharmacy_name : ""
  );

  async function editPharmacy() {
    try {
      // Validations
      if (!validateInputs([name])) {
        toast.error("El nombre de farmacia no es válido");
        return;
      }
      if (updateImg && !imgFile) {
        toast.error("La imágen seleccionada no es válida");
        return;
      }

      // Update image
      let resImg = null;
      const oldPhamacyLogo = pharmacy?.logo_url;
      if (updateImg) {
        resImg = await fileService.uploadFile(
          imgFile,
          pharmacy?.pharmacy_id,
          pharmacy?.pharmacy_name?.replace(/\s+/g, "_").toLowerCase(),
          "pharmacy-logos"
        );
        if (!resImg) {
          toast.error("Hubo un error subiendo la imágen");
          resImg = null;
        }
      }

      // Create body
      const body = {
        pharmacy_name: name,
        status: pharmacy?.status,
        logo_url: resImg || pharmacy?.logo_url,
      };

      // Make request
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/pharmacys/${pharmacy?.pharmacy_id}`;
      const res = await axios.put(url, body, {
        headers: { authToken: userToken },
      });

      // Check response
      if (!checkResponse(res)) throw res;

      // Update data in context
      const newPharmacyData = { ...pharmacy, ...body };
      setPharmacy(newPharmacyData);

      toast.success("Datos actualizados correctamente");
      navigate("/my-pharmacy");

      // If image was updated, delete old image
      if (updateImg && resImg) fileService.deleteFile(oldPhamacyLogo);
    } catch (error) {
      console.log("EDIT PHARMACY ERROR:", error);
      toast.error("Hubo un error editando los datos de farmacia");
    }
  }

  return (
    <PageContainer fitWidth>
      <div className="edit_my_pharmacy">
        {/* Back Button */}
        <div className="back_btn_container">
          <BackPageBtn noMargin />
        </div>

        {/* Pharmacy Img */}
        <img
          src={
            imgFile
              ? URL.createObjectURL(imgFile)
              : pharmacy.logo_url || "/imgs/no_pharmacy.jpg"
          }
          accept="image/*"
          alt="Pharmacy"
        />
        <Button
          className="change_img_btn"
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            const imgInput = document.querySelector(".img_input");
            imgInput.click();
          }}
        >
          Cambiar Imágen
        </Button>

        <input
          type="file"
          className="img_input"
          accept=".jpeg"
          onChange={(e) => {
            setImgFile(e.target.files[0]);
            setUpdateImg(true);
          }}
        />

        {/* Info */}
        <div className="card-info">
          <div className="row">
            <TextField
              fullWidth
              label="Nombre de Farmacia"
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="standard"
            />
          </div>
        </div>
        <div className="bottom">
          <Button variant="contained" onClick={editPharmacy}>
            Editar Datos
          </Button>
        </div>
      </div>
    </PageContainer>
  );
}
