/* eslint-disable no-unused-vars */
import { React, useState, useEffect } from "react";
import PageContainer from "components/General/PageContainer/PageContainer";
import "./ProductCatalogue.sass";
import { TextField, Button, InputAdornment } from "@mui/material";
import ProductTable from "components/ProductCatalogue/ProductTable/ProductTable";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import AddIcon from "@mui/icons-material/Add";
import { Link, useSearchParams } from "react-router-dom";
import { useAppContext } from "contexts/AppContext";
import toast from "react-hot-toast";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import ChangePageBtn from "components/General/ChangePageBtn/ChangePageBtn";
import EmptyStateScreen from "components/General/EmptyStateScreen/EmptyStateScreen";
import ToolsBar from "components/General/ToolsBar/ToolsBar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Visibility } from "@mui/icons-material";
import TuneIcon from "@mui/icons-material/Tune";
import FilterProductsModal from "components/Inventory/FilterProductsModal/FilterProductsModal";

export default function ProductCatalogue() {
  // Paramentros de url
  const [params, setParams] = useSearchParams({ page: 1 });

  // Variables
  const { user, pharmacy } = useAppContext();
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(parseInt(params.get("page", "1"), 10));
  const [loading, setLoading] = useState(true);
  const [getPrivate, setGetPrivate] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);

  // Roles
  const [isAdmin] = useState(user.role === "nabu" || user.role === "anafarmex");

  // Search query
  async function handleSearch(e) {
    try {
      // Prevenir que se refresque la pagina
      e.preventDefault();
      setLoading(true);
      // Si la busqueda está vacía no hacer nada
      if (!validateInputs([query])) return;

      // Buscar los productos en el backend
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/products/search`;
      const config = {
        params: {
          query,
          pharmacy_id: !isAdmin ? pharmacy?.pharmacy_id : null,
          get_private: isAdmin && getPrivate ? true : null,
        },
      };
      const res = await axios.get(url, config);

      // Checar si hay errores
      if (!checkResponse(res)) throw res;

      // Cambiar los productos
      setProducts(res?.data?.body?.products);
      setLoading(false);
    } catch (res) {
      console.log("SEARCH PRODUCTS ERROR:", res);
      toast.error(getResponseError(res), "Hubo un error con la búsqueda");
    }
  }

  // Get products by page
  async function getProductsByPage() {
    try {
      // Buscar los productos en el backend
      setLoading(true);

      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/products`;
      const pharmacy_id = !isAdmin ? pharmacy?.pharmacy_id : null;
      const queryParams = {
        page,
        pharmacy_id,
        get_private: getPrivate,
      };
      const res = await axios.get(url, { params: queryParams });

      // Checar si hay errores
      if (!checkResponse(res)) throw res;

      // Cambiar los productos
      setProducts(res?.data?.body?.Product_Catalogue);
      setLoading(false);
    } catch (res) {
      console.log("SEARCH PRODUCTS ERROR:", res);
      toast.error(getResponseError(res));
    }
  }

  useEffect(() => {
    setParams({ page });
    getProductsByPage();
  }, [page, getPrivate]);

  return (
    <PageContainer>
      {/* ToolsBar */}
      <ToolsBar>
        {/* Add product to catalogue */}
        <Link to="/product-catalogue/add-new" className="link">
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            className="tool_button"
            size="small"
          >
            Crear Nuevo Producto
          </Button>
        </Link>

        {/* Categories */}
        {isAdmin && (
          <Link to="/product-categories" className="link">
            <Button variant="outlined" className="tool_button" size="small">
              Ver Categorías
            </Button>
          </Link>
        )}

        {/* Providers */}
        {isAdmin && (
          <Link to="/providers" className="link">
            <Button
              variant="outlined"
              startIcon={<LocalShippingIcon />}
              className="tool_button"
              size="small"
            >
              Proveedores
            </Button>
          </Link>
        )}
        {isAdmin && (
          <Button
            variant={getPrivate ? "contained" : "outlined"}
            startIcon={<Visibility />}
            className="tool_button"
            size="small"
            onClick={() => setGetPrivate(getPrivate ? null : true)}
          >
            Mostrar productos privados
          </Button>
        )}
      </ToolsBar>

      <div className="product_catalogue">
        {/* Parte de arriba */}
        <form className="forms" onSubmit={(e) => handleSearch(e)}>
          {/* Buscar y ordenar por */}
          <TextField
            label="Buscar por nombre o código de barras"
            id="searchProductField"
            variant="outlined"
            className="text_input"
            size="small"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="outlined"
            startIcon={<TuneIcon />}
            onClick={() => setShowFilterModal(true)}
          >
            Filtrar Búsqueda
          </Button>
        </form>
        {/* Tabla de productos */}
        {loading ? (
          <LoadingTable nCols={6} />
        ) : (
          <TableBody products={products} />
        )}
        {/* Botones para cambiar de pagina */}
        <ChangePageBtn
          page={page}
          setPage={setPage}
          data={products}
          isArray={false}
          defaultBehaviour={false}
        />
      </div>

      {/* Fitler Products */}
      {showFilterModal && (
        <FilterProductsModal
          setShowFilterModal={setShowFilterModal}
          setProducts={setProducts}
          query={query}
          setQuery={setQuery}
          setPage={setPage}
          mode="product_catalogue"
        />
      )}
    </PageContainer>
  );
}

function TableBody({ products }) {
  if (products !== undefined && products.length >= 1) {
    return <ProductTable products={products} />;
  }

  return (
    <EmptyStateScreen
      title="No se encontraron productos"
      subtitle="intenta cambiar de pagina o buscar otro producto"
      imgSrc="/imgs/no-products.png"
    />
  );
}
