import { useAppContext } from "contexts/AppContext";
import React from "react";
import "./UtilitiesByProductTable.sass";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, TextField } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import { cutDisplayText } from "helpers/string";
import {
  countProductUtilities,
  downloadUtilitiesExcel,
  getProductsOfTheMonth,
} from "./UtilitiesByProductActions";

export default function UtilitiesByProductTable() {
  const { pharmacy, userToken, inventory } = useAppContext();
  const [loading, setLoading] = React.useState(true);
  const [productsData, setProductsData] = React.useState([]);
  const [monthOnView, setMonthOnView] = React.useState(
    new Date().toISOString().split("T")[0]
  );

  async function getUtilitiesDataHandler() {
    const res = await getProductsOfTheMonth(
      pharmacy?.pharmacy_id,
      monthOnView,
      userToken
    );

    if (res.success) {
      const countRes = countProductUtilities(res.products, inventory);
      setProductsData(Object.values(countRes));
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getUtilitiesDataHandler();
  }, [monthOnView]);

  return (
    <div className="utilities_by_product">
      <div className="actions">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Seleccionar Mes"
            views={["month", "year"]}
            openTo="month"
            value={monthOnView}
            onChange={(newValue) => {
              const formatedDate = new Date(newValue)
                .toISOString()
                .split("T")[0];
              setMonthOnView(formatedDate);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          size="small"
          sx={{ ml: "10px" }}
          onClick={() => downloadUtilitiesExcel(productsData)}
        >
          Descargar Excel
        </Button>
      </div>

      <div className="table_div">
        {!loading ? (
          <table className="table">
            <thead>
              <tr>
                <th>Producto</th>
                <th>Código de Barras</th>
                <th className="amount_header">Costo Unitario</th>
                <th className="amount_header">Precio de Venta</th>
                <th className="amount_header">Cantidad</th>
                <th className="amount_header">Utilidad Unitaria</th>
                <th className="amount_header">Utilidad Total</th>
              </tr>
            </thead>

            <tbody>
              {productsData.map((r, i) => (
                <tr key={i}>
                  <td>
                    {cutDisplayText(r?.product_name, 55) || "Sin información"}
                  </td>
                  <td>{r?.sku || "-"}</td>
                  <td className="amount_cell">
                    ${r?.item_cost.toFixed(2) || "-"}
                  </td>
                  <td className="amount_cell">
                    ${r?.sold_at.toFixed(2) || "-"}
                  </td>
                  <td className="amount_cell">{r?.quantity || "-"}</td>
                  <td
                    className={`amount_cell ${r?.utility >= 0 ? "pos" : "neg"}`}
                  >
                    ${(r?.sold_at - r?.item_cost).toFixed(2) || "-"}
                  </td>
                  <td
                    className={`amount_cell ${r?.utility >= 0 ? "pos" : "neg"}`}
                  >
                    ${r?.utility.toFixed(2) || "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <LoadingTable nCols={6} />
        )}
      </div>
    </div>
  );
}
