import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import React from "react";
import { Button, TextField } from "@mui/material";
import "./AddProductToInventory.sass";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import GenericModal from "components/General/GenericModal/GenericModal";
import toast from "react-hot-toast";
import { formatDate } from "helpers/date_formaters";

export default function AddProductToInventory() {
  // Variables
  const {
    pharmacy,
    productOnView: product,
    setProductOnView,
    userToken,
    inventory,
    user,
  } = useAppContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  // Input values
  const [price, setPrice] = React.useState("");
  const [itemCost, setItemCost] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [batch, setBatch] = React.useState("");
  const [expiracyDate, setExpiracyDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );

  // Tools
  const navigate = useNavigate();

  // Add product to inventory
  async function addProductToInventory() {
    try {
      // Validate fields
      if (!validateInputs([price, itemCost, quantity, batch, expiracyDate])) {
        toast.error("Debes llenar todos los campos");
        return;
      }

      // Make request
      const body = {
        created_by: user.employee_id,
        pharmacy_id: pharmacy.pharmacy_id,
        product_id: product.product_id,
        price: parseFloat(price),
        item_cost: parseFloat(itemCost),
        quantity: parseFloat(quantity),
        entry_date: formatDate(new Date()),
        expiracy_date: expiracyDate,
        badge: batch,
      };
      const headers = {
        authToken: userToken,
      };
      const url =
        "https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/inventory/add-product";
      const res = await axios.post(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;

      // Update local inventory
      const newItem = {
        item_id: res.data.body.item_id,
        items_sold: 0,
        ...product,
        ...body,
      };
      inventory[product.sku] = newItem;
      setProductOnView(newItem);

      // Alert and navigate
      toast.success("Producto añadido exitosamente");
      navigate("/product/view");
    } catch (res) {
      console.log("ADD PRODUCT TO INVENTORY ERROR:", res);
      toast.error(
        getResponseError(
          res,
          "Hubo un error agregando el producto al inventario"
        )
      );
    }
  }

  return (
    <PageContainer fitWidth>
      <div className="add_product_to_inventory">
        {/* Back Btn */}
        <BackPageBtn noMargin path="/product/view" />

        {/* Title */}
        <h1>Añadir Producto</h1>

        {/* Inputs */}
        <TextField
          label="Precio de Venta"
          className="input"
          value={price}
          type="number"
          onChange={(e) => setPrice(e.target.value)}
        />
        <TextField
          label="Costo por Unidad"
          className="input"
          value={itemCost}
          type="number"
          onChange={(e) => setItemCost(e.target.value)}
        />
        <TextField
          label="Cantidad"
          className="input"
          value={quantity}
          type="number"
          onChange={(e) => setQuantity(e.target.value)}
        />
        <TextField
          label="Lote"
          className="input"
          value={batch}
          onChange={(e) => setBatch(e.target.value)}
        />
        <TextField
          label="Fecha de Caducidad"
          className="input"
          value={expiracyDate}
          type="date"
          onChange={(e) => setExpiracyDate(e.target.value)}
        />

        {/* Button */}
        <Button
          onClick={() => setShowConfirmModal(true)}
          className="btn"
          variant="contained"
        >
          Añadir Producto
        </Button>
      </div>

      {/* Confirm Modal */}
      {showConfirmModal && (
        <GenericModal
          title="Añadir Producto"
          onCancel={() => setShowConfirmModal(false)}
          onContinue={addProductToInventory}
        >
          <p className="label">
            ¿Estás seguro que quieres añadir este producto a tu inventario?
          </p>
        </GenericModal>
      )}
    </PageContainer>
  );
}
