import { Email, LocationOn, WhatsApp } from "@mui/icons-material";
import React from "react";
import "./ContactFooter.sass";

export default function ContactFooter() {
  return (
    <footer className="contact_footer">
      <div className="container">
        <div className="contact-item">
          <Email sx={{ color: "white" }} />
          <p>marcos@asesoriaenfarmacias.com</p>
        </div>
        <div className="contact-item">
          <WhatsApp sx={{ color: "white" }} />
          <p>55-73-4122-27</p>
        </div>
        <div className="contact-item">
          <LocationOn sx={{ color: "white" }} />
          <small>
            Av. Chapultepec No. 592, San <br />
            Miguel Chapultepec, Alcaldia <br />
            Miguel Hidalgo, C.P 11850, Ciudad <br />
            de Mexico
          </small>
        </div>
      </div>
    </footer>
  );
}
