import axios from "axios";
import { formatDate } from "helpers/date_formaters";
import { checkResponse, getResponseError } from "helpers/requests";

export async function getCashRegisters(pharmacy_id, userToken) {
  try {
    // Make request
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/physical-registers/pharmacy/${pharmacy_id}`;
    const headers = { authToken: userToken };
    const res = await axios.get(url, { headers });

    // Check respose
    if (!checkResponse(res)) throw res;

    // Set operations
    return {
      success: true,
      data: res?.data?.body?.data,
    };
  } catch (error) {
    console.log("GET CASH REGISTERS ERROR:", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un error obteniendo las cajas registradoras"
      ),
    };
  }
}

// Add cash register
export async function addCashRegister(
  pharmacy_id,
  userToken,
  name,
  printerType,
  ipAddress
) {
  try {
    // Make request
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/physical-registers`;
    const headers = { authToken: userToken };
    const body = {
      pharmacy_id,
      name,
      printer_type: printerType,
      printer_ip_address: ipAddress,
      created_at: formatDate(new Date()),
    };
    const res = await axios.post(url, body, { headers });

    // Check respose
    if (!checkResponse(res)) throw res;

    // Set operations
    return {
      success: true,
      cash_register_id: res?.data?.body?.cash_register_id,
    };
  } catch (error) {
    console.log("ADD CASH REGISTER ERROR:", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un error añadiendo la caja registradora"
      ),
    };
  }
}

// Edit cash register
export async function editCashRegister(
  cash_register_id,
  name,
  printerType,
  ipAddress,
  userToken
) {
  try {
    // Make request
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/physical-registers/${cash_register_id}`;
    const headers = { authToken: userToken };
    const body = {
      name,
      printer_type: printerType,
      printer_ip_address: ipAddress,
    };
    const res = await axios.put(url, body, { headers });

    // Check respose
    if (!checkResponse(res)) throw res;

    // Set operations
    return { success: true };
  } catch (error) {
    console.log("EDIT CASH REGISTER ERROR:", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un error editando la caja registradora"
      ),
    };
  }
}

// Change active status
export async function setCashRegisterActiveStatus(
  cash_register_id,
  active,
  userToken
) {
  try {
    // Make request
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/physical-registers/${cash_register_id}/active`;
    const headers = { authToken: userToken };
    const body = { active };
    const res = await axios.put(url, body, { headers });

    // Check respose
    if (!checkResponse(res)) throw res;

    // Set operations
    return { success: true };
  } catch (error) {
    console.log("STATUS CASH REGISTER ERROR:", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un error eliminando la caja registradora"
      ),
    };
  }
}
