import axios from "axios";
import { downloadExcel } from "helpers/excel";
import { checkResponse } from "helpers/requests";
import toast from "react-hot-toast";

export async function getProductsOfTheMonth(
  pharmacyId,
  monthOnView,
  userToken
) {
  try {
    // Hacer petición
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/accounting/${pharmacyId}/utilities/by-product`;
    const params = { date: monthOnView };
    const headers = { authToken: userToken };
    const res = await axios.get(url, { params, headers });

    // Checar respuesta
    if (!checkResponse(res)) throw res;

    return { success: true, products: res?.data?.body?.products };
  } catch (error) {
    console.log("GET PRODUCTS UTILITIES ERROR:", error);
    toast.error("Hubo un error obteniendo las utilidades");
    return { success: false };
  }
}

export function countProductUtilities(products, inventory) {
  const productsData = {};

  products.forEach((p) => {
    // Si ya estaba registrado el item_id
    if (productsData[p?.item_id]) {
      console.log(1);
      // Update utility
      const newUtility =
        productsData[p?.item_id].utility + (p?.sold_at - p?.item_cost);
      productsData[p?.item_id].utility = newUtility;
      productsData[p?.item_id].quantity++;
    } else {
      console.log(2);
      // Agregar datos del producto
      const newData = {
        utility: p?.sold_at - p?.item_cost,
        sku: p?.sku,
        item_id: p?.item_id,
        product_name: inventory[p?.sku].product_name,
        item_cost: p?.item_cost,
        sold_at: p?.sold_at,
        quantity: 1,
      };
      productsData[p?.item_id] = newData;
    }
  });

  return productsData;
}

export function downloadUtilitiesExcel(productsData) {
  // Set Labels
  const data = [];
  const headers = [
    "Producto",
    "Código de Barras",
    "Costo Unitario",
    "Precio de Venta",
    "Cantidad Vendida",
    "Utilidad Unitaria",
    "Utilidad Total",
  ];
  data.push(headers);

  // For each row
  productsData.forEach((r) => {
    const row = [];
    row.push(r?.product_name);
    row.push(r?.sku);
    row.push(r?.item_cost);
    row.push(r?.sold_at);
    row.push(r?.quantity);
    row.push(r?.sold_at - r?.item_cost);
    row.push(r?.utility);
    data.push(row);
  });

  downloadExcel(data, "Utilidades");
}
