import axios from "axios";

const { getResponseError, checkResponse } = require("helpers/requests");

export default async function editProductData(productId, body, userToken) {
  try {
    // Make request
    const headers = { authToken: userToken };
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/products/${productId}`;
    const res = await axios.put(url, body, { headers });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return success
    return { success: true };
  } catch (error) {
    console.log("EDIT PRODUCT DATA ERROR:", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un error actualizando los datos del producto"
      ),
    };
  }
}
