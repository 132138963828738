import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import ToasterComp from "components/General/Toaster/Toaster";
import React from "react";
import "./AuthLayout.sass";

function AuthLayout({ children, showBackPageBtn }) {
  return (
    <div className="auth-layout">
      {/* Left Container */}
      <div className="left_container">
        {showBackPageBtn && <BackPageBtn absolute path="/" />}
        <img src="/imgs/login_doctors.svg" alt="" />
      </div>

      {/* Right Container */}
      <div className="right_container">{children}</div>

      {/* Toaster */}
      <ToasterComp />
    </div>
  );
}

export default AuthLayout;
