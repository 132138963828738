import React from "react";
import "./PageLayout.sass";
import Navbar from "components/General/Navbar/Navbar";
import Topbar from "components/General/Topbar/Topbar";

export default function PageLayout({ title, Page }) {
  return (
    <div className="page_layout">
      <Navbar />
      <div className="right_side">
        <Topbar title={title} />
        <div className="page_container_lay">
          <Page />
        </div>
      </div>
    </div>
  );
}
