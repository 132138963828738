import { Button } from "@mui/material";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import PageContainer from "components/General/PageContainer/PageContainer";
import { Link } from "react-router-dom";
import React from "react";
import "./PharmacyAdmin.sass";
import PharmaciesTable from "components/PharmacyAdmin/PharmaciesTable";
import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";
import toast from "react-hot-toast";
import { useAppContext } from "contexts/AppContext";
import ChangePageBtn from "components/General/ChangePageBtn/ChangePageBtn";

function PharmacyAdministration() {
  const { userToken } = useAppContext();
  const [loading, setLoading] = React.useState(true);
  const [pharmacies, setPharmacies] = React.useState(null);
  const [page, setPage] = React.useState(1);

  async function getPharmacies() {
    try {
      const config = { headers: { authToken: userToken } };
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/pharmacys`;
      const res = await axios.get(url, config);

      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;

      setPharmacies(res.data.body.pharmacys);
      setLoading(false);
    } catch (res) {
      console.log("GET PHARMACIES ERROR:", res);
      toast.error(
        getResponseError(res),
        "Hubo un error obteniendo las farmacias"
      );
    }
  }

  React.useEffect(() => {
    getPharmacies();
  }, []);

  return (
    <PageContainer>
      <div className="pharmacies">
        <form className="add-pharmacy">
          <h2>Farmacias Registradas</h2>
          {/* Botón de para navegar a creacion de farmacia */}
          <Link to="/farmacias/nueva" className="link">
            <Button variant="contained" className="add_btn">
              Crear Farmacia
            </Button>
          </Link>
        </form>
        {/* Tabla con los empleados */}
        {loading ? (
          <LoadingTable nCols={5} />
        ) : (
          <>
            <PharmaciesTable pharmacies={pharmacies} />
            <ChangePageBtn page={page} setPage={setPage} data={pharmacies} />
          </>
        )}
      </div>
    </PageContainer>
  );
}

export default PharmacyAdministration;
