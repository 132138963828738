import React from "react";
import { Link, NavLink } from "react-router-dom";
import Toaster from "components/General/Toaster/Toaster";
import "./LandingNavbar.sass";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

export default function Navbar() {
  // Click on link phone view check
  function linkPhoneViewCheck() {
    if (window.matchMedia("(max-width: 768px)").matches) {
      const smallMenu = document.querySelector(".left_side");
      smallMenu.style.display = "none";
    }
  }

  return (
    <div className="landing_navbar">
      {/* Right Side */}
      <div className="right_side">
        {/* Image */}
        <img src="imgs/logo.jpg" alt="Logo" className="logo" />

        {/* Title */}
        <h1 className="app_name">Mi Farmacia 360</h1>
      </div>

      {/* Left Side */}
      <div className="left_side">
        {/* Close small menu */}
        <IconButton
          className="close_small_menu_btn"
          onClick={() => {
            const smallMenu = document.querySelector(".left_side");
            smallMenu.style.display = "none";
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Buttons */}
        <div className="buttons">
          <Link to="/solicitar-demo" onClick={linkPhoneViewCheck}>
            <button type="button" className="button register_btn">
              Demo
            </button>
          </Link>

          <Link to="/login" onClick={linkPhoneViewCheck}>
            <button type="button" className="button login_btn">
              Iniciar Sesión
            </button>
          </Link>
        </div>

        {/* Links */}
        <div className="links">
          <NavLink to="/home" className="link" onClick={linkPhoneViewCheck}>
            Inicio
          </NavLink>
          <NavLink
            to="/punto-de-venta"
            className="link"
            onClick={linkPhoneViewCheck}
          >
            MF360
          </NavLink>
          <NavLink to="/nosotros" className="link" onClick={linkPhoneViewCheck}>
            Nosotros
          </NavLink>
          <NavLink to="/contacto" className="link" onClick={linkPhoneViewCheck}>
            Contacto
          </NavLink>
        </div>
      </div>

      {/* Open Small Menu Button */}
      <div className="open_small_menu_btn">
        <IconButton
          onClick={() => {
            const smallMenu = document.querySelector(".left_side");
            smallMenu.style.display = "flex";
          }}
        >
          <MenuIcon />
        </IconButton>
      </div>

      {/* Toaster */}
      <Toaster />
    </div>
  );
}
