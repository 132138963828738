export const initialCount = {
  bills: {
    1000: 0,
    500: 0,
    200: 0,
    100: 0,
    50: 0,
    20: 0,
  },
  coins: {
    20: 0,
    10: 0,
    5: 0,
    2: 0,
    1: 0,
  },
  cents: {
    50: 0,
    20: 0,
    10: 0,
    5: 0,
  },
};

export function validateBillsCount(billsCount) {
  let error = null;

  // Iterate over the money types
  const types = Object.keys(billsCount);
  types.forEach((type) => {
    // Get denominations
    const denominations = Object.keys(billsCount[type]);
    denominations.forEach((denomination) => {
      // Validate is number
      const count = billsCount[type][denomination];
      if (
        Number.isNaN(count) ||
        count === "" ||
        count === undefined ||
        count === null
      )
        error = {
          message: `La deniminación $${denomination} no tiene cantidad`,
        };
      if (count < 0)
        error = { message: `La deniminación $${denomination} es negativa` };
    });
  });

  return error;
}
