import React from "react";
import "./EmptyStateScreen.sass";

export default function EmptyStateScreen({ imgSrc, title, subtitle }) {
  return (
    <div className="empty-state">
      <img src={imgSrc} alt="error-img" className="no-products" height={250} />
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </div>
  );
}
