import { Button } from "@mui/material";
import OpenCashRegister from "components/CashRegister/OpenCashRegister/OpenCashRegister";
import ProductDetails from "components/CashRegister/ProductDetails/ProductDetails";
import SelectPhyisicalCashRegister from "components/CashRegister/SelectPhyisicalCashRegister/SelectPhyisicalCashRegister";
import TotalProducts from "components/CashRegister/TotalProducts/TotalProducts";
import { useAppContext } from "contexts/AppContext";
import React from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import "./ChashRegister.sass";
import { Link, useNavigate } from "react-router-dom";

export default function ChashRegister() {
  // Variables
  const { physicalCashRegister, isOpenCashRegister } = useAppContext();
  const [products, setProducts] = React.useState(() => {
    const stringArray = localStorage.getItem("MF360-Sale-Products");
    if (!stringArray) {
      return [];
    }
    return JSON.parse(stringArray);
  });
  const [resetSale, setResetSale] = React.useState(undefined);
  const navigate = useNavigate();

  if (!physicalCashRegister) {
    return <SelectPhyisicalCashRegister />;
  }
  if (!isOpenCashRegister) {
    return <OpenCashRegister />;
  }
  return (
    <div className="cash_register">
      {/* LEFT COLUMN */}
      <div className="product_details cash_register_side">
        <ProductDetails
          products={products}
          setProducts={setProducts}
          resetSale={resetSale}
        />

        <div className="toolbar_container">
          <Link to="/cash-register/close">
            <Button
              variant="outlined"
              size="small"
              className="tool_button"
              startIcon={<CancelPresentationIcon />}
            >
              Cerrar Caja
            </Button>
          </Link>
          <Link to="/cash-register/withdrawal">
            <Button
              variant="outlined"
              size="small"
              className="tool_button"
              startIcon={<RemoveCircleOutlineIcon />}
            >
              Retirar Dinero
            </Button>
          </Link>

          <Button
            variant="outlined"
            size="small"
            className="tool_button"
            startIcon={<AssignmentReturnIcon />}
            onClick={() => navigate("/cash-register/return-products")}
          >
            Consultar Venta
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="tool_button"
            startIcon={<RemoveRedEyeOutlinedIcon />}
            onClick={() => navigate("/cash-register/view")}
          >
            Detalles de Caja
          </Button>
        </div>
      </div>

      {/* RIGHT COLUMN */}
      <TotalProducts
        products={products}
        setProducts={setProducts}
        setResetSale={setResetSale}
      />
    </div>
  );
}
