import React from "react";

// Redirect to my profile
function ErrorReload() {
  React.useEffect(() => {
    window.location.replace(window.location.origin);
  }, []);
  return null;
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    // If there is an error, reload
    if (this.state.hasError) {
      return <ErrorReload />;
    }

    // Render normal app
    return this.props.children;
  }
}

export default ErrorBoundary;
