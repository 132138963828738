import React from "react";
import "./OpenCashRegister.sass";
import PageContainer from "components/General/PageContainer/PageContainer";
import { Button, TextField } from "@mui/material";
import { useAppContext } from "contexts/AppContext";
import toast from "react-hot-toast";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import axios from "axios";
import { formatDate } from "helpers/date_formaters";

export default function OpenCashRegister() {
  // Variables
  const {
    setIsOpenCashRegister,
    userToken,
    user,
    pharmacy,
    physicalCashRegister,
  } = useAppContext();
  const [showForm, setShowForm] = React.useState(false);
  const [openAmmount, setOpenAmmount] = React.useState("");

  // Open cash register
  async function openCashRegister() {
    try {
      // Validate fields
      if (!validateInputs([openAmmount])) {
        toast.error("Debes ingresar la cantidad de apertura");
        return;
      }

      // Make request
      const headers = { authToken: userToken };
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/cash-register/new-operation`;
      const body = {
        employee_id: user.employee_id,
        pharmacy_id: pharmacy.pharmacy_id,
        cash_register_id: physicalCashRegister.cash_register_id,
        operation_type: "open",
        opening_ammount: openAmmount !== "" ? parseFloat(openAmmount) : null,
        operation_date: formatDate(new Date()),
        cardAmmount: null,
      };
      const res = await axios.post(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;

      // Open cash register
      setIsOpenCashRegister(true);
      toast.success("Caja abierta correctamente");
    } catch (res) {
      console.log("OPEN CASH REGISTER ERROR:", res);
      toast.error(getResponseError(res, "Hubo un error abriendo la caja"));
    }
  }

  return (
    <PageContainer fitWidth>
      <div className="open_cash_register">
        {/* Top */}
        <h1>Iniciar Operaciones</h1>
        <p className="date">21 de mayo del 2022</p>

        {!showForm ? (
          <Button variant="contained" onClick={() => setShowForm(true)}>
            Abrir caja
          </Button>
        ) : (
          <>
            <TextField
              label="Cantidad de Apertura"
              className="input"
              type="number"
              value={openAmmount}
              onChange={(e) => setOpenAmmount(e.target.value)}
            />
            <Button variant="contained" onClick={openCashRegister}>
              Continuar
            </Button>
          </>
        )}
      </div>
    </PageContainer>
  );
}
