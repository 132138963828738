import React from "react";
import "./TopStatistics.sass";
import BadgeIcon from "@mui/icons-material/Badge";
import {
  AttachMoney,
  Inventory,
  PointOfSale,
  PriceCheck,
} from "@mui/icons-material";
import { useAppContext } from "contexts/AppContext";
import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";
import toast from "react-hot-toast";
import { formatDate } from "helpers/date_formaters";

export default function TopStatistics() {
  const { pharmacy, userToken } = useAppContext();
  const [data, setData] = React.useState(null);

  async function getGeneralStats() {
    // Headers del request
    const config = {
      headers: {
        authToken: userToken,
      },
    };

    // mandar request
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const today = formatDate(new Date());
      const url = `${apiUrl}/reports/general-stats/${pharmacy.pharmacy_id}?today=${today}`;
      const res = await axios.get(url, config);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;
      setData(res.data.body.data);
    } catch (res) {
      console.log("GET SALES ERROR:", res);
      toast.error(getResponseError(res));
    }
  }

  React.useEffect(() => {
    getGeneralStats();
  }, []);

  return (
    <div className="top_statistics">
      <div className="item">
        <div className="left">
          <p className="label">Empleados</p>
          <p className="value">{data?.total_employees ?? "-"}</p>
        </div>
        <div className="right">
          <BadgeIcon fontSize="large" />
        </div>
      </div>
      <div className="item">
        <div className="left">
          <p className="label">Balance del Mes</p>
          <p className="value">
            ${data?.total_sale_quantity?.toFixed(2) ?? "0"}
          </p>
        </div>
        <div className="right">
          <AttachMoney fontSize="large" />
        </div>
      </div>
      <div className="item">
        <div className="left">
          <p className="label">Numero de Ventas</p>
          <p className="value">{data?.total_sales ?? "-"}</p>
        </div>
        <div className="right">
          <PriceCheck fontSize="large" />
        </div>
      </div>
      <div className="item">
        <div className="left">
          <p className="label">En Inventario</p>
          <p className="value">{data?.total_items ?? "-"}</p>
        </div>
        <div className="right">
          <Inventory fontSize="large" />
        </div>
      </div>
      <div className="item">
        <div className="left">
          <p className="label">Cajas</p>
          <p className="value">{data?.total_registers ?? "-"}</p>
        </div>
        <div className="right">
          <PointOfSale fontSize="large" />
        </div>
      </div>
    </div>
  );
}
