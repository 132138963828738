import { useAppContext } from "contexts/AppContext";
import toast from "react-hot-toast";
import { Outlet, Navigate } from "react-router-dom";

export default function authValidator({ allowedRoles }) {
  // Obtener usuario
  const { user } = useAppContext();

  // Si no hay usuario ir a login
  if (!user) return <Navigate to="/login" />;

  // Si el estatus del usuario es pendiente ir a new-user
  if (user.status === "pending") return <Navigate to="/new-user" />;

  // Si su rol no está dentro de los roles permitidos de la ruta
  if (!allowedRoles.find((role) => role === user?.role)) {
    toast.error("No tienes acceso a esa página");
    return <Navigate to="/my-profile" />; // Ir a home
  }

  // Permitir renderizar la ruta
  return <Outlet />;
}
