import React from "react";
import "./RegisterPharmacy.sass";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import PageContainer from "components/General/PageContainer/PageContainer";
import S3 from "aws-sdk/clients/s3";
import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function RegisterPharmacy() {
  const navigate = useNavigate();

  // Variables
  const bucketName = process.env.REACT_APP_BUCKET_NAME;
  const bucketRegion = process.env.REACT_APP_BUCKET_REGION;
  const bucketAccessKey = process.env.REACT_APP_BUCKET_ACCESS_KEY;
  const bucketSecretKey = process.env.REACT_APP_BUCKET_SECRET_KEY;

  // Create s3 instance
  const s3 = new S3({
    region: bucketRegion,
    accessKeyId: bucketAccessKey,
    secretAccessKey: bucketSecretKey,
  });

  // Input Values
  const [file, setFile] = React.useState(null);
  const [pharmacyName, setPharmacyName] = React.useState("");
  const [estate, setEstate] = React.useState("");
  const [city, setCity] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [exteriorNum, setExteriorNum] = React.useState("");
  const [interiorNum, setInteriorNum] = React.useState("");
  const [phoneNum1, setPhoneNum1] = React.useState("");
  const [ownerEmail, setOwnerEmail] = React.useState("");
  const [ownerName, setOwnerName] = React.useState("");
  const [ownerLastName, setOwnerLastName] = React.useState("");
  const [ownerPhoneNum, setOwnerPhoneNum] = React.useState("");
  const [ownerPassword, setOwnerPassword] = React.useState("");
  const [paid, setPaid] = React.useState(true);
  const [lastPaid, setLastPaid] = React.useState(null);

  // Add product
  async function registerPharmacy(e) {
    try {
      e.preventDefault();

      if (paid && !lastPaid) {
        toast.error("Debes seleccionar una fecha de pago");
      }

      let imgUrl = null;
      if (file !== null) {
        // Save image on aws s3
        const folderName = "pharmacy-logos/";
        const params = {
          Body: file,
          Bucket: bucketName,
          Key: folderName.concat(file.name),
          ContentDisposition: "inline",
          ContentType: "image/jpeg",
        };
        const resImg = await s3.upload(params).promise();
        // Check for errors
        const error = { message: "Hubo un error guardando la imágen." };
        if (!resImg.Location) throw error;
        imgUrl = resImg.Location;
      }

      // Save pharmacy data
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/pharmacys/create`;

      const body = {
        pharmacy_name: pharmacyName,
        pharmacy_logo: imgUrl,
        street,
        exterior_number: exteriorNum,
        interior_number: interiorNum,
        city,
        postal_code: postalCode,
        state: estate,
        name: ownerName,
        last_name: ownerLastName,
        created_at: new Date(),
        pharmacy_phone_num: phoneNum1,
        mail: ownerEmail,
        password: ownerPassword,
        phone_num: ownerPhoneNum,
        status: paid ? "active" : "pending_payment",
        last_time_paid: lastPaid,
      };

      // Check for errors
      const resData = await axios.post(url, body);
      if (!checkResponse(resData)) throw resData;

      // Navigate back
      navigate(-1);
      toast.success("Se registro la farmacia correctamente");
    } catch (res) {
      console.log("REGISTER PHARMACY ERROR: ", res);
      toast.error(
        getResponseError(res),
        "Hubo un error al registrar la farmacia"
      );
    }
  }

  return (
    <PageContainer>
      <div className="register_pharmacy">
        {/* Go Back Btn */}
        <BackPageBtn noMargin path="/farmacias" />

        {/* Título */}
        <h1>Registrar Farmacia</h1>

        {/* Formularios */}
        <div className="forms">
          {/* Formulario de Imágen */}
          <form className="img_form">
            <Button
              variant="outlined"
              onClick={() => {
                const imgInput = document.querySelector(".img_input");
                imgInput.click();
              }}
            >
              Seleccionar Imágen
            </Button>

            <input
              type="file"
              className="img_input"
              accept=".jpeg"
              onChange={(e) => setFile(e.target.files[0])}
            />

            <div className="img_container">
              {file ? (
                <img src={URL.createObjectURL(file)} alt="Producto" />
              ) : (
                <p>No has seleccionado una imágen</p>
              )}
            </div>
          </form>

          {/* Formulario de Datos */}
          <form className="data_form" onSubmit={(e) => registerPharmacy(e)}>
            <h3>Datos de Farmacia: </h3>
            <TextField
              className="text_area"
              required
              label="Nombre de Farmacia"
              variant="outlined"
              value={pharmacyName}
              onChange={(e) => setPharmacyName(e.target.value)}
            />
            <TextField
              label="Estado de la Republica"
              variant="outlined"
              required
              className="text_input"
              value={estate}
              onChange={(e) => setEstate(e.target.value)}
            />
            <TextField
              label="Ciudad"
              required
              variant="outlined"
              className="text_input"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <TextField
              label="Calle"
              variant="outlined"
              required
              className="text_input"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
            <TextField
              label="Codigo Postal"
              variant="outlined"
              className="text_input"
              required
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
            <TextField
              label="Numero Exterior"
              variant="outlined"
              required
              className="text_input"
              value={exteriorNum}
              onChange={(e) => setExteriorNum(e.target.value)}
            />
            <TextField
              label="Numero Interior"
              variant="outlined"
              className="text_input"
              value={interiorNum}
              onChange={(e) => setInteriorNum(e.target.value)}
            />
            <TextField
              label="Telefono"
              variant="outlined"
              required
              className="text_area"
              value={phoneNum1}
              onChange={(e) => setPhoneNum1(e.target.value)}
            />
            <FormControl className={`${paid ? "text_input" : "text_area"}`}>
              <InputLabel id="role-label">Sistema Pagado</InputLabel>
              <Select
                fullWidth
                label="Sistema Pagado"
                value={paid}
                onChange={(e) => setPaid(e.target.value)}
              >
                <MenuItem value>Si</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            {paid && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Fecha de Pago"
                  value={lastPaid}
                  onChange={(newValue) => {
                    setLastPaid(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            )}
            <h3>Datos de Dueño: </h3>

            <TextField
              className="text_area"
              label="Correo Electronico"
              required
              type="email"
              variant="outlined"
              value={ownerEmail}
              onChange={(e) => setOwnerEmail(e.target.value)}
            />

            <TextField
              label="Nombre"
              variant="outlined"
              required
              className="text_input"
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
            />

            <TextField
              label="Apellido"
              required
              variant="outlined"
              className="text_input"
              value={ownerLastName}
              onChange={(e) => setOwnerLastName(e.target.value)}
            />

            <TextField
              label="Numero Telefónico"
              variant="outlined"
              className="text_input"
              value={ownerPhoneNum}
              required
              onChange={(e) => setOwnerPhoneNum(e.target.value)}
            />

            <TextField
              label="Contraseña"
              variant="outlined"
              required
              type="password"
              className="text_input"
              value={ownerPassword}
              onChange={(e) => setOwnerPassword(e.target.value)}
            />

            <Button
              variant="contained"
              size="large"
              type="submit"
              className="add_btn"
            >
              Registrar Farmacia
            </Button>
          </form>
        </div>
      </div>
    </PageContainer>
  );
}
