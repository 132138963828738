import React, { Children } from "react";
import "./ToolsBar.sass";

export default function ToolsBar({ children, noMargin }) {
  const count = Children.count(children);

  return (
    <div className={`toolsbar ${noMargin && "no_margin"}`}>
      <div
        className="tools_container"
        style={{ width: `${188 * count + 30}px` }}
      >
        {children}
      </div>
    </div>
  );
}
