import React from "react";
import { Link } from "react-router-dom";
import "./HomeBanner.sass";

export default function HomeBanner() {
  return (
    <>
      {/* Main banner */}
      <div className="home_banner">
        {/* Image */}
        <img src="/imgs/banner.jpg" alt="Banner" />

        {/* Texts */}
        <div className="texts">
          {/* Title */}
          <h1>Mi Farmacia 360</h1>

          {/* Description */}
          <h2>
            El <span>punto de venta</span> que hará crecer tu{" "}
            <span>farmacia</span>
          </h2>

          {/* Button */}
          <Link to="/solicitar-demo">
            <button type="button" className="button">
              COMENZAR
            </button>
          </Link>
        </div>
      </div>

      {/* Features */}
      <div className="features">
        <div className="items">
          {/* Ecommerce */}
          <div className="item">
            <div className="text">
              <h2>Ecommerce</h2>
              <p>
                Tu farmacia podrá estará dentro del Ecommerce de Mi Farmacia 360
                para que alcances a más clientes
              </p>
            </div>
            <img src="/imgs/ecommerce.png" alt="Ecommerce" />
          </div>

          {/* Punto de Venta */}
          <div className="item">
            <div className="text">
              <h2>Punto de Venta</h2>
              <p>
                Podrás gestionar las ventas de los productos pudiendo ver
                reportes de ventas e ingresos
              </p>
            </div>
            <img src="/imgs/pos.png" alt="Ventas" />
          </div>

          {/* Control de Inventario */}
          <div className="item">
            <div className="text">
              <h2>Inventario</h2>
              <p>
                Podrás gesionar el inventario de tu farmacia agregando nuevos
                producos del catálogo general
              </p>
            </div>
            <img src="/imgs/inventory.png" alt="Inventario" />
          </div>

          {/* Facturación */}
          <div className="item">
            <div className="text">
              <h2>Facturación</h2>
              <p>
                Tus clientes podrán solicitar facturas de sus compras, y tu
                registrarás de las facturas de tus proveedores
              </p>
            </div>
            <img src="/imgs/invoice.png" alt="Facturación" />
          </div>
        </div>
      </div>
    </>
  );
}
