import { Button, TextField } from "@mui/material";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import { translateAddressType } from "helpers/translators";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import "./AddressForm.sass";

export default function AddressForm() {
  // Variables
  const { type, id, action } = useParams();
  const { addressOnView: address, userOnView } = useAppContext();
  const [editMode] = React.useState(!!(action === "edit"));

  // Input values
  const [street, setStreet] = React.useState(
    editMode && address.street ? address.street : ""
  );
  const [exteriorNumber, setExteriorNumber] = React.useState(
    editMode && address.exterior_number ? address.exterior_number : ""
  );
  const [interiorNumber, setInteriorNumber] = React.useState(
    editMode && address.interior_number ? address.interior_number : ""
  );
  const [city, setCity] = React.useState(
    editMode && address.city ? address.city : ""
  );
  const [state, setState] = React.useState(
    editMode && address.state ? address.state : ""
  );
  const [postalCode, setPostalCode] = React.useState(
    editMode && address.postal_code ? address.postal_code : ""
  );
  const [phone1, setPhone1] = React.useState(
    editMode && address.phone_num1 ? address.phone_num1 : ""
  );
  const [phone2, setPhone2] = React.useState(
    editMode && address.phone_num2 ? address.phone_num2 : ""
  );

  // Tools
  const navigate = useNavigate();

  // Edit address
  async function editAddress() {
    try {
      // Validate fields
      if (!validateInputs([street, exteriorNumber, city, state])) {
        toast.error(
          "Los campos: Calle, No. Exterior, Ciudad y Estado son obligatorios"
        );
        return;
      }

      // Make request
      const body = {
        street,
        exterior_number: exteriorNumber,
        interior_number: interiorNumber !== "" ? interiorNumber : null,
        city,
        state,
        postal_code: postalCode !== "" ? postalCode : null,
        phone_num1: phone1 !== "" ? phone1 : null,
        phone_num2: phone2 !== "" ? phone2 : null,
      };
      const params = { adress_type: address.adress_type };
      const url = `https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/adress/${id}`;
      const res = await axios.put(url, body, { params });

      // Check response
      if (!checkResponse(res)) throw res;

      // Go back to address view
      toast.success("Dirección actualizada correctamente");
      navigate(`/address/${type}/${id}`);
    } catch (res) {
      console.log("EDIT ADDRESS ERROR:", res);
      toast.error(getResponseError(res, "Hubo un error editando la dirección"));
    }
  }

  // Create address
  async function createAddress() {
    try {
      // Validate fields
      if (!validateInputs([street, exteriorNumber, city, state])) {
        toast.error("Debes llenar todos los campos");
        return;
      }

      // Make request
      const body = {
        user_id: null,
        employee_id: type === "employee" ? userOnView.employee_id : null,
        street,
        exterior_number: parseInt(exteriorNumber, 20),
        interior_number:
          interiorNumber !== "" ? parseInt(interiorNumber, 10) : null,
        city,
        state,
        postal_code: postalCode !== "" ? postalCode : null,
        phone_num1: phone1 !== "" ? phone1 : null,
        phone_num2: phone2 !== "" ? phone2 : null,
        adress_type: type,
      };
      const url =
        "https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/adress/create";
      const res = await axios.post(url, body);

      // Check response
      if (!checkResponse(res)) throw res;

      // Alert and go back
      toast.success("Dirección creada correctamente");
      navigate(-1);
    } catch (res) {
      console.log("CREATE ADDRESS ERROR:", res);
      toast.error(getResponseError(res, "Hubo un error creando la dirección"));
    }
  }

  return (
    <PageContainer fitWidth>
      <div className="address_form">
        {/* Back Button */}
        <div className="back_btn_container">
          <BackPageBtn noMargin />
        </div>

        {/* Title */}
        <h1>
          {editMode ? "Editar" : "Crear"} Dirección de{" "}
          {translateAddressType(address?.adress_type || type)}
        </h1>

        {/* Info */}
        <div className="card-info">
          <div className="row">
            <TextField
              label="Calle"
              variant="outlined"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              className="input"
            />
            <TextField
              label="No. Exterior"
              variant="outlined"
              value={exteriorNumber}
              onChange={(e) => setExteriorNumber(e.target.value)}
              className="input"
            />
          </div>

          <div className="row">
            <TextField
              label="No. Interior (Opcional)"
              variant="outlined"
              value={interiorNumber}
              onChange={(e) => setInteriorNumber(e.target.value)}
              className="input"
            />
            <TextField
              label="Ciudad o Municipio"
              variant="outlined"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="input"
            />
          </div>

          <div className="row">
            <TextField
              label="Estado"
              variant="outlined"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="input"
            />
            <TextField
              label="Código Postal (Opcional)"
              variant="outlined"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              className="input"
            />
          </div>

          <div className="row">
            <TextField
              label="Telefóno 1 (Opcional)"
              variant="outlined"
              value={phone1}
              onChange={(e) => setPhone1(e.target.value)}
              className="input"
            />
            <TextField
              label="Telefóno 2 (Opcional)"
              variant="outlined"
              value={phone2}
              onChange={(e) => setPhone2(e.target.value)}
              className="input"
            />
          </div>
        </div>

        <div className="bottom">
          <Button
            variant="contained"
            onClick={editMode ? editAddress : createAddress}
          >
            Guardar Dirección
          </Button>
        </div>
      </div>
    </PageContainer>
  );
}
