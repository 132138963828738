import React from "react";
import "./ShowBills.sass";

export default function ShowBills({ billsCount }) {
  return (
    <div className="show_bills">
      <div className="count_section">
        {/* Bills */}
        <div className="headers">
          <h1 className="first">Billetes</h1>
          <h1 className="second">Cantidad</h1>
        </div>

        <div className="input_container">
          <div className="item">
            <p>1000 pesos</p>
            <p className="value">
              {billsCount?.b1000 !== null || billsCount?.b1000 !== undefined
                ? billsCount?.b1000
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>500 pesos</p>
            <p className="value">
              {billsCount?.b500 !== null || billsCount?.b500 !== undefined
                ? billsCount?.b500
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>200 pesos</p>
            <p className="value">
              {billsCount?.b200 !== null || billsCount?.b200 !== undefined
                ? billsCount?.b200
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>100 pesos</p>
            <p className="value">
              {billsCount?.b100 !== null || billsCount?.b100 !== undefined
                ? billsCount?.b100
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>50 pesos</p>
            <p className="value">
              {billsCount?.b50 !== null || billsCount?.b50 !== undefined
                ? billsCount?.b50
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>20 pesos</p>
            <p className="value">
              {billsCount?.b20 !== null || billsCount?.b20 !== undefined
                ? billsCount?.b20
                : "Sin información"}
            </p>
          </div>
        </div>
      </div>

      <div className="count_section">
        {/* Coins */}
        <div className="headers">
          <h1 className="first">Monedas</h1>
          <h1 className="second">Cantidad</h1>
        </div>

        <div className="input_container">
          <div className="item">
            <p>20 pesos</p>
            <p className="value">
              {billsCount?.c20 !== null || billsCount?.c20 !== undefined
                ? billsCount?.c20
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>10 pesos</p>
            <p className="value">
              {billsCount?.c10 !== null || billsCount?.c10 !== undefined
                ? billsCount?.c10
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>5 pesos</p>
            <p className="value">
              {billsCount?.c5 !== null || billsCount?.c5 !== undefined
                ? billsCount?.c5
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>2 pesos</p>
            <p className="value">
              {billsCount?.c2 !== null || billsCount?.c2 !== undefined
                ? billsCount?.c2
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>1 peso</p>
            <p className="value">
              {billsCount?.c1 !== null || billsCount?.c1 !== undefined
                ? billsCount?.c1
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>50 centavos</p>
            <p className="value">
              {billsCount?.cc50 !== null || billsCount?.cc50 !== undefined
                ? billsCount?.cc50
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>20 centavos</p>
            <p className="value">
              {billsCount?.cc20 !== null || billsCount?.cc20 !== undefined
                ? billsCount?.cc20
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>10 centavos</p>
            <p className="value">
              {billsCount?.cc10 !== null || billsCount?.cc10 !== undefined
                ? billsCount?.cc10
                : "Sin información"}
            </p>
          </div>
          <div className="item">
            <p>5 centavos</p>
            <p className="value">
              {billsCount?.cc5 !== null || billsCount?.cc5 !== undefined
                ? billsCount?.cc5
                : "Sin información"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
