import React from "react";
import "./LandingFooter.sass";

export default function Footer() {
  return (
    <footer className="landing_footer">
      <div className="container">
        <h1>Mi Farmacia 360 &reg;</h1>
      </div>
    </footer>
  );
}
