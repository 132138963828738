import { Check, Lock } from "@mui/icons-material";
import { Button, InputAdornment, TextField } from "@mui/material";
import axios from "axios";
import LoadingComponent from "components/General/LoadingComponent/LoadingComponent";
import PageNotFound from "components/General/PageNotFound/PageNotFound";
import ToasterComp from "components/General/Toaster/Toaster";
import { checkResponse, getResponseError } from "helpers/requests";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ResetPassword.sass";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const { endpointLink } = useParams();
  const [loading, setLoading] = useState(true);
  const [requestData, setRequestData] = useState(null);

  const navigate = useNavigate();

  async function isEdnpointLinkValid() {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/auth/recover/verify-url-endpoint?endpoint=${endpointLink}`;
      const request = await axios.get(url);
      if (!checkResponse(request)) throw request;
      setRequestData(request.data.body.data);
      setLoading(false);
    } catch (error) {
      console.error(`VALIDATE LINK ERROR: ${getResponseError(error)}`);
      setLoading(false);
    }
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (newPassword !== confirm) {
        toast.error("Las contraseñas no coinciden");
        return;
      }
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/auth/recover`;
      const request = await axios.post(url, {
        endpoint_id: requestData.link_id,
        new_password: newPassword,
      });
      if (!checkResponse(request)) throw request;
      navigate("/login");
      toast.success("Se actualizo tu contraseña correctamente");
    } catch (error) {
      console.error(`CHANGE PWD ERROR: ${getResponseError(error)}`);
      toast.error(getResponseError(error));
    }
  }

  React.useEffect(() => {
    isEdnpointLinkValid();
  }, [endpointLink]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (!requestData) {
    return <PageNotFound />;
  }

  return (
    <>
      <ToasterComp />
      <div className="right_container">
        <form className="form" onSubmit={(e) => handleSubmit(e)}>
          <h1>Recupera tu Contraseña</h1>

          <TextField
            fullWidth
            className="input"
            label="Nueva Contraseña"
            required
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock className="icon" />
                </InputAdornment>
              ),
            }}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            fullWidth
            className="input"
            label="Confrimar Contraseña"
            required
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Check className="icon" />
                </InputAdornment>
              ),
            }}
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
          />

          <Button
            className="btn"
            variant="contained"
            fullWidth
            size="large"
            type="submit"
          >
            Cambiar Contraseña
          </Button>

          <Link to="/login" className="forgot_psw">
            <p>Ir a login</p>
          </Link>
        </form>
      </div>
    </>
  );
}

export default ResetPassword;
