import React from "react";
import "./LandingLayout.sass";
import Footer from "../LandingFooter/LandingFooter";
import Navbar from "../LandingNavbar/LandingNavbar";

export default function LandingLayout({ Page, Banner }) {
  return (
    <div className="landing_layout">
      <Navbar />
      {Banner && <Banner />}
      {Page && (
        <div className="landing_container">
          <Page />
        </div>
      )}
      <Footer />
    </div>
  );
}
