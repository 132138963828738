import axios from "axios";

class FileService {
  async uploadFile(file, pharmacyId, fileName, folderPath) {
    try {
      const base64 = await this.toBase64(file);
      const fileApi = `${process.env.REACT_APP_UPLOAD_API}/files`;

      const body = {
        base64Image: base64,
        pharmacyId,
        imageName: fileName,
        folderPath,
      };

      const response = await axios.post(fileApi, body);
      const resData = JSON.parse(response.data.body);
      const { url } = resData.data;

      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async deleteFile(objectKey) {
    try {
      await axios.post(`${process.env.REACT_APP_UPLOAD_API}/files/delete`, {
        storageUrl: objectKey,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
}

const fileService = new FileService();
export default fileService;
