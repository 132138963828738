import BadgeSection from "components/Graphs/RegulatorySection/BadgeSection/Badges/BadgeSection";
import CardexSection from "components/Graphs/RegulatorySection/Kardex/KardexSection";

function RegulatorySection() {
  return (
    <div className="section">
      <CardexSection />
      <BadgeSection />
    </div>
  );
}

export default RegulatorySection;
