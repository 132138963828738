import { useAppContext } from "contexts/AppContext";
import SearchIcon from "@mui/icons-material/Search";
import { es } from "date-fns/locale";
import "./KardexSection.sass";
import TuneIcon from "@mui/icons-material/Tune";
import React from "react";
import { formatDate } from "helpers/date_formaters";
import { Button, InputAdornment, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ChangePageBtn from "components/General/ChangePageBtn/ChangePageBtn";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";
import toast from "react-hot-toast";
import KardexTable from "./KardexTable";

function CardexSection() {
  // VARIABLES DE USO
  const { pharmacy, userToken } = useAppContext();
  const [products, setProducts] = React.useState([]);
  const [initialDate, setInitialDate] = React.useState(null);
  const [finalDate, setFinalDate] = React.useState(null);
  const [query, setQuery] = React.useState("");
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  // Current page showing
  const [page, setPage] = React.useState(1);
  const [onView, setOnView] = React.useState(products);

  function clearSearch() {
    setInitialDate(null);
    setFinalDate(null);
    setQuery("");
  }

  async function getProducts() {
    // mandar request
    try {
      if (initialDate !== null && finalDate === null && query === "") {
        return;
      }
      setIsLoading(true);

      // request headers
      const config = {
        headers: {
          authToken: userToken,
        },
      };

      const body = {
        pharmacy_id: pharmacy.pharmacy_id,
        sku: query && query !== "" ? query : null,
        final_date: finalDate != null ? formatDate(finalDate) : null,
        initial_date: initialDate != null ? formatDate(initialDate) : null,
      };
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/reports/cardex`;
      const res = await axios.post(url, body, config);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;
      setProducts(res.data.body.products);
      setIsLoading(false);
    } catch (res) {
      console.log("GET BATCHES ERROR:", res);
      toast.error(getResponseError(res));
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    getProducts();

    // Esconder boton dependiendo si el usuario realiza un filtro
    if (query !== "" || finalDate != null || initialDate != null) {
      setIsFiltering(true);
    } else {
      setIsFiltering(false);
    }
  }, [initialDate, finalDate, query]);

  React.useEffect(() => {
    const productsPerPage = 10;
    // Calcular desde donde se muestran los productos basandose en el num de pagina
    // Ej: page: 2   from: (2*5) - 5 = 5;
    const from = page * productsPerPage - productsPerPage;
    setOnView(Object.values(products).slice(from, from + productsPerPage));
  }, [page, products]);

  return (
    <div className="cardex_table">
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <div className="header">
          <h2>Consultar Cardex</h2>
          <p>Buscar por sku o fecha de venta: </p>
          <form className="forms">
            {/* Buscar y ordenar por */}
            <TextField
              label="código de barras"
              id="searchProductField"
              variant="outlined"
              className="text_input"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className="date-pickers">
              <div className="picker">
                <DatePicker
                  label="Mes inicial"
                  views={["month", "year"]}
                  value={initialDate}
                  onChange={(newValue) => {
                    setInitialDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div className="picker">
                <DatePicker
                  label="Mes final"
                  views={["month", "year"]}
                  value={finalDate}
                  onChange={(newValue) => {
                    setFinalDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </div>
          </form>
          {isFiltering === true && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<TuneIcon />}
              onClick={() => clearSearch()}
            >
              Limpiar Busqueda
            </Button>
          )}
        </div>
      </LocalizationProvider>
      <hr />

      <KardexTable
        products={onView}
        isLoading={isLoading}
        allProducts={products}
      />

      {/* Boton para paginacion de productos */}
      <ChangePageBtn
        page={page}
        setPage={setPage}
        data={products}
        isArray={false}
      />
    </div>
  );
}

export default CardexSection;
