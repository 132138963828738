import axios from "axios";
import LoadingComponent from "components/General/LoadingComponent/LoadingComponent";
import { useAppContext } from "contexts/AppContext";
import { formatDate } from "helpers/date_formaters";
import { checkResponse, getResponseError } from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import BarsGraph from "../GraphContainers/BarsGraph/BarsGraph";
import PieGraph from "../GraphContainers/PieGraph/PieGraph";
import "./InventorySection.sass";
import TopSoldProducts from "./TopSoldProducts/TopSoldProducts";

export default function InventorySection({ dateRange }) {
  const { pharmacy, userToken } = useAppContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [compositionLabels, setCompositionLabels] = React.useState(null);
  const [compositionValues, setCompositionValues] = React.useState(null);
  const [salesValues, setSalesValues] = React.useState(null);
  const [salesLabels, setSalesLabels] = React.useState(null);

  async function getInventoryComposition() {
    try {
      const config = {
        headers: {
          authToken: userToken,
        },
      };
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/reports/inventory/${pharmacy.pharmacy_id}`;
      const res = await axios.get(url, config);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;
      setCompositionValues(res.data.body.data.values);
      setCompositionLabels(res.data.body.data.labels);
    } catch (res) {
      toast.error(getResponseError(res));
    }
  }

  async function getSalesByCategory() {
    try {
      const config = {
        headers: {
          authToken: userToken,
        },
      };
      const today = formatDate(new Date());
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/reports/inventory/${pharmacy.pharmacy_id}/sales-by-category?date_range=${dateRange}&today=${today}`;
      const res = await axios.get(url, config);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;
      setSalesValues(res.data.body.data.values);
      setSalesLabels(res.data.body.data.labels);
    } catch (res) {
      toast.error(getResponseError(res));
    }
  }

  React.useEffect(() => {
    getInventoryComposition();
    getSalesByCategory();
    setIsLoading(false);
  }, []);

  // Actualizar graficas cuando cambie la fecha
  React.useEffect(() => {
    setIsLoading(true);
    getSalesByCategory();
    setIsLoading(false);
  }, [dateRange]);

  return (
    <div className="section">
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className="flex">
            <PieGraph
              title="Composicion de Inventario"
              chartLabels={compositionLabels}
              chartData={compositionValues}
              width={45}
            />
            <BarsGraph
              title="Ventas por categorias"
              chartLabels={salesLabels}
              chartData={salesValues}
              width={45}
            />
          </div>

          <TopSoldProducts dateRange={dateRange} />
        </>
      )}
    </div>
  );
}
