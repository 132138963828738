import PageLayout from "components/General/PageLayout/PageLayout";
import LandingLayout from "components/Landing/LandingLayout/LandingLayout";
import HomeBanner from "components/Landing/HomeBanner/HomeBanner";
import InitialRoute from "router/InitialRoute";
import Login from "pages/Auth/Login/Login";
import NewUser from "pages/Auth/NewUser/NewUser";
import Home from "pages/Landing/Home/Home";
import Demo from "pages/Landing/Demo/Demo";
import Us from "pages/Landing/Us/Us";
import System from "pages/Landing/System/System";
import RecoverPassword from "pages/Auth/RecoverPassword/RecoverPassword";
import ResetPassword from "pages/Auth/ResetPassword/ResetPassword";
import Contact from "pages/Landing/Contact/Contact";

const routes = [
  // ____________________ Rutas Públicas ____________________
  {
    path: "",
    element: <InitialRoute />,
  },
  {
    path: "home",
    element: <LandingLayout Banner={HomeBanner} Page={Home} />,
  },
  {
    path: "nosotros",
    element: <LandingLayout Page={Us} />,
  },
  {
    path: "punto-de-venta",
    element: <LandingLayout Page={System} />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "recuperar-acceso",
    element: <RecoverPassword />,
  },
  {
    path: "recuperar/:endpointLink",
    element: <ResetPassword />,
  },
  {
    path: "solicitar-demo",
    element: <Demo />,
  },
  {
    path: "contacto",
    element: <Contact />,
  },
  {
    path: "new-user",
    element: <PageLayout title="Nuevo Usuario" Page={NewUser} />,
  },
];

export default routes;
