import { Button, IconButton, TextField } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./FilterByDatesModal.sass";

export default function FilterByDatesModal({
  showModal,
  outInitialDate,
  outFinalDate,
  setOutInitialDate,
  setOutFinalDate,
  setPage,
}) {
  const [initialDate, setInitialDate] = React.useState(
    outInitialDate || new Date().toISOString().split("T")[0]
  );
  const [finalDate, setFinalDate] = React.useState(
    outFinalDate || new Date().toISOString().split("T")[0]
  );

  function searchHandler() {
    setOutInitialDate(initialDate);
    setOutFinalDate(finalDate);
    setPage(1);
    showModal(false);
  }

  return (
    <div className="filter_dates_modal_bg">
      <div className="filer_dates_modal">
        <div className="close_btn">
          <IconButton onClick={() => showModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>

        <h1>Filtrar por Fechas</h1>

        <div className="inputs_container">
          <TextField
            type="date"
            className="input"
            label="Fecha Inicial"
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
          <TextField
            type="date"
            className="input"
            label="Fecha Final"
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </div>

        <div className="buttons">
          <Button
            variant="outlined"
            sx={{ marginRight: "10px" }}
            onClick={() => {
              setOutInitialDate(null);
              setOutFinalDate(null);
              setPage(1);
              showModal(false);
            }}
          >
            Limpiar
          </Button>
          <Button variant="contained" onClick={searchHandler}>
            Buscar
          </Button>
        </div>
      </div>
    </div>
  );
}
