import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import { scrollPageContainer } from "helpers/page";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./RegisterDifference.sass";

export default function RegisterDifference() {
  // Variables
  const { closureRequestOnView: closure, userToken } = useAppContext();
  const [realCash, setRealCash] = React.useState(
    closure?.totals?.cashTotal || 0
  );
  const [realVisa, setRealVisa] = React.useState(
    closure?.totals?.cardVisaTotal || 0
  );
  const [realMC, setRealMC] = React.useState(closure?.totals?.cardMCTotal || 0);
  const [realAMEX, setRealAMEX] = React.useState(
    closure?.totals?.cardAMEXTotal || 0
  );
  const [realVoucher, setRealVoucher] = React.useState(
    closure?.totals?.voucherTotal || 0
  );
  const [diffCash, setDiffCash] = React.useState(0);
  const [diffVisa, setDiffVisa] = React.useState(0);
  const [diffMC, setDiffMC] = React.useState(0);
  const [diffAMEX, setDiffAMEX] = React.useState(0);
  const [diffVoucher, setDiffVoucher] = React.useState(0);
  const [totalDiff, setTotalDiff] = React.useState(0);
  const [responsible, setResponsible] = React.useState("pharmacy");
  const navigate = useNavigate();

  // Register Difference
  async function registerDifference() {
    try {
      // Validate fields
      if (
        !validateInputs([
          realCash,
          realVisa,
          realMC,
          realAMEX,
          realVoucher,
          diffCash,
          diffVisa,
          diffMC,
          diffAMEX,
          diffVoucher,
          totalDiff,
          responsible,
        ])
      ) {
        toast.error("Algunos campos están vacíos");
        return;
      }

      // Create body
      const body = {
        operation_id: closure?.operation_id,
        cash_register_id: closure?.cash_register_id,
        cashier: closure?.employee_id,
        responsible,
        real_cash_ammount: realCash,
        real_visa_ammount: realVisa,
        real_mc_ammount: realMC,
        real_amex_ammount: realAMEX,
        real_voucher_ammount: realVoucher,
        cash_difference: diffCash,
        visa_difference: diffVisa,
        mc_difference: diffMC,
        amex_difference: diffAMEX,
        voucher_difference: diffVoucher,
      };

      // Make request
      const headers = { authToken: userToken };
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/cash-register/differences`;
      const res = await axios.post(url, body, { headers });

      // Check respose
      if (!checkResponse(res)) throw res;

      // Success and go back
      toast.success("Diferencia registrada y caja cerrada correctamente");
      navigate("/cash-registers/closure-requests");
    } catch (error) {
      console.log("REGISTER DIFFERENCE ERROR:", error);
      toast.error(
        getResponseError(error, "Hubo un error registrando la diferencia")
      );
    }
  }

  // On render
  React.useEffect(() => {
    scrollPageContainer();
  }, []);

  React.useEffect(() => {
    setTotalDiff(diffCash + diffVisa + diffMC + diffAMEX + diffVoucher);
  }, [diffCash, diffVisa, diffMC, diffAMEX, diffVoucher]);

  return (
    <PageContainer fitWidth>
      <div className="register_difference">
        {/* Back Btn */}
        <BackPageBtn noMargin path="/cash-registers/closure-requests/view" />

        {/* Title */}
        <h1>Registrar Diferencia</h1>

        {/* General Info */}
        <div className="section">
          <h2>Información del Cierre</h2>

          <div className="info_item">
            <p>Empleado:</p>
            <span>
              {closure?.name || "Sin nombre"}{" "}
              {closure?.last_name || "Sin apellido"}
            </span>
          </div>
          <div className="info_item">
            <p>Fecha de Cierre:</p>
            <span>{closure?.operation_date || "Sin información"}</span>
          </div>
        </div>

        {/* Money Inputs */}
        <div className="section">
          <h2>Cantidades Reales</h2>

          {/* Table */}
          <table className="input_table">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Registrado</th>
                <th>Real</th>
                <th>Diferencia</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Efectivo</td>
                <td>
                  {closure?.totals?.cashTotal !== null
                    ? closure?.totals?.cashTotal
                    : "Sin información"}
                </td>
                <td>
                  <input
                    type="number"
                    value={realCash.toString()}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setRealCash(0);
                        setDiffCash(0 - closure?.totals?.cashTotal);
                      } else {
                        setRealCash(parseFloat(e.target.value));
                        setDiffCash(
                          parseFloat(e.target.value) -
                            closure?.totals?.cashTotal
                        );
                      }
                    }}
                  />
                </td>
                <td className={diffCash < 0 ? "error" : ""}>{diffCash}</td>
              </tr>

              <tr>
                <td>Visa</td>
                <td>
                  {closure?.totals?.cardVisaTotal !== null
                    ? closure?.totals?.cardVisaTotal
                    : "Sin información"}
                </td>
                <td>
                  <input
                    type="number"
                    value={realVisa.toString()}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setRealVisa(0);
                        setDiffVisa(0 - closure?.totals?.cardVisaTotal);
                      } else {
                        setRealVisa(parseFloat(e.target.value));
                        setDiffVisa(
                          parseFloat(e.target.value) -
                            closure?.totals?.cardVisaTotal
                        );
                      }
                    }}
                  />
                </td>
                <td className={diffVisa < 0 ? "error" : ""}>{diffVisa}</td>
              </tr>

              <tr>
                <td>Master Card</td>
                <td>
                  {closure?.totals?.cardMCTotal !== null
                    ? closure?.totals?.cardMCTotal
                    : "Sin información"}
                </td>
                <td>
                  <input
                    type="number"
                    value={realMC.toString()}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setRealMC(0);
                        setDiffMC(0 - closure?.totals?.cardMCTotal);
                      } else {
                        setRealMC(parseFloat(e.target.value));
                        setDiffMC(
                          parseFloat(e.target.value) -
                            closure?.totals?.cardMCTotal
                        );
                      }
                    }}
                  />
                </td>
                <td className={diffMC < 0 ? "error" : ""}>{diffMC}</td>
              </tr>

              <tr>
                <td>AMEX</td>
                <td>
                  {closure?.totals?.cardAMEXTotal !== null
                    ? closure?.totals?.cardAMEXTotal
                    : "Sin información"}
                </td>
                <td>
                  <input
                    type="number"
                    value={realAMEX.toString()}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setRealAMEX(0);
                        setDiffAMEX(0 - closure?.totals?.cardAMEXTotal);
                      } else {
                        setRealAMEX(parseFloat(e.target.value));
                        setDiffAMEX(
                          parseFloat(e.target.value) -
                            closure?.totals?.cardAMEXTotal
                        );
                      }
                    }}
                  />
                </td>
                <td className={diffAMEX < 0 ? "error" : ""}>{diffAMEX}</td>
              </tr>

              <tr>
                <td>Vales</td>
                <td>
                  {closure?.totals?.voucherTotal !== null
                    ? closure?.totals?.voucherTotal
                    : "Sin información"}
                </td>
                <td>
                  <input
                    type="number"
                    value={realVoucher.toString()}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setRealVoucher(0);
                        setDiffVoucher(0 - closure?.totals?.voucherTotal);
                      } else {
                        setRealVoucher(parseFloat(e.target.value));
                        setDiffVoucher(
                          parseFloat(e.target.value) -
                            closure?.totals?.voucherTotal
                        );
                      }
                    }}
                  />
                </td>
                <td className={diffVoucher < 0 ? "error" : ""}>
                  {diffVoucher}
                </td>
              </tr>

              <tr className="totals">
                <td>Total</td>
                <td>
                  {closure?.totals?.sessionTotal !== null
                    ? closure?.totals?.sessionTotal
                    : "Sin información"}
                </td>
                <td>{realCash + realVisa + realMC + realAMEX + realVoucher}</td>
                <td className={totalDiff < 0 ? "error" : ""}>{totalDiff}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Select responsable */}
        <div className="section">
          <h2>Procedimiento</h2>
          <p className="prodecure_label">¿Quién asumirá la diferencia?</p>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={responsible}
              onChange={(e) => setResponsible(e.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="pharmacy"
                control={<Radio />}
                label="Farmacia"
              />
              <FormControlLabel
                value="employee"
                control={<Radio />}
                label="Empleado"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="btn_container">
          <Button
            className="end_btn"
            variant="contained"
            onClick={registerDifference}
          >
            Registrar diferencia y validar cierre
          </Button>
        </div>
      </div>
    </PageContainer>
  );
}
