import axios from "axios";
import { downloadExcel } from "helpers/excel";
import { checkResponse } from "helpers/requests";
import toast from "react-hot-toast";

export async function getBatchesOfTheMonth(pharmacyId, monthOnView, userToken) {
  try {
    // Hacer petición
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/accounting/${pharmacyId}/utilities`;
    const params = { date: monthOnView };
    const headers = { authToken: userToken };
    const res = await axios.get(url, { params, headers });

    // Checar respuesta
    if (!checkResponse(res)) throw res;

    return { success: true, batches_data: res?.data?.body?.batches_data };
  } catch (error) {
    console.log("GET BATCHES UTILITIES ERROR:", error);
    toast.error("Hubo un error obteniendo las utilidades");
    return { success: false };
  }
}

export function countBatches(batchesData) {
  // Mapa que relaciona un batch_id con la cantidad de veces que se vendió en total y sus datos
  const batchesCount = {};

  // Contar cuantas veces se vendió cada batch
  batchesData.forEach((row) => {
    // Si ya estaba registrado el badge_id
    if (batchesCount[row.badge_id]) {
      // Suma la cantidad actual con la cantidad del row
      const curQuantity = batchesCount[row.badge_id].quantity;
      const rowQname = row.quantity;
      batchesCount[row.badge_id].quantity = curQuantity + rowQname;
    } else {
      // Inicializa con los datos y la cantidad de este row
      batchesCount[row.badge_id] = row;
    }
  });

  return batchesCount;
}

export function downloadUtilitiesExcel(batchesData) {
  // Set Labels
  const data = [];
  const headers = [
    "Producto",
    "Lote",
    "Costo Unitario",
    "Costo de Venta",
    "Unidades Vendidas",
    "Utilidad",
  ];
  data.push(headers);

  // For each row
  batchesData.forEach((r) => {
    const row = [];
    row.push(r?.product_name);
    row.push(r?.badge);
    row.push(r?.item_cost);
    row.push(r?.item_price);
    row.push(r?.quantity);
    row.push(((r?.item_price || 0) - (r?.item_cost || 0)) * r?.quantity || 0);
    data.push(row);
  });

  downloadExcel(data, "Utilidades");
}
