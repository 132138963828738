import React from "react";
import { Button, InputAdornment, TextField } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { Link, useNavigate } from "react-router-dom";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import axios from "axios";
import { useAppContext } from "contexts/AppContext";
import { encriptar } from "helpers/encrypt";
import toast from "react-hot-toast";
import AuthLayout from "components/Auth/AuthLayout";

export default function Login() {
  // Variables
  const { user, setUser, setUserToken, pharmacy, setPharmacy } =
    useAppContext();
  const [showPhoneViewAlert, setShowPhoneViewAlert] = React.useState(false);

  // Input Values
  const [mail, setMail] = React.useState("");
  const [password, setPassword] = React.useState("");

  // Tools
  const navigate = useNavigate();

  // Login
  async function login() {
    try {
      // Validate fields
      if (!validateInputs([mail, password])) {
        toast.error("Debes completar todos los campos");
        return;
      }

      // Make employee request
      const body = { mail, password };
      const urlEmployee =
        "https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/auth/pos/login";
      const resEmployee = await axios.post(urlEmployee, body);

      // Check response
      if (!checkResponse(resEmployee)) throw resEmployee;

      // Set user and token
      setUser(resEmployee?.data?.body?.employee);
      setUserToken(resEmployee?.data?.body?.token);

      // Make pharmacy request
      const headers = { authToken: resEmployee?.data?.body?.token };
      const urlPharmacy = `https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/pharmacys/${resEmployee?.data?.body?.employee?.pharmacy_id}`;
      const resPharmacy = await axios.get(urlPharmacy, { headers });

      // Set pharmacy
      setPharmacy(resPharmacy?.data?.body?.pharmacy);

      // Save user and pharamcy on local storage
      const lsKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;
      const tokenStr = resEmployee?.data?.body?.token;

      localStorage.setItem(
        "mf360-user-token",
        await encriptar(lsKey, tokenStr)
      );

      // Go home
      navigate("/my-profile");
    } catch (res) {
      toast.error(
        getResponseError(res, "Hubo un error ingresando a la cuenta")
      );
    }
  }

  // Every time user changes check if there is a user
  React.useEffect(() => {
    if (user && pharmacy) navigate("/my-profile");
  }, [user, pharmacy]);

  React.useEffect(() => {
    if (window.matchMedia("(max-width: 900px)").matches) {
      setShowPhoneViewAlert(true);
    }
  }, []);

  return (
    <AuthLayout showBackPageBtn>
      <div className="right_container">
        <div className="form">
          <img src="imgs/logo.png" alt="" className="logo" />

          <h1>Bienvenido</h1>

          <TextField
            fullWidth
            className="input"
            label="Correo Electrónico"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon className="icon" />
                </InputAdornment>
              ),
            }}
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
          <TextField
            fullWidth
            className="input"
            label="Contraseña"
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon className="icon" />
                </InputAdornment>
              ),
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            className="btn"
            variant="contained"
            fullWidth
            size="large"
            onClick={login}
          >
            Ingresar
          </Button>

          <Link to="/recuperar-acceso" className="forgot_psw">
            <p>¿Olvidaste tu contraseña?</p>
          </Link>
        </div>
      </div>

      {/* Phone View Alert */}
      {showPhoneViewAlert && (
        <div className="phone_view_alert_container">
          <div className="alert">
            <h1>Vista Incorrecta</h1>
            <p>
              La app Mi Farmacia 360 fue diseñada para usarse en computadoras.
              Para una mejor experiencia cambia a un dispositivo con pantalla
              más amplia.
            </p>
          </div>
        </div>
      )}
    </AuthLayout>
  );
}
