import { Menu, MenuItem } from "@mui/material";
import React from "react";
import "./NotificationList.sass";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import { useAppContext } from "contexts/AppContext";
import { useNavigate } from "react-router-dom";

export default function NotificationList({ anchorEl, open, setAnchor }) {
  // Variables
  const { notifications, setProductOnView } = useAppContext();
  const navigate = useNavigate();

  /*
  This object will allow to display diferent notifications depending on the notification type,
  each type has an specific icon and a clickHandler that will occur when the menu item is clicked
  */
  const notificationTypes = {
    // Low inventory data
    lowInventory: {
      icon: (
        <AssignmentLateIcon className="icon" style={{ color: "#FFA117" }} />
      ),
      clickHandler: (product) => {
        setProductOnView(product); // Set product on view
        setAnchor(null); // Close notification menu
        navigate("/item/view"); // Go to item view
      },
    },
  };

  return (
    notifications.length > 0 && (
      <Menu
        className="notifications_menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchor(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {notifications.map((n) => (
          <MenuItem
            key={n.id}
            className="menu_item"
            onClick={() => notificationTypes[n.type].clickHandler(n.data)}
          >
            {/* Icon */}
            {notificationTypes[n.type].icon}
            <p className="msg">{n.msg}</p>
          </MenuItem>
        ))}
      </Menu>
    )
  );
}
