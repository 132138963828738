export function translateRole(role) {
  switch (role) {
    case "employee":
      return "Empleado";

    case "pharmacy_admin":
      return "Administrador";

    case "pharmacy_owner":
      return "Dueño";

    case "anafarmex":
      return "anafarmex";

    case "nabu":
      return "nabu";

    default:
      return "-";
  }
}

export function translateEmployeeStatus(status) {
  switch (status) {
    case "inactive":
      return "Inactivo";

    case "pending":
      return "Pendiente";

    case "active":
      return "Activo";

    default:
      return "-";
  }
}

export function translateOrderStatus(status) {
  switch (status) {
    case "pending":
      return "Pendiente";

    case "canceled":
      return "Cancelado";

    case "on way":
      return "En camino";

    case "delivered":
      return "Entregado";

    default:
      return "-";
  }
}

export function translateItemStatus(status) {
  switch (status) {
    case "active":
      return "Activo";

    case "deleted":
      return "Eliminado";

    case "unavailable":
      return "Agotado";

    default:
      return "-";
  }
}

export function translatePharmacyStatus(status) {
  switch (status) {
    case "active":
      return "Activa";

    case "inactive":
      return "Inactiva";

    case "suspended":
      return "Suspendida";

    case "pending_payment":
      return "Pago pendiente";

    default:
      return "-";
  }
}

export function translateAddressType(type) {
  switch (type) {
    case "client":
      return "Cliente";

    case "provider":
      return "Proveedor";

    case "pharmacy":
      return "Farmacia";

    case "employee":
      return "Empleado";

    default:
      return "-";
  }
}

export function binaryStatus(status) {
  switch (status) {
    case 0:
      return "No";
    case 1:
      return "Si";
    default:
      return null;
  }
}

export function getCategoryNameById(categoryId, categories) {
  const category = categories?.find((c) => c?.category_id === categoryId);
  if (category) return category.category_name;
  return null;
}

export function getAccountMovementType(code) {
  switch (code) {
    case "charge":
      return "Cobro por uso del sistema";
    case "payment":
      return "Abono a cuenta";
    default:
      return null;
  }
}

export function getProfitMovementType(code) {
  switch (code) {
    case "sale":
      return "Venta de productos";
    case "invoice":
      return "Compra con factura";
    case "product_return":
      return "Devolución de producto";
    default:
      return null;
  }
}
