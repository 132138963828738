import { useAppContext } from "contexts/AppContext";
import React from "react";
import "./UtilitiesByBatchTable.sass";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, TextField } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import { cutDisplayText } from "helpers/string";
import {
  countBatches,
  downloadUtilitiesExcel,
  getBatchesOfTheMonth,
} from "./UtilitiesByBatchActions";

export default function UtilitiesByBatchTable() {
  const { pharmacy, userToken } = useAppContext();
  const [loading, setLoading] = React.useState(true);
  const [batchesData, setBatchesData] = React.useState([]);
  const [monthOnView, setMonthOnView] = React.useState(
    new Date().toISOString().split("T")[0]
  );

  async function getUtilitiesDataHandler() {
    const res = await getBatchesOfTheMonth(
      pharmacy?.pharmacy_id,
      monthOnView,
      userToken
    );

    if (res.success) {
      const batchesCount = countBatches(res.batches_data);
      setBatchesData(Object.values(batchesCount));
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getUtilitiesDataHandler();
  }, [monthOnView]);

  return (
    <div className="utilities_section">
      <div className="actions">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Seleccionar Mes"
            views={["month", "year"]}
            openTo="month"
            value={monthOnView}
            onChange={(newValue) => {
              const formatedDate = new Date(newValue)
                .toISOString()
                .split("T")[0];
              setMonthOnView(formatedDate);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          size="small"
          sx={{ ml: "10px" }}
          onClick={() => downloadUtilitiesExcel(batchesData)}
        >
          Descargar Excel
        </Button>
      </div>

      <div className="table_div">
        {!loading ? (
          <table className="table">
            <thead>
              <tr>
                <th>Producto</th>
                <th>Lote</th>
                <th className="amount_header">Costo Unitario</th>
                <th className="amount_header">Costo de Venta</th>
                <th className="amount_header">Vendidas</th>
                <th className="amount_header">Utilidad</th>
              </tr>
            </thead>

            <tbody>
              {batchesData.map((r, i) => (
                <tr key={i}>
                  <td>
                    {cutDisplayText(r?.product_name, 35) || "Sin información"}
                  </td>
                  <td>{r?.badge || "Sin información"}</td>
                  <td className="amount_cell">
                    ${r?.item_cost.toFixed(2) || "Sin información"}
                  </td>
                  <td className="amount_cell">
                    ${r?.item_price.toFixed(2) || "Sin información"}
                  </td>
                  <td className="amount_cell">
                    {r?.quantity || "Sin información"}
                  </td>
                  <td className="amount_cell">
                    $
                    {(
                      ((r?.item_price || 0) - (r?.item_cost || 0)) *
                      r?.quantity
                    ).toFixed(2) || 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <LoadingTable nCols={6} />
        )}
      </div>
    </div>
  );
}
