import React from "react";
import "./Topbar.sass";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useAppContext } from "contexts/AppContext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Menu, MenuItem, IconButton, Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Toaster from "components/General/Toaster/Toaster";
import NotificationList from "components/Notifications/NotificationList/NotificationList";
import { VisibilityOff } from "@mui/icons-material";

export default function Topbar({ title }) {
  // Variables
  const {
    user,
    setUser,
    pharmacy,
    setUserToken,
    setPharmacy,
    setIsOpenCashRegister,
    notifications,
    adminVisibility,
    setAdminVisibility,
  } = useAppContext();

  // Menu anchors
  const [profileMenuAnchor, setPofileMenuAnchor] = React.useState(null);
  const showProfileMenu = Boolean(profileMenuAnchor);
  const [notificationsMenuAnchor, setNotificationsMenuAnchor] =
    React.useState(null);
  const showNotificationsMenu = Boolean(notificationsMenuAnchor);

  // Tools
  const navigate = useNavigate();

  // Close sesion
  function closeSesion() {
    // Set values null
    setUser(null);
    setUserToken(null);
    setPharmacy(null);
    setIsOpenCashRegister(false);

    // Clean local storage
    localStorage.removeItem("mf360-user-token");

    // Go login page
    navigate("/login");
  }

  function handleReturnToAdminView() {
    setPharmacy(null);
    setAdminVisibility(false);
    setUser((prevUser) => {
      return { ...prevUser, role: "nabu", pharmacy_id: null };
    });
    navigate("/my-profile");
  }

  return (
    <div className="topbar">
      {/* Title */}
      <h1>{title}</h1>

      {/* Actions */}
      <div className="actions" />

      {/* User Info */}
      <div className="user_info">
        {/* User */}
        <div className="user">
          <img
            src={
              user.employee_photo ? user.employee_photo : "/imgs/no_user.jpg"
            }
            alt="User"
          />
          <p>
            {user.name} {user.last_name}
          </p>
          <ArrowDropDownIcon
            className="icon"
            onClick={(e) => setPofileMenuAnchor(e.currentTarget)}
          />

          {/* Profile Menu */}
          <Menu
            className="topbar_menu"
            anchorEl={profileMenuAnchor}
            open={showProfileMenu}
            onClose={() => setPofileMenuAnchor(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                navigate("/my-profile");
                setPofileMenuAnchor(null);
              }}
            >
              Mi Perfil
            </MenuItem>
            <MenuItem onClick={closeSesion}>Cerrar Sesión</MenuItem>
            {/* <MenuItem
              onClick={() => {
                navigate("/terminos-y-condiciones");
                setPofileMenuAnchor(null);
              }}
            >
              Términos y Condiciones
            </MenuItem> */}
          </Menu>
        </div>

        <IconButton
          className="notification_btn"
          onClick={(e) => setNotificationsMenuAnchor(e.currentTarget)}
        >
          <Badge badgeContent={notifications.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <NotificationList
          anchorEl={notificationsMenuAnchor}
          open={showNotificationsMenu}
          setAnchor={setNotificationsMenuAnchor}
        />

        {/* Admin View */}
        {adminVisibility && pharmacy && (
          <IconButton
            className="notification_btn"
            onClick={() => handleReturnToAdminView()}
          >
            <VisibilityOff />
          </IconButton>
        )}
      </div>

      {/* Toaster */}
      <Toaster />
    </div>
  );
}
