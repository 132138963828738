import { React, useState, useEffect } from "react";
import "./EmployePage.sass";
import PageContainer from "components/General/PageContainer/PageContainer";
import EmployeeTable from "components/Employees/EmployeeTable";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { checkResponse, getResponseError } from "helpers/requests";
import axios from "axios";
import { useAppContext } from "contexts/AppContext";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import toast from "react-hot-toast";

export default function EmployeePage() {
  // Variables
  const { pharmacy, user } = useAppContext();
  const [employees, setEmployees] = useState([]);
  const [loading, setIsLoading] = useState(true);

  // Funcion para obtener los empleados de la api
  async function getEmployees() {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const url = `${apiUrl}/employees/pharmacy/${pharmacy.pharmacy_id}`;
      const res = await axios.get(url);

      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;

      // Filtrar mi propia cuenta
      const filteredEmployees = res?.data?.body?.employees.filter(
        (emp) => emp.employee_id !== user.employee_id
      );

      setEmployees(filteredEmployees);
      setIsLoading(false);
    } catch (res) {
      console.log("GET EMPLOYEES ERROR:", res);
      toast.error(
        getResponseError(res),
        "Hubo un error obteniendo los empleados"
      );
    }
  }

  // Use effect para que la peticion solo se llama al ingresar a la pantalla
  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <PageContainer>
      <div className="employees">
        <form className="add-employee">
          {/* Botón de para navegar a creacion de empleado */}
          <Link to="/employees/create" className="link">
            <Button variant="contained" className="add_btn">
              Agregar empleado
            </Button>
          </Link>
        </form>
        {/* Tabla con los empleados */}
        {loading ? (
          <LoadingTable nCols={5} />
        ) : (
          <EmployeeTable employees={employees} />
        )}
      </div>
    </PageContainer>
  );
}
