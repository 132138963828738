/* eslint-disable no-use-before-define */
import axios from "axios";

export default async function printTicket(
  totalAmmount,
  products,
  user,
  pharmacy,
  physicalCashRegister,
  iva,
  without_iva,
  ticket_id,
  paymentMethods,
  date = new Date()
) {
  try {
    // Funcion que se comunica con el plugin de impresion para imprimir un ticket
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // Obtener la fecha y formatearla
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    const second = date.getSeconds().toString().padStart(2, "0");
    const today = `${dd}/${mm}/${yyyy} ${hour}:${minute}:${second}`;

    // eslint-disable-next-line no-unused-vars
    const body = {
      pharmacy_name: pharmacy?.pharmacy_name,
      employee_name: `${user?.name} ${user?.last_name}`,
      pharmacy_adress: `${pharmacy?.street}`,
      date: today,
      connection_type: physicalCashRegister?.printer_type,
      ip_adress: physicalCashRegister?.printer_ip_address,
      total: totalAmmount,
      products,
      logo: pharmacy?.logo_url,
      iva,
      without_iva,
      ticket_id,
      phone_num1: pharmacy.phone_num1,
      phone_num2: pharmacy.phone_num2,
      city: pharmacy.city,
      postal_code: pharmacy.postal_code,
      web_page: pharmacy.web_page,
      payment_methods: getPaymentMethodArray(paymentMethods),
    };

    const url = "http://localhost:8080/print/sale";
    const res = await axios.post(url, body, config);
    console.log(res);
  } catch (error) {
    console.log(error);
  }
}

function getPaymentMethodArray(paymentMethods) {
  const result = [];

  Object.keys(paymentMethods).forEach((key) => {
    const value = paymentMethods[key];
    const formatedValue = value !== "" ? parseFloat(value) : 0;

    if (formatedValue !== 0) {
      if (key === "cashMoney") {
        result.push("Efectivo");
      } else if (key === "cardVisaMoney") {
        result.push("Visa");
      } else if (key === "voucherMoney") {
        result.push("Vales");
      } else if (key === "cardMCMoney") {
        result.push("Master Card");
      } else if (key === "cardAMEXMoney") {
        result.push("American Express");
      }
    }
  });

  return result;
}
