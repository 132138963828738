import React from "react";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import "./OrderHeader.sass";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useAppContext } from "contexts/AppContext";
import { checkResponse, getResponseError } from "helpers/requests";
import axios from "axios";
import toast from "react-hot-toast";

export default function OrderHeader({ hideBackBtn }) {
  const [orderState, setOrderState] = React.useState("");
  const { orderOnView } = useAppContext();
  const date = orderOnView.header.ordered_date.substring(0, 10);
  const hour = orderOnView.header.ordered_date.substring(11, 16);

  const isSale = orderOnView.header.order_location === "pos";

  React.useEffect(() => {
    // Cambiar agregar guion al status en caso de que sea on way para poder agregar estilo
    const status =
      orderOnView.header.status === "on way"
        ? "on-way"
        : orderOnView.header.status;

    setOrderState(status);
  }, []);

  async function updateOrderStatus(status) {
    try {
      let udpatedStatus = status === "on-way" ? "on way" : status;

      const body = {
        status: udpatedStatus,
        total_ammount: orderOnView.header.total_ammount,
      };
      const url = `https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/orders/${orderOnView.header.order_id}`;
      const res = await axios.put(url, body);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;

      udpatedStatus = status;
      setOrderState(udpatedStatus);
    } catch (res) {
      console.log("GET ORDERS ERROR:", res);
      toast.error(
        getResponseError(res),
        "Hubo un error obteniendo el estado de la orden"
      );
    }
  }

  function navigateToMap() {
    // Funcion para navegar a google maps
    window.open(
      `https://www.google.com/maps/place/${orderOnView.header.street} ${orderOnView.header.postal_code}`,
      "_blank"
    );
  }

  return (
    <PageContainer smallMargins>
      <div className="order_header">
        {!hideBackBtn && <BackPageBtn />}
        {/* Datos */}
        <h1>{!isSale ? "Datos del pedido" : "Datos de Venta"}</h1>
        <div className="order-details">
          {/* Nombre de empleado o cliente */}

          <div className="order-data">
            <p className="data-title">
              {!isSale ? "Nombre de Cliente:" : "Vendido Por:"}
            </p>
            <p>{` ${orderOnView.header.name}  ${orderOnView.header.last_name}`}</p>
          </div>

          {/* Fecha y Hora de venta o pedido  */}

          <div className="order-data">
            <p className="data-title">Fecha y Hora</p>
            <p>
              {date} {hour}
            </p>
          </div>

          {/* Solo mostrar forma de estado si la venta viene del ecomerce */}
          {!isSale ? (
            <div className="order-data state">
              <p className="data-title">Estado</p>
              {/* Forma para seleccionar el estado de la orden */}
              <select
                name="select"
                value={orderState}
                className={`select-form ${orderState}`}
                onChange={(e) => updateOrderStatus(e.target.value)}
              >
                <option value="pending">Pendiente</option>
                <option value="on-way">En camino</option>
                <option value="delivered">Entregado</option>
              </select>
            </div>
          ) : (
            <div className="order-data">
              <p className="data-title">Venta Total</p>
              <p>${orderOnView.header.total_ammount}</p>
            </div>
          )}

          {/* Nombre de caja o Total de pedido segun sea el caso */}

          <div className="order-data">
            <p className="data-title">
              {!isSale ? "Total del Pedido" : "Caja Registradora"}
            </p>
            <p>
              {!isSale
                ? `$ ${orderOnView.header.total_ammount}`
                : orderOnView.header.cash_register_name}
            </p>
          </div>
        </div>
        <hr />

        {/* Direccion de entrega  o datos de pago segun sea el caso */}

        <h1>{!isSale ? "Direccion de Entrega" : "Informacion de Pago"}</h1>
        <div className={!isSale ? "adress-details" : "sale-details"}>
          {/* Calle o total de venta  en efectivo */}
          <div className="adress-data">
            <p className="data-title">
              {!isSale ? "Calle" : "Total en Efectivo "}
            </p>
            <p>
              {!isSale
                ? orderOnView.header.street
                : `$ ${orderOnView.header.cash_ammount}`}
            </p>
          </div>

          {/* Numero exterior o total en visa */}

          <div className="adress-data">
            <p className="data-title">
              {!isSale ? "Numero Exterior" : "Total Visa"}
            </p>
            <p>
              {!isSale
                ? orderOnView.header.exterior_number
                : `$ ${orderOnView.header.visa_ammount}`}
            </p>
          </div>

          {/* Numero interior si es que este es una orden y la direccion tiene num interior */}

          {orderOnView.header.interior_number && (
            <div className="adress-data">
              <p className="data-title">Numero Interior</p>
              <p>{orderOnView.header.interior_number}</p>
            </div>
          )}

          {/* Ciudad o Cantidad en American Express */}
          <div className="adress-data">
            <p className="data-title">
              {!isSale ? "Ciudad o Municipio" : "Total American Express"}
            </p>
            <p>
              {!isSale
                ? orderOnView.header.city
                : `$ ${orderOnView.header.amex_ammount}`}
            </p>
          </div>

          {/* Estaod o Cantidad en Mastercard */}

          <div className="adress-data">
            <p className="data-title left">
              {!isSale ? "Estado" : "Total Mastercard"}
            </p>
            <p>
              {!isSale
                ? orderOnView.header.state
                : `$ ${orderOnView.header.mc_ammount}`}
            </p>
          </div>

          {/* Codigo Postal */}

          {!isSale && (
            <div className="adress-data">
              <p className="data-title">Codigo Postal</p>
              <p>{orderOnView.header.postal_code}</p>
            </div>
          )}

          {/* Telefono */}

          {!isSale && (
            <div className="adress-data">
              <p className="data-title">Telefono</p>
              <p>
                {orderOnView.header.phone_num !== "None"
                  ? orderOnView.header.phone_num
                  : "-"}
              </p>
            </div>
          )}

          {/* Ver en mapa solo si la venta viene del ecomerce  */}
          {!isSale && (
            <div className="map">
              <LocationOnIcon
                style={{ fill: "#2d6a4f" }}
                className="loc_icon"
              />
              <p>Ver en mapa</p>
              <IconButton onClick={() => navigateToMap()}>
                <ArrowForwardIcon
                  style={{ fill: "#2d6a4f" }}
                  className="arrow_icon"
                />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </PageContainer>
  );
}
