import React from "react";
import "./Us.sass";

export default function Us() {
  return (
    <div className="landing_us">
      <h1 className="title">Acerca de Nosotros</h1>

      <div className="banner">
        <p>
          Somos especialistas tecnológicos con toda la experiencia en la
          industria farmacéutica.
        </p>
        <img src="/imgs/team.svg" alt="Equipo" />
      </div>

      <div className="cards">
        <div className="card">
          <h3>Misión</h3>
          <p>
            Innovación contínua en soluciones tecnológicas y de negocio para el
            mundo de la salud.
          </p>
        </div>
        <div className="card">
          <h3>Visión</h3>
          <p>
            Ser la mayor red tecnológica en facmacias del país lorando el éxito
            empresarial de nuestros asociados creando enlaces de venta
            eficientes para el acceso a la salud.
          </p>
        </div>
      </div>
    </div>
  );
}
