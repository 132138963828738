import { useAppContext } from "contexts/AppContext";
import React from "react";
import { Navigate } from "react-router-dom";

export default function InitialRoute() {
  // Get user
  const { user } = useAppContext();

  // Redirect to home or login
  return user ? <Navigate to="/my-profile" /> : <Navigate to="/home" />;
}
