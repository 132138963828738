/* eslint-disable no-unused-vars */
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import "./ChangePageBtn.sass";

export default function ChangePageBtn({
  page,
  setPage,
  data,
  isArray = true,
  defaultBehaviour = true,
  itemsPerPage = 10,
}) {
  function changePage(pageNumber) {
    // Obtener el numero de pagianas y solo dejar cambiar de pagina
    // en caso de que no se haya llegado al limite
    const totalItems = isArray ? data.length : Object.keys(data).length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // El behaviour por default no permite seguir cambiado de pagina si ya se llego al limite
    // de paginas
    if (defaultBehaviour) {
      if (pageNumber !== 0 && pageNumber <= totalPages) {
        setPage(pageNumber);
      }
      return;
    }

    if (pageNumber !== 0) {
      setPage(pageNumber);
    }
  }
  return (
    <div className="select-page">
      <b>Pagina: </b>
      <IconButton variant="solid" onClick={() => changePage(page - 1)}>
        <ArrowLeft className="icon" />
      </IconButton>
      <p>{page}</p>
      <IconButton variant="solid" onClick={() => changePage(page + 1)}>
        <ArrowRight className="icon" />
      </IconButton>
    </div>
  );
}
