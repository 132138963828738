import React from "react";
import "./CountBills.sass";

export default function CountBills({ billsCount, setBillsCount }) {
  // Bills
  const [b1000, setB1000] = React.useState("0");
  const [b500, setB500] = React.useState("0");
  const [b200, setB200] = React.useState("0");
  const [b100, setB100] = React.useState("0");
  const [b50, setB50] = React.useState("0");
  const [b20, setB20] = React.useState("0");

  // Coins
  const [c20, setC20] = React.useState("0");
  const [c10, setC10] = React.useState("0");
  const [c5, setC5] = React.useState("0");
  const [c2, setC2] = React.useState("0");
  const [c1, setC1] = React.useState("0");
  const [c50c, setC50c] = React.useState("0");
  const [c20c, setC20c] = React.useState("0");
  const [c10c, setC10c] = React.useState("0");
  const [c5c, setC5c] = React.useState("0");

  // On input value change
  function onValueChange(type, key, newValue) {
    const curCount = billsCount; // Get current counter
    curCount[type][key] = parseInt(newValue, 10);
    setBillsCount(curCount); // Set new value
  }

  return (
    <div className="count_bills">
      <div className="count_section">
        {/* Bills */}
        <div className="headers">
          <h1 className="first">Billetes</h1>
          <h1 className="second">Cantidad</h1>
        </div>

        <div className="input_container">
          <div className="item">
            <p>1000 pesos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={b1000}
              onChange={(e) => {
                setB1000(e.target.value);
                onValueChange("bills", 1000, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>500 pesos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={b500}
              onChange={(e) => {
                setB500(e.target.value);
                onValueChange("bills", 500, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>200 pesos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={b200}
              onChange={(e) => {
                setB200(e.target.value);
                onValueChange("bills", 200, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>100 pesos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={b100}
              onChange={(e) => {
                setB100(e.target.value);
                onValueChange("bills", 100, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>50 pesos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={b50}
              onChange={(e) => {
                setB50(e.target.value);
                onValueChange("bills", 50, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>20 pesos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={b20}
              onChange={(e) => {
                setB20(e.target.value);
                onValueChange("bills", 20, e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="count_section">
        {/* Coins */}
        <div className="headers">
          <h1 className="first">Monedas</h1>
          <h1 className="second">Cantidad</h1>
        </div>

        <div className="input_container">
          <div className="item">
            <p>20 pesos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={c20}
              onChange={(e) => {
                setC20(e.target.value);
                onValueChange("coins", 20, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>10 pesos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={c10}
              onChange={(e) => {
                setC10(e.target.value);
                onValueChange("coins", 10, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>5 pesos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={c5}
              onChange={(e) => {
                setC5(e.target.value);
                onValueChange("coins", 5, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>2 pesos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={c2}
              onChange={(e) => {
                setC2(e.target.value);
                onValueChange("coins", 2, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>1 peso</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={c1}
              onChange={(e) => {
                setC1(e.target.value);
                onValueChange("coins", 1, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>50 centavos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={c50c}
              onChange={(e) => {
                setC50c(e.target.value);
                onValueChange("cents", 50, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>20 centavos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={c20c}
              onChange={(e) => {
                setC20c(e.target.value);
                onValueChange("cents", 20, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>10 centavos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={c10c}
              onChange={(e) => {
                setC10c(e.target.value);
                onValueChange("cents", 10, e.target.value);
              }}
            />
          </div>
          <div className="item">
            <p>5 centavos</p>
            <input
              type="number"
              placeholder="Cantidad"
              value={c5c}
              onChange={(e) => {
                setC5c(e.target.value);
                onValueChange("cents", 5, e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
