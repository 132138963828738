/* eslint-disable no-unused-vars */
import { Button, TextField } from "@mui/material";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import OrderedProducts from "components/Orders/OrderedProducts/OrderedProducts";
import OrderHeader from "components/Orders/OrderHeader/OrderHeader";
import { useAppContext } from "contexts/AppContext";
import { formatDate } from "helpers/date_formaters";
import { checkResponse, validateInputs } from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./ReturnProducts.sass";

export default function ReturnProducts() {
  const {
    physicalCashRegister,
    orderOnView,
    userToken,
    setOrderOnView,
    pharmacy,
    user,
  } = useAppContext();
  const [orderIdInput, setOrderIdInput] = React.useState("");
  const [productsToReturn, setProductsToReturn] = React.useState([]);
  const [orderLoaded, setOrderLoaded] = React.useState(false);
  const navigate = useNavigate();

  async function searchOrder() {
    try {
      // Validate id
      if (!validateInputs([orderIdInput])) {
        toast.error("El numero de ticket es invalido");
        return;
      }

      // Make request
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/orders/${orderIdInput}`;
      const res = await axios.get(url, { headers: { authToken: userToken } });

      // Check respose
      if (!checkResponse(res)) throw res;

      if (res?.data?.body?.header?.pharmacy_id !== pharmacy.pharmacy_id) {
        toast.error("El numero de ticket es invalido");
        return;
      }

      // Set order on view
      setOrderOnView(res?.data?.body);
      setOrderLoaded(true);
    } catch (error) {
      console.log("GET ORDER DATA ERROR:", error);
      toast.error("Hubo un error obteniendo los datos de la venta");
      setOrderLoaded(false);
    }
  }

  async function returnCash() {
    try {
      // Get the ammount to return
      let ammountToReturn = 0;

      productsToReturn.forEach((item) => {
        ammountToReturn += item.item_cost;
      });

      // Make request
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/cash-register/withdrawal`;
      const headers = { authToken: userToken };
      const body = {
        pharmacy_id: pharmacy.pharmacy_id,
        cash_register_id: physicalCashRegister.cash_register_id,
        cash_ammount: parseFloat(ammountToReturn),
        type: "product_return",
        payment_date: formatDate(new Date()),
      };
      const res = await axios.post(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;
    } catch (error) {
      console.log("WITHDRAWAL ERROR:", error);
      toast.error(
        toast.error("Hubo un error registrando la devolución de dinero")
      );
    }
  }

  async function returnProducts() {
    try {
      // Validate
      if (productsToReturn.length <= 0) {
        toast.error("No has seleccionado productos para devolver");
        return;
      }

      // Make request
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/orders/${orderOnView?.header?.order_id}/return-products`;
      const body = {
        pharmacy_id: pharmacy?.pharmacy_id,
        registered_by: user?.employee_id,
        entry_date: formatDate(new Date()),
        products: productsToReturn,
      };
      const headers = {
        authToken: userToken,
      };
      const res = await axios.post(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;

      // Return cash
      await returnCash();

      // Success and go back
      toast.success("Productos devueltos correctamente");
      navigate("/cash-register");
    } catch (error) {
      console.log("RETURN PRODUCTS ERROR:", error);
      toast.error("Hubo un error devolviendo los productos");
    }
  }

  function addProductToReturn(orderedItemId, itemId, badgeId, itemCost) {
    const product = {
      ordered_item_id: orderedItemId,
      item_id: itemId,
      badge_id: badgeId,
      quantity: 1,
      item_cost: itemCost,
    };
    setProductsToReturn([...productsToReturn, product]);
  }

  function removeProductToReturn(orderedItemId) {
    const filteredProducts = productsToReturn.filter(
      (p) => p.ordered_item_id !== orderedItemId
    );
    setProductsToReturn(filteredProducts);
  }

  return (
    <>
      <PageContainer smallMargins>
        <BackPageBtn path="/cash-register" />
        <div className="return_products">
          {/* Top */}
          <h1>Consultar Venta</h1>

          {/* Order id input */}
          <div className="order_id_search">
            <TextField
              label="Numero de Ticket"
              variant="outlined"
              size="small"
              value={orderIdInput}
              onChange={(e) => setOrderIdInput(e.target.value)}
            />
            <Button variant="contained" sx={{ ml: 2 }} onClick={searchOrder}>
              Buscar venta
            </Button>
          </div>
        </div>
      </PageContainer>

      {/* Datos de orden */}
      {orderLoaded && (
        <div className="order-details-pg">
          <OrderHeader hideBackBtn />
          <OrderedProducts
            returnProductsMode
            returnProducts={returnProducts}
            addProductToReturn={addProductToReturn}
            removeProductToReturn={removeProductToReturn}
          />
        </div>
      )}
    </>
  );
}
