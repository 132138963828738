import GenericModal from "components/General/GenericModal/GenericModal";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import React from "react";

export default function SuccessSaleModal({ tempSaleData, closeModal }) {
  return (
    <GenericModal
      title="Venta Exitosa"
      onContinue={closeModal}
      hideCancelButton
    >
      <div className="success_sale_modal">
        <div className="main_msg">
          <TaskAltIcon sx={{ mb: 1, fontSize: "70px", color: "#34974d" }} />
          <p>Venta Exitosa</p>
        </div>
        <div className="sale_details">
          <p className="label">Total de Venta:</p>
          <p className="amount">${tempSaleData.total}</p>
          <p className="label">Total Pagado:</p>
          <p className="amount">${tempSaleData.paid}</p>
          <p className="label">Cambio:</p>
          <p className="amount">${tempSaleData.change}</p>
        </div>
        <div className="hr" />
        <div className="sale_details">
          <p className="label">Efectivo:</p>
          <p className="amount">${tempSaleData.cashMoney}</p>
          <p className="label">Visa:</p>
          <p className="amount">${tempSaleData.cardVisaMoney}</p>
          <p className="label">Master Card:</p>
          <p className="amount">${tempSaleData.cardMCMoney}</p>
          <p className="label">American Express:</p>
          <p className="amount">${tempSaleData.cardAMEXMoney}</p>
          <p className="label">Vales:</p>
          <p className="amount">${tempSaleData.voucherMoney}</p>
        </div>
      </div>
    </GenericModal>
  );
}
