import { Routes, Route } from "react-router-dom";
import PageNotFound from "components/General/PageNotFound/PageNotFound";
import publicRoutes from "./routes/publicRoutes";
import authRoutes from "./routes/authRoutes";
import AuthValidator from "./AuthValidator";

export default function Router() {
  return (
    <Routes>
      {/* Public Routes */}
      {publicRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}

      {/* Private Routes */}
      {authRoutes.map((route) => (
        <Route
          key={route.path}
          element={<AuthValidator allowedRoles={route.allowedRoles} />}
        >
          <Route path={route.path} element={route.element} />
        </Route>
      ))}

      {/* 404 Not Found */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
