import React from "react";
import "./System.sass";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import MedicationIcon from "@mui/icons-material/Medication";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import GroupsIcon from "@mui/icons-material/Groups";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import BarChartIcon from "@mui/icons-material/BarChart";
import LanguageIcon from "@mui/icons-material/Language";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { COLORS_MAP } from "helpers/colors";
import { Link } from "react-router-dom";

export default function System() {
  return (
    <div className="landing_system">
      <h1 className="title">Sistema MF360</h1>

      <div className="banner">
        <div className="text">
          <p>Un sistema construido para hacer crecer tu farmacia</p>
          <Link to="/solicitar-demo">
            <button type="button" className="demo_btn">
              Agendar Demo
            </button>
          </Link>
        </div>
        <img src="/imgs/pos_ss.png" alt="Punto de Venta" />
      </div>

      <div className="modules">
        <div className="module" style={{ background: COLORS_MAP.green }}>
          <PointOfSaleIcon className="icon" />
          <h3>Módulo de Venta</h3>
          <p>
            Realiza todas tus ventas con control de lotes, fecha de caducidad y
            productos controlados con nuestro punto de venta.
          </p>
        </div>
        <div className="module" style={{ background: COLORS_MAP.red }}>
          <MedicationIcon className="icon" />
          <h3>Catálogo de Productos</h3>
          <p>
            Contamos con un catálogo precargado de más de 17 mil productos para
            que no pierdas tiempo cargando los datos de tus productos.
          </p>
        </div>
        <div className="module" style={{ background: COLORS_MAP.blue }}>
          <FactCheckIcon className="icon" />
          <h3>Módulo de Inventarios</h3>
          <p>
            Podrás tener control total sobre tu inventario, conociendo cuantas
            unidades tienes disponibles de cada producto y cuantas se han
            vendido.
          </p>
        </div>
        <div className="module" style={{ background: COLORS_MAP.purple }}>
          <GroupsIcon className="icon" />
          <h3>Módulo de Personal</h3>
          <p>
            Podrás saber el desempeño y acciones de tu personal, así como
            desactivar sus cuentas.
          </p>
        </div>
        <div className="module" style={{ background: COLORS_MAP.yellow }}>
          <CreditScoreIcon className="icon" />
          <h3>Cobro de Servicios</h3>
          <p>
            El cobro del sistema se ajusta a la cantidad de ventas que realiza
            tu farmacia, así solo pagas lo que usas.
          </p>
        </div>
        <div className="module" style={{ background: COLORS_MAP.orange }}>
          <BarChartIcon className="icon" />
          <h3>Reportes y Gráficos</h3>
          <p>
            Contamos con una amplia variedad de gráficos y reportes con los que
            podrás conocer las operaciones de tu farmacia y tomar decisiones
            informadas.
          </p>
        </div>
        <div className="module" style={{ background: COLORS_MAP.orange }}>
          <LanguageIcon className="icon" />
          <h3>E-commerce</h3>
          <p>
            Si estás interesado en entrar al mundo digital con tu farmacia
            podrás incluir en el sistema el uso de nuesto ecommerce nacional
            especializado en farmacias.
          </p>
        </div>
        <div className="module" style={{ background: COLORS_MAP.green }}>
          <HandshakeIcon className="icon" />
          <h3>Acompañamiento Continuo</h3>
          <p>
            Nuestro equipo está listo para acompañarte y asesorarte con las
            dudas o situaciones que puedas llegar a presentar en la migración al
            sistema, siempre tendrás apoyo.
          </p>
        </div>
        <div className="module" style={{ background: COLORS_MAP.purple }}>
          <LocalPoliceIcon className="icon" />
          <h3>Asesoría en Marco Regulatorio</h3>
          <p>
            Nuestra amplia experiencia en el área farmacéutica se ve reflejada
            en el sistema, se han incorporado módulos del sistema específicos
            para cumplir con los marcos regulatorios de una forma sencilla.
          </p>
        </div>
      </div>
    </div>
  );
}
