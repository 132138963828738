/* eslint-disable react/jsx-props-no-spreading */
import SalesSection from "components/Graphs/SalesSection/SalesSection";
import TopStatistics from "components/Graphs/TopStatistics/TopStatistics";
import React from "react";
import "./Graphs.sass";
import InventorySection from "components/Graphs/InventorySection/InventorySection";
import RegulatorySection from "components/Graphs/RegulatorySection/BadgeSection/RegulatorySection";
import AccountingSection from "components/Graphs/AccountingSection/AccountingSection";

// Data
export default function Graphs() {
  // Variables
  const [section, setSection] = React.useState("sales");
  const [dateRange, setDateRange] = React.useState("weekly");

  // Render section
  function renderSection(s) {
    switch (s) {
      case "sales":
        return <SalesSection dateRange={dateRange} />;
      case "inventory":
        return <InventorySection dateRange={dateRange} />;
      case "regulatory":
        return <RegulatorySection />;
      case "accounting":
        return <AccountingSection />;
      default:
        return <SalesSection dateRange={dateRange} />;
    }
  }

  return (
    <div className="graphs_page">
      {/* Top Statistics */}
      <TopStatistics />

      {/* Graph Section and date selects  */}
      <div className="section_select">
        <div className="options">
          <select value={section} onChange={(e) => setSection(e.target.value)}>
            <option value="sales">Ventas</option>
            <option value="inventory">Inventario</option>
            <option value="regulatory">Regulatorio</option>
            <option value="accounting">Contabilidad</option>
          </select>
        </div>
        {section !== "regulatory" && (
          <div className="date-range">
            <select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
            >
              <option value="weekly">Ultimos 7 dias</option>
              <option value="monthly">Ultimo mes</option>
              <option value="yearly">Ultimo año</option>
            </select>
          </div>
        )}
      </div>

      {/* Sections */}
      {renderSection(section)}
    </div>
  );
}
