/* eslint-disable prefer-const */
// Funcion que transforma una fecha de javascript a una
// Que pueda usar mysql para filtros de fecha

export const formatDate = (inputDate, getTime = true) => {
  const month = inputDate.getMonth() + 1;
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();

  const date = `${year}-${month}-${day}`; // => 2022-12-05
  if (getTime) {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const todaysTime = inputDate.toLocaleTimeString("en-US", options);
    return `${date} ${todaysTime}`;
  }
  return `${date}`;
};

export const getDateString = (date, getTime = true) => {
  const formatedDate = new Date(date);

  if (getTime) {
    return formatDate.toLocaleTimeString();
  }

  return formatedDate.toLocaleDateString();
};

export const getTimeFromDate = (date) => {
  return date.replaceAll("-", "/").substring(10, 16);
};
