export function checkResponse(response) {
  if (response.data.success || response.data.succes || response.data.sucess)
    return true;
  return false;
}

export function getResponseError(res, defaultErr) {
  return (
    res.data?.errorMessage ||
    res.data?.msg ||
    res.response?.data?.message ||
    res.message ||
    defaultErr
  );
}

export function validateInputs(values) {
  let status = true;
  values.forEach((value) => {
    if (value === "" || value === null) status = false;
  });
  return status;
}
