import { Button } from "@mui/material";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import React from "react";
import "./Welcome.sass";

export default function Welcome({ nextNewUserStep }) {
  const { pharmacy } = useAppContext();

  return (
    <PageContainer fitWidth>
      <div className="welcome_page">
        <h1>¡Bienvenido a {pharmacy.pharmacy_name}!</h1>
        <h2>Nos alegra tenerte con nosotros</h2>
        <p>El primer paso que debes realizar es llenar tu información</p>

        <Button
          className="continue_button"
          variant="contained"
          onClick={nextNewUserStep}
        >
          Continuar
        </Button>
      </div>
    </PageContainer>
  );
}
