/* eslint-disable no-unused-vars */
import axios from "axios";
import { useAppContext } from "contexts/AppContext";
import { checkResponse, getResponseError } from "helpers/requests";
import React, { useState } from "react";
import toast from "react-hot-toast";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import "./TopSoldProducts.sass";
import { formatDate } from "helpers/date_formaters";

function TopSoldProducts({ dateRange }) {
  const { userToken, pharmacy } = useAppContext();
  const [topProducts, setTopProducts] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  async function getTopSoldProducts() {
    try {
      const config = {
        headers: {
          authToken: userToken,
        },
      };
      const today = formatDate(new Date());
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/reports/inventory/${pharmacy.pharmacy_id}/topsoldproducts?date_range=${dateRange}&today=${today}`;
      const res = await axios.get(url, config);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;
      setTopProducts(res.data.body.data);
    } catch (res) {
      toast.error(getResponseError(res));
    }
  }

  // Actualizar graficas cuando cambie la fecha
  React.useEffect(() => {
    setIsLoading(true);
    getTopSoldProducts();
    setIsLoading(false);
    console.log(topProducts);
  }, [dateRange]);

  if (isLoading) {
    return (
      <div className="sales-table">
        <LoadingTable />
      </div>
    );
  }

  return (
    <div className="top-products">
      {/* seleccion de fechas para filtros  */}

      <div className="header">
        <h2>Mas Vendidos</h2>
      </div>
      <hr />

      {/* Tabla de ventas  */}
      {topProducts.length > 0 ? (
        <table className="product_table">
          <thead className="product_header">
            <tr>
              <th scope="col">Imagen</th>
              <th scope="col name">Nombre</th>
              <th scope="col">Código de Barras</th>
              <th scope="col">Categoria</th>
              <th scope="col">Numero de Ventas</th>
            </tr>
          </thead>
          <tbody id="products">
            {topProducts &&
              topProducts.map((product) => (
                <tr key={product.item_id}>
                  <td>
                    <img
                      src={product.photo_url ?? "/imgs/no-image.png"}
                      alt="product"
                    />
                  </td>
                  <td className="name">{product.product_name}</td>
                  <td>{product.sku}</td>
                  <td>{product.category_name}</td>
                  <td>{product.total_quantity_sold}</td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="no-sales">
          <img
            height={300}
            src="/imgs/no-sales.png"
            alt=""
            className="no-sales"
          />
          <h3>No se encontraron ventas en estas fechas</h3>
          <p>Intenta seleccionar un rango de fechas diferente</p>
        </div>
      )}
    </div>
  );
}

export default TopSoldProducts;
