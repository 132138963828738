import { Button } from "@mui/material";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import { getDateString } from "helpers/date_formaters";
import React from "react";
import shortid from "shortid";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadExcel } from "helpers/excel";

function BadgeTable({ batches, isLoading, allBatches }) {
  function downloadExcelHandler() {
    // Set Labels
    const data = [];
    const headers = [
      "Producto",
      "Código de Barras",
      "Lote",
      "Fecha de Caducidad",
      "En Inventario",
      "Precio Unitario",
      "Costo Unitario",
    ];
    data.push(headers);

    // For each row
    allBatches.forEach((batch) => {
      const row = [];
      row.push(batch?.product_name);
      row.push(batch?.sku);
      row.push(batch?.badge);
      row.push(getDateString(batch?.expiracy_date, false));
      row.push(batch?.quantity);
      row.push(batch?.item_price);
      row.push(batch?.item_cost);
      data.push(row);
    });

    downloadExcel(data, "Lotes");
  }
  return (
    <div>
      {isLoading ? (
        <LoadingTable nCols={5} />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={downloadExcelHandler}
            >
              Descargar Excel
            </Button>
          </div>
          <table className="product_table">
            <thead className="product_header">
              <tr>
                <th scope="col">Producto</th>
                <th scope="col">Codigo de Barras</th>
                <th scope="col name">Lote</th>
                <th scope="col">Fecha de Caducidad</th>
                <th scope="col">En Inventario</th>
                <th scope="col">Precio Unitario</th>
                <th scope="col">Costo Unitario</th>
              </tr>
            </thead>
            <tbody id="products">
              {batches.map((batch) => (
                <tr key={shortid.generate()}>
                  <td className="img-text">
                    <img
                      src={batch?.photo_url ?? "/imgs/no-image.png"}
                      alt="product"
                    />
                    <p>{batch?.product_name}</p>
                  </td>
                  <td>{batch?.sku}</td>
                  <td>{batch?.badge}</td>
                  <td>{getDateString(batch?.expiracy_date, false)}</td>
                  <td>{batch?.quantity}</td>
                  <td>${batch?.item_price}</td>
                  <td>${batch?.item_cost}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default BadgeTable;
