import { Button } from "@mui/material";
import "./ItemData.sass";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { translateItemStatus } from "helpers/translators";

export default function ItemData({ item }) {
  return (
    <div className="item_data">
      <div className="top">
        <h1>En Inventario</h1>
        <Link to="/product/edit" className="edit_btn">
          <Button variant="outlined" size="small" startIcon={<EditIcon />}>
            Editar Inventario
          </Button>
        </Link>
      </div>

      <div className="fields">
        <div className="field">
          <p className="label">Cantidad</p>
          <p className="value">{item.quantity || "Sin información"}</p>
        </div>

        <div className="field">
          <p className="label">Precio de Venta</p>
          <p className="value">{item.price || "Sin información"}</p>
        </div>

        <div className="field">
          <p className="label">Costo por Unidad</p>
          <p className="value">{item.item_cost || "Sin información"}</p>
        </div>

        <div className="field">
          <p className="label">Estado</p>
          <p className="value">
            {translateItemStatus(item.status) || "Sin información"}
          </p>
        </div>

        <div className="field">
          <p className="label">Unidades Vendidas</p>
          <p className="value">{item.items_sold || "Sin información"}</p>
        </div>
      </div>
    </div>
  );
}
