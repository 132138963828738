import axios from "axios";
import { checkResponse, getResponseError } from "./requests";

// Funcion que obtiene un producto ya sea del catalogo global o del inventario
export default async function getProductData(sku, inventory) {
  try {
    // Buscar primero en el inventario
    const inventoryItem = inventory[sku];
    if (inventoryItem)
      return {
        success: true,
        product: inventoryItem,
      };

    // Si no está en el inventario buscar en el catálogo
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/products/get-by-sku`;
    const params = { sku };

    const res = await axios.get(url, { params });

    // Check respose
    console.log(res);
    if (!checkResponse(res)) throw res;

    // Set operations
    return {
      success: true,
      product: res?.data?.body?.product,
    };
  } catch (error) {
    console.log("GET PRODUCT DATA ERROR", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un error obteniendo los datos del producto"
      ),
    };
  }
}

export function getProductBatches(item_id, batches) {
  const options = [];

  batches.forEach((batch) => {
    if (batch.item_id === item_id) {
      const option = {
        label: `${batch.badge} | ${batch.expiracy_date.substring(0, 10)}`,
        batch_id: batch.badge_id,
      };
      options.push(option);
    }
  });

  return options;
}
