/* eslint-disable no-param-reassign */
import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";
import toast from "react-hot-toast";

// Funcion para realizar una venta en el pos
export default async function makePosSale(order, userToken, change) {
  try {
    // Verificar que haya la cantidad suficiente de inventario para cada item
    const apiUrl = process.env.REACT_APP_API_URL;
    let url = `${apiUrl}/orders/create/verify-quantities`;
    const resVerification = await axios.post(url, {
      ordered_items: order.ordered_items,
    });
    if (!checkResponse(resVerification)) throw resVerification;

    // Si hay cambio restar a la cantidad en efectivo el cambio
    if (change > 0) {
      order.cash_ammount -= change;
    }

    // Hacer request a API
    const config = {
      headers: {
        authToken: userToken,
      },
    };
    url = `${apiUrl}/orders/create/sale`;
    const res = await axios.post(url, order, config);

    // Levantar errrores en caso de que los haya
    if (!checkResponse(res)) throw res;
    return {
      msg: res.data.body.msg,
      iva: res.data.body.iva,
      without_iva: res.data.body.wihtout_iva,
      success: true,
      ticket_id: res.data.body.order_id,
    };
  } catch (res) {
    console.log("CREATE ORDER ERROR:", res);
    return {
      msg: getResponseError(res, "Hubo un error realizando la venta"),
      iva: null,
      without_iva: null,
      success: false,
      ticket_id: null,
    };
  }
}

export async function createPrescription(
  doctor,
  professional_license,
  prescription_number,
  userToken,
  pharmacyId
) {
  try {
    // Make request
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/prescriptions/${pharmacyId}/create`;
    const headers = { authToken: userToken };
    const body = {
      doctor,
      professional_license,
      prescription_number,
    };
    const res = await axios.post(url, body, { headers });

    if (!checkResponse(res)) throw res;

    return { success: true, prescription_id: res?.data?.body?.prescription_id };
  } catch (error) {
    console.log("CREATE PRESCRIPTION ERROR:", error);
    toast.error("Hubo un problema con los datos de la receta médica");
    return { success: false };
  }
}
