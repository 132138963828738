import axios from "axios";
import { checkResponse } from "helpers/requests";
import toast from "react-hot-toast";
import { getProfitMovementType } from "helpers/translators";
import { downloadExcel } from "helpers/excel";

export async function getMovements(pharmacyId, page, monthOnView, userToken) {
  try {
    // Hacer petición
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/accounting/${pharmacyId}/profit/movements`;
    const params = { page, date: monthOnView };
    const headers = { authToken: userToken };
    const res = await axios.get(url, { params, headers });

    // Checar respuesta
    if (!checkResponse(res)) throw res;

    return { success: true, movements: res?.data?.body?.movements };
  } catch (error) {
    console.log("GET PROFIT MOVEMENTS ERROR:", error);
    toast.error("Hubo un error obteniendo los movimientos");
    return { success: false };
  }
}

export async function calculateProfit(pharmacyId, monthOnView, userToken) {
  try {
    // Hacer petición
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/accounting/${pharmacyId}/profit/calculate-profit`;
    const params = { date: monthOnView };
    const headers = { authToken: userToken };
    const res = await axios.get(url, { params, headers });

    // Checar respuesta
    if (!checkResponse(res)) throw res;

    // Poner valores
    return { success: true, data: res?.data?.body };
  } catch (error) {
    console.log("CALCULATE PROFIT ERROR:", error);
    toast.error("Hubo un error obteniendo calculando los totales");
    return { success: false };
  }
}

export async function downloadMovements(movements) {
  // Set Labels
  const data = [];
  const headers = ["Movimiento", "Fecha", "Cargo", "Crédito", "IVA", "Total"];
  data.push(headers);

  // For each row
  movements.forEach((m) => {
    const row = [];
    row.push(getProfitMovementType(m.movement));
    row.push(new Date(m.date).toDateString());
    row.push(
      m.movement === "invoice" || m.movement === "product_return"
        ? m.ammount
        : null
    );
    row.push(m.movement === "sale" ? m.ammount : null);
    row.push(m?.iva);
    row.push(m?.ammount);
    data.push(row);
  });

  downloadExcel(data, "Ganancias");
}
