import React from "react";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import "../ProductView/ProductView.sass";
import ProductData from "components/ProductCatalogue/ProductData/ProductData";
import ItemData from "components/ProductCatalogue/ItemData/ItemData";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import { scrollPageContainer } from "helpers/page";

export default function ItemView() {
  // Variables
  const { productOnView: product } = useAppContext();

  // On render
  React.useEffect(() => {
    scrollPageContainer();
  }, []);

  return (
    <PageContainer>
      <div id="top" className="product_view">
        {/* Go Back Btn */}
        <BackPageBtn path="/inventory" />

        {/* Item Data */}
        {product.item_id ? (
          <ItemData item={product} />
        ) : (
          <div className="add_btn_container">
            <Button variant="contained" startIcon={<AddIcon />}>
              Agregar a Inventario
            </Button>
          </div>
        )}

        {/* Product Data */}
        <ProductData product={product} />
      </div>
    </PageContainer>
  );
}
