import React from "react";
import "./Home.sass";

export default function Home() {
  return (
    <div className="home">
      {/* Screenshots */}
      <div className="screenshots">
        <div className="item">
          <div className="texts">
            <h1>Ecommerce</h1>
            <p>
              Al contaratar Mi Farmacia 360 tu farmacia podrá ser parte de
              nuestro ecommerce nacional, podrás llegar a más clientes y
              generarás una mejor experiencia
            </p>
          </div>

          <div className="img">
            <img src="/imgs/ecommerce-ss.png" alt="" />
          </div>
        </div>

        <div className="item reverse">
          <div className="texts">
            <h1>Mi Farmacia</h1>
            <p>
              El sistema Mi Farmacia 360 es fácil de usar y está pensado
              específicamente para farmacias, te ayudará a mejorar tus
              operaciones y aumentar tus ventas
            </p>
          </div>

          <div className="img">
            <iframe
              src="https://www.youtube.com/embed/QCYxPWwJAUY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>

        <div className="item">
          <div className="texts">
            <h1>Administrar Inventario</h1>
            <p>
              Con Mi Farmacia 360 podrás gesionar tu inventario de una forma
              fácil y rápida, solo debes buscar un producto en el amplio
              catálogo que tenemos disponible y los datos se cargarán
              automáticamente
            </p>
          </div>

          <div className="img">
            <iframe
              src="https://www.youtube.com/embed/KXMMlTvId8w"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>

        <div className="item reverse">
          <a
            href="https://asesoriaenfarmacias.com/"
            target="_blank"
            rel="noreferrer"
            className="texts"
          >
            <h1>Asesoría en Farmacias</h1>
            <p style={{ color: "black" }}>
              Asesorate con expertos en farmacias para lograr un mayor control y
              crecimiento en tu negocio. Pascual Asesoría en Farmacias ofrece
              trámites y permisos para tu farmacia, cadena de farmacias,
              registro de productos o servicios que deseas comercializar a nivel
              regional o nacional.
            </p>
          </a>

          <a
            href="https://asesoriaenfarmacias.com/"
            target="_blank"
            rel="noreferrer"
            className="img"
          >
            <img
              src="/imgs/pascual.jpg"
              alt=""
              style={{ objectFit: "contain" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
