import React from "react";
import "./AddProductToCatalogue.sass";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import PageContainer from "components/General/PageContainer/PageContainer";
import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";
import { useAppContext } from "contexts/AppContext";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import toast from "react-hot-toast";
import { formatDate } from "helpers/date_formaters";
import fileService from "services/fileService";

export default function AddProductToCatalogue() {
  // Variables
  const { categories, user, pharmacy } = useAppContext();

  // Input Values
  const [file, setFile] = React.useState(null);
  const [sku, setSku] = React.useState("");
  const [productName, setProductName] = React.useState("");
  const [laboratory, setLaboratory] = React.useState("");
  const [grammage, setGrammage] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [hasIVA, setHasIVA] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [salts, setSalts] = React.useState("");
  const [reqPres, setReqPres] = React.useState("");
  const [satNum, setSatNum] = React.useState("");
  const [warnings, setWarnings] = React.useState("");
  const [dose, setDose] = React.useState("");
  const [contradictions, setContradictions] = React.useState("");

  // Add product
  async function addProduct(e) {
    e.preventDefault();
    try {
      let imageUrl = null;

      if (file) {
        // Save image
        const url = await fileService.uploadFile(
          file,
          pharmacy?.pharmacy_id,
          productName.replace(/\s+/g, "_").toLowerCase(),
          "product-images"
        );
        if (!url) toast.error("Hubo un error subiendo la imágen");
        else imageUrl = url;
      }

      // Save product data
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/products/create-product`;

      // Set published and pharmacy id
      let published = null;
      let pharmacy_id = null;
      if (user.role === "nabu" || user.role === "anafarmex") {
        published = 1;
        pharmacy_id = null;
      } else {
        published = 0;
        pharmacy_id = pharmacy?.pharmacy_id;
      }

      const body = {
        sku,
        product_name: productName,
        product_description: description,
        photo_url: imageUrl,
        laboratory,
        grammage,
        salt: salts.trim() ? salts : null,
        has_iva_charge: parseInt(hasIVA, 10),
        created_by: 8,
        category_id: category,
        requires_prescription: parseInt(reqPres, 10),
        warnings: warnings.trim() ? warnings : null,
        dose: dose.trim() ? dose : null,
        contradictions: contradictions.trim() ? contradictions : null,
        created_at: formatDate(new Date()),
        sat_number: satNum,
        pharmacy_id,
        published,
      };

      // Check for errors
      const resData = await axios.post(url, body);
      if (!checkResponse(resData)) throw resData;

      // Clean inputs
      setFile(null);
      const cleanInputs = [
        setSku,
        setProductName,
        setLaboratory,
        setGrammage,
        setCategory,
        setHasIVA,
        setDescription,
        setSalts,
        setReqPres,
        setWarnings,
        setWarnings,
        setDose,
        setContradictions,
        setSatNum,
      ];
      cleanInputs.map((setFunc) => setFunc(""));
      toast.success("Se creo el producto correctamente");
    } catch (res) {
      console.log("ADD CATALOGUE PRODUCT ERROR:", res);
      toast.error(getResponseError(res), "Hubo un error añadiendo el producto");
    }
  }

  return (
    <PageContainer>
      <div className="add_product_to_catalogue">
        {/* Go Back Btn */}
        <BackPageBtn noMargin path="/product-catalogue" />

        {/* Título */}
        <h1>Crear Producto</h1>

        {/* Formularios */}
        <div className="forms">
          {/* Formulario de Imágen */}
          <form className="img_form">
            <Button
              variant="outlined"
              onClick={() => {
                const imgInput = document.querySelector(".img_input");
                imgInput.click();
              }}
            >
              Seleccionar Imágen
            </Button>

            <input
              type="file"
              className="img_input"
              accept="image/*"
              onChange={(e) => setFile(e.target.files[0])}
            />

            <div className="img_container">
              {file ? (
                <img src={URL.createObjectURL(file)} alt="Producto" />
              ) : (
                <p>No has seleccionado una imágen</p>
              )}
            </div>
          </form>

          {/* Formulario de Datos */}
          <form className="data_form" onSubmit={(e) => addProduct(e)}>
            <TextField
              label="Código de Barras"
              variant="outlined"
              className="text_input"
              value={sku}
              onChange={(e) => setSku(e.target.value)}
              required
            />

            <TextField
              label="Nombre de Producto"
              variant="outlined"
              className="text_input"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />

            <TextField
              label="Laboratorio"
              variant="outlined"
              className="text_input"
              value={laboratory}
              onChange={(e) => setLaboratory(e.target.value)}
              required
            />

            <TextField
              label="Gramaje"
              variant="outlined"
              className="text_input"
              value={grammage}
              onChange={(e) => setGrammage(e.target.value)}
              required
            />

            <FormControl className="select_input">
              <InputLabel id="category-label">Categoría</InputLabel>
              <Select
                labelId="category-label"
                label="Categoría"
                value={category}
                required
                onChange={(e) => setCategory(e.target.value)}
              >
                {categories.map((c) => (
                  <MenuItem value={c.category_id} key={c.category_id}>
                    {c.category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className="select_input">
              <InputLabel id="iva-label">Tiene Iva</InputLabel>
              <Select
                labelId="iva-label"
                label="Tiene IVA"
                value={hasIVA}
                onChange={(e) => setHasIVA(e.target.value)}
                required
              >
                <MenuItem value={1}>Si</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>

            <TextField
              className="text_area"
              label="Numero Sat"
              variant="outlined"
              value={satNum}
              onChange={(e) => setSatNum(e.target.value)}
            />

            <TextField
              className="text_area"
              label="Descripción del Producto"
              multiline
              required
              rows={3}
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <TextField
              label="Sales"
              variant="outlined"
              type="text"
              className="text_input"
              required
              value={salts}
              onChange={(e) => setSalts(e.target.value)}
            />

            <FormControl className="select_input">
              <InputLabel id="req-pres">Requiere Prescripción</InputLabel>
              <Select
                labelId="req-pres"
                label="Requiere Prescripción"
                value={reqPres}
                onChange={(e) => setReqPres(e.target.value)}
                required
              >
                <MenuItem value={1}>Si</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>

            <TextField
              className="text_area"
              label="Advertencias"
              multiline
              rows={3}
              variant="outlined"
              value={warnings}
              onChange={(e) => setWarnings(e.target.value)}
            />

            <TextField
              className="text_area"
              label="Dosis"
              multiline
              rows={3}
              variant="outlined"
              value={dose}
              onChange={(e) => setDose(e.target.value)}
            />

            <TextField
              className="text_area"
              label="Contraindicaciones"
              multiline
              rows={3}
              variant="outlined"
              value={contradictions}
              onChange={(e) => setContradictions(e.target.value)}
            />

            <Button
              variant="contained"
              size="large"
              className="add_btn"
              type="submit"
            >
              Añadir Producto
            </Button>
          </form>
        </div>
      </div>
    </PageContainer>
  );
}
