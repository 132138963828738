import { Button, TextField } from "@mui/material";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import { formatDate } from "helpers/date_formaters";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./CashWidthrawal.sass";

export default function CashWithdrawal() {
  // Variables
  const { userToken, pharmacy, physicalCashRegister } = useAppContext();
  const [withdrawalAmount, setWithdrawalAmount] = React.useState("");
  const navigate = useNavigate();

  // Make withdrawal
  async function makeWithdrawal() {
    try {
      // Validate fields
      if (!validateInputs([withdrawalAmount])) {
        toast.error("Debes ingresar la cantidad a retirar");
        return;
      }

      // Make request
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/cash-register/withdrawal`;
      const headers = { authToken: userToken };
      const body = {
        pharmacy_id: pharmacy.pharmacy_id,
        cash_register_id: physicalCashRegister.cash_register_id,
        cash_ammount: parseFloat(withdrawalAmount),
        type: "withdrawal",
        payment_date: formatDate(new Date()),
      };
      const res = await axios.post(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;

      // Success and go back
      toast.success("Retiro realizado exitosamente");
      navigate("/cash-register");
    } catch (error) {
      console.log("WITHDRAWAL ERROR:", error);
      toast.error(
        getResponseError(error, "Hubo un error realizando el retiro")
      );
    }
  }

  return (
    <PageContainer fitWidth>
      <div className="cash_widthdrawal">
        {/* Back Btn */}
        <BackPageBtn noMargin path="/cash-register" />

        {/* Title */}
        <h1>Retirar Dinero de la Caja</h1>

        {/* Form */}
        <form>
          <TextField
            label="Cantidad en Efectivo a Retirar"
            className="input"
            type="number"
            value={withdrawalAmount}
            onChange={(e) => setWithdrawalAmount(e.target.value)}
          />
          <Button variant="contained" onClick={makeWithdrawal} className="btn">
            RETIRAR DINERO
          </Button>
        </form>
      </div>
    </PageContainer>
  );
}
