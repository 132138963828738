import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";

export function getSessionSales(
  userToken,
  cash_register_id,
  pharmacy_id,
  closure_datetime = null
) {
  return new Promise((resolve, reject) => {
    // Make request
    const headers = { authToken: userToken };
    const params = {
      cash_register_id,
      pharmacy_id,
      closure_datetime, // Si la función se llama en closure request deberá de haber valor
    };
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/cash-register/session-payments`;
    axios
      .get(url, { headers, params })
      .then((res) => {
        // Check response
        if (!checkResponse(res)) throw res;

        // Resolve operation
        const sales = res?.data?.body?.sales;
        const openOperation = res?.data?.body?.open_operation;
        resolve({ sales, openOperation });
      })

      // If there is an error
      .catch((res) => {
        console.log("GET SESSION SALES ERROR", res);
        const error = {
          success: false,
          message: getResponseError(
            res,
            "Hubo un error obteniendo las ventas de esta sesión"
          ),
        };
        reject(error);
      });
  });
}

export function getSessionResults(sessionSales) {
  // Declare values to be returned
  let cashPositiveTotal = 0;
  let cashNegativeTotal = 0;
  let cashTotal = 0;
  let cardVisaTotal = 0;
  let cardMCTotal = 0;
  let cardAMEXTotal = 0;
  let voucherTotal = 0;
  let withdrawalTotal = 0;
  let productReturnsTotal = 0;

  sessionSales.forEach((sale) => {
    // Icrease values
    if (sale.type === "withdrawal") {
      cashNegativeTotal += sale.cash_ammount;
      cashTotal -= sale.cash_ammount;
      withdrawalTotal += sale.cash_ammount;
    } else if (sale.type === "product_return") {
      cashNegativeTotal += sale.cash_ammount;
      cashTotal -= sale.cash_ammount;
      productReturnsTotal += sale.cash_ammount;
    } else {
      cashPositiveTotal += sale.cash_ammount;
      cashTotal += sale.cash_ammount;
    }
    cardVisaTotal += sale.visa_ammount;
    cardMCTotal += sale.mc_ammount;
    cardAMEXTotal += sale.amex_ammount;
    voucherTotal += sale.voucher_ammount;
  });

  const sessionTotal =
    cashTotal + cardVisaTotal + cardMCTotal + cardAMEXTotal + voucherTotal;

  // Return totals
  return {
    sessionTotal,
    cashTotal,
    cashPositiveTotal,
    cashNegativeTotal,
    cardVisaTotal,
    cardMCTotal,
    cardAMEXTotal,
    voucherTotal,
    withdrawalTotal,
    productReturnsTotal,
  };
}

export function closeCashRegister(userToken, body) {
  return new Promise((resolve, reject) => {
    // Make request
    const headers = { authToken: userToken };
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/cash-register/new-operation`;
    axios
      .post(url, body, { headers })
      .then((res) => {
        // Check response
        if (!checkResponse(res)) throw res;

        // Resolve operation
        resolve({ success: true });
      })

      // If there is an error
      .catch((res) => {
        console.log("CLOSE CASH REGISTER ERROR", res);
        const error = {
          success: false,
          message: getResponseError(res, "Hubo un error cerrando la caja"),
        };
        reject(error);
      });
  });
}
