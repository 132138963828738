import React from "react";
import "./PageContainer.sass";

export default function PageContainer({ children, fitWidth, smallMargins }) {
  return (
    <div
      className={`page_container_comp ${fitWidth && "fit_width"} ${
        smallMargins && "small_margins"
      }`}
    >
      {children}
    </div>
  );
}
