import PageLoader from "components/General/PageLoader/PageLoader";
import React from "react";
import {
  getBatches,
  getCashRegisterLastOperation,
  getCategories,
  getPharmacyData,
  getPharmacyInventory,
  getPhysicalCashRegister,
  initFirebase,
  refreshSession,
} from "./AppActions";
import getNotifications from "./NotificationsActions";
// Create context
export const AppContext = React.createContext();

// Set provider
export default function AppProvider({ children }) {
  // Variables
  // Used so that admins can look at specific pharmacy information
  const [adminVisibility, setAdminVisibility] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [userToken, setUserToken] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [batches, setBatches] = React.useState([]);
  const [pharmacy, setPharmacy] = React.useState(null);
  const [isOpenCashRegister, setIsOpenCashRegister] = React.useState(false);
  const [inventory, setInventory] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const [physicalCashRegister, setPhysicalCashRegister] = React.useState(null);

  // Loaders
  const [userAndPharmacyReady, setUserAndPharmacyReady] = React.useState(false);
  const [getDataReady, setGetDataReady] = React.useState(false);

  // On view
  const [userOnView, setUserOnView] = React.useState(null);
  const [productOnView, setProductOnView] = React.useState(null);
  const [orderOnView, setOrderOnView] = React.useState(null);
  const [addressOnView, setAddressOnView] = React.useState(null);
  const [closureRequestOnView, setClosureRequestOnView] = React.useState(null);

  // Google analytics
  const [googleAnalytics, setGoogleAnalytics] = React.useState(null);

  // Get user and pharmacy data
  async function getUserAndPhamracyData() {
    const foundUser = await refreshSession(setUser, setUserToken); // Get user from ls

    // If there is a user get pharmacy data
    if (
      foundUser &&
      foundUser.role !== "nabu" &&
      foundUser.role !== "anafarmex"
    ) {
      getPharmacyData(foundUser.pharmacy_id, userToken, setPharmacy);
    }
    if (!foundUser) {
      // If there is no user set values to null and let render
      setUser(null);
      setPharmacy(null);
    }
    setUserAndPharmacyReady(true);
  }

  // Get pyhisical cash register data
  async function getPhysicalCashRegisterData() {
    // Search in LS if there is a cash register
    const physicalCashRegisterData = await getPhysicalCashRegister(
      userToken,
      pharmacy?.pharmacy_id
    );

    // If there is a cash register founded then get its open status
    setPhysicalCashRegister(physicalCashRegisterData);

    // Obten la ultima operación de esa caja
    if (physicalCashRegisterData) {
      getCashRegisterLastOperation(
        userToken,
        physicalCashRegisterData.cash_register_id,
        pharmacy.pharmacy_id,
        setIsOpenCashRegister
      );
    }
  }

  React.useEffect(() => {
    getUserAndPhamracyData();
    initFirebase(setGoogleAnalytics);
  }, []);

  // Get data when user or pharmacy changes
  React.useEffect(() => {
    // Get data
    if (userToken && pharmacy) {
      // Cash register data
      getPhysicalCashRegisterData();
      // Get pharmacy data
      getBatches(pharmacy.pharmacy_id, userToken, setBatches);
      getPharmacyInventory(userToken, pharmacy, setInventory);
      getNotifications(userToken, pharmacy.pharmacy_id, setNotifications);
    }

    getCategories(setCategories);
    // Let render
    setGetDataReady(true);
  }, [user, pharmacy]);

  return userAndPharmacyReady && getDataReady ? (
    <AppContext.Provider
      value={{
        user,
        setUser,
        userToken,
        setUserToken,
        categories,
        setCategories,
        batches,
        setBatches,
        userOnView,
        setUserOnView,
        productOnView,
        setProductOnView,
        orderOnView,
        setOrderOnView,
        pharmacy,
        setPharmacy,
        isOpenCashRegister,
        setIsOpenCashRegister,
        inventory,
        setInventory,
        addressOnView,
        setAddressOnView,
        notifications,
        setNotifications,
        closureRequestOnView,
        setClosureRequestOnView,
        physicalCashRegister,
        setPhysicalCashRegister,
        googleAnalytics,
        setGoogleAnalytics,
        adminVisibility,
        setAdminVisibility,
      }}
    >
      {children}
    </AppContext.Provider>
  ) : (
    <PageLoader />
  );
}

// Use app context hook
export const useAppContext = () => {
  return React.useContext(AppContext);
};
