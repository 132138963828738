import {
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import HeightIcon from "@mui/icons-material/Height";
import "./FilterProductsModal.sass";
import toast from "react-hot-toast";
import { checkResponse, getResponseError } from "helpers/requests";
import { useAppContext } from "contexts/AppContext";
import axios from "axios";

export default function FilterProductsModal({
  setShowFilterModal,
  setProducts,
  query,
  setQuery,
  setPage,
  mode,
}) {
  // Variables
  const { categories, pharmacy, inventory, user } = useAppContext();
  const [category, setCategory] = React.useState("");
  const [minPrice, setMinPrice] = React.useState("");
  const [maxPrice, setMaxPrice] = React.useState("");
  const [minQty, setMinQty] = React.useState("");
  const [maxQty, setMaxQty] = React.useState("");
  const [salt, setSalt] = React.useState("");
  const [inventoryMode] = React.useState(mode === "inventory");
  const [isPharmacyUser] = React.useState(
    user?.role === "pharmacy_owner" ||
      user?.role === "pharmacy_admin" ||
      user?.role === "employee"
  );

  // Close modal
  const closeModal = () => setShowFilterModal(false);

  // Filter Products
  async function filterProducts() {
    try {
      // Return if all values are empty
      const values = [
        query,
        category,
        minPrice,
        maxPrice,
        minQty,
        maxQty,
        salt,
      ];
      if (values.every((v) => v === "" || !v)) return;

      // Create req body
      let body = {};
      if (inventoryMode) {
        body = {
          pharmacy_id: pharmacy.pharmacy_id,
          query: query && query !== "" ? query : null,
          category: category && category !== "" ? category : null,
          minPrice: minPrice && minPrice !== "" ? parseFloat(minPrice) : null,
          maxPrice: maxPrice && maxPrice !== "" ? parseFloat(maxPrice) : null,
          minQty: minQty && minQty !== "" ? parseFloat(minQty) : null,
          maxQty: maxQty && maxQty !== "" ? parseFloat(maxQty) : null,
          salt: salt && salt !== "" ? salt : null,
        };
      } else {
        body = {
          query: query && query !== "" ? query : null,
          category: category && category !== "" ? category : null,
          salt: salt && salt !== "" ? salt : null,
        };
      }

      if (isPharmacyUser) body.pharmacy_id = pharmacy.pharmacy_id;

      // Make request
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = inventoryMode
        ? `${apiUrl}/inventory/filter`
        : `${apiUrl}/products/filter`;
      const res = await axios.post(url, body);

      // Check response
      if (!checkResponse(res)) throw res;

      // Set products
      setProducts(res?.data?.body?.products);
      setPage(1);
      closeModal();
    } catch (error) {
      console.log("FILTER PRODUCTS ERROR", error);
      toast.error(
        getResponseError(error),
        "Hubo un error realizando la filtración"
      );
    }
  }

  // Clean filters
  function cleanFilters() {
    setProducts(Object.values(inventory).slice(0, 10));
    setQuery("");
    setCategory("");
    setMinPrice("");
    setMaxPrice("");
    setMinQty("");
    setMaxQty("");
    setPage(1);
    closeModal();
  }

  return (
    <div className="filter_products_modal_background">
      <div className="modal">
        {/* Close Btn */}
        <IconButton className="close_btn" onClick={closeModal}>
          <CloseIcon fontSize="inherit" />
        </IconButton>

        {/* Title */}
        <h1>Filtrar Productos</h1>

        {/* Seatch input */}
        <TextField
          fullWidth
          label="Buscar por nombre"
          variant="outlined"
          size="small"
          className="search_input"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Categories */}
        <FormControl fullWidth size="small" className="categories">
          <InputLabel id="categories">Categoría</InputLabel>
          <Select
            labelId="categories"
            label="Categoría"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <MenuItem value="" />
            {categories.map((c) => (
              <MenuItem key={c.category_id} value={c.category_id}>
                {c.category_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Salt or Component */}
        <TextField
          fullWidth
          label="Componente o Sal"
          variant="outlined"
          size="small"
          className="search_input"
          value={salt}
          onChange={(e) => setSalt(e.target.value)}
        />

        {/* Price */}
        {inventoryMode && (
          <div className="range_form">
            <p className="label">Precio</p>
            <div className="inputs">
              <input
                type="number"
                placeholder="Min"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />
              <HeightIcon className="range_icon" />
              <input
                type="number"
                placeholder="Max"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </div>
          </div>
        )}

        {/* Quantity */}
        {inventoryMode && (
          <div className="range_form">
            <p className="label">Cantidad en Inventario</p>
            <div className="inputs">
              <input
                type="number"
                placeholder="Min"
                value={minQty}
                onChange={(e) => setMinQty(e.target.value)}
              />
              <HeightIcon className="range_icon" />
              <input
                type="number"
                placeholder="Max"
                value={maxQty}
                onChange={(e) => setMaxQty(e.target.value)}
              />
            </div>
          </div>
        )}

        {/* Buttons */}
        <div className="buttons">
          <Button
            color="error"
            variant="outlined"
            className="btn"
            onClick={cleanFilters}
          >
            Limpiar
          </Button>
          <Button
            color="primary"
            variant="outlined"
            className="btn"
            onClick={filterProducts}
          >
            Aplicar
          </Button>
        </div>
      </div>
    </div>
  );
}
