import { Button } from "@mui/material";
import GoToItemDetailsPageBtn from "components/General/GoToItemDetailsPageBtn/GoToItemDetailsPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import { translateRole } from "helpers/translators";
import React from "react";
import { Link } from "react-router-dom";
import "./MyProfile.sass";

export default function MyProfile() {
  // Variables
  const { user } = useAppContext();

  return (
    <PageContainer fitWidth>
      <div className="my_profile">
        {/* Title */}
        <h1>Mi Perfil</h1>

        {/* Img */}
        <img
          src={user?.employee_photo || "/imgs/no_user.jpg"}
          className="user_img"
          alt="User Profile"
        />

        {/* Info */}
        <div className="card-info">
          <div className="row">
            <div className="field">
              <p className="field-title">Nombre</p>
              <p>{user.name || "Sin información"}</p>
            </div>

            <div className="field">
              <p className="field-title">Apellidos</p>
              <p>{user.last_name || "Sin información"}</p>
            </div>
          </div>

          <div className="row">
            <div className="field">
              <p className="field-title">Correo</p>
              <p>{user.mail || "Sin información"}</p>
            </div>

            <div className="field">
              <p className="field-title">Teléfono</p>
              <p>{user.phone_num || "Sin información"}</p>
            </div>
          </div>

          <div className="row">
            <div className="field">
              <p className="field-title">Rol</p>
              <p>{translateRole(user.role) || "Sin información"}</p>
            </div>

            <div className="field">
              <p className="field-title">Dirección</p>
              <GoToItemDetailsPageBtn
                item="Dirección"
                path={`/address/employee/${user.employee_id}`}
              />
            </div>
          </div>
        </div>

        <div className="buttons">
          <Link to="/my-profile/edit">
            <Button className="edit_button" variant="outlined" size="small">
              Editar Perfil
            </Button>
          </Link>
          <Link to="/my-profile/change-password">
            <Button className="edit_button" variant="outlined" size="small">
              Cambiar Contraseña
            </Button>
          </Link>
        </div>
      </div>
    </PageContainer>
  );
}
