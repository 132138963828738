import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import "./EditInventoryItem.sass";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import GenericModal from "components/General/GenericModal/GenericModal";
import toast from "react-hot-toast";
import { formatDate } from "helpers/date_formaters";

export default function ProductFrom() {
  // Variables
  const {
    pharmacy,
    productOnView: product,
    setProductOnView,
    userToken,
    inventory,
    user,
    batches,
    setBatches,
  } = useAppContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  // Input values
  const [price, setPrice] = React.useState(product.price || "0");
  const [itemCost, setItemCost] = React.useState(product.item_cost || "0");
  const [status, setStatus] = React.useState(product.status || "active");
  const [addQuantity, setAddQuantity] = React.useState(false);
  const [quantity, setQuantity] = React.useState("");
  const [batch, setBatch] = React.useState("");
  const [expiracyDate, setExpiracyDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );

  // Tools
  const navigate = useNavigate();

  function updateBatchInContext(badgeId) {
    // Find if there is the batch in the context
    const batchFounded = batches.find((b) => b.badge_id === badgeId);

    let newBatchData = {};
    if (batchFounded) {
      newBatchData = {
        ...batchFounded,
        quantity: batchFounded?.quantity + parseFloat(quantity),
        item_cost: parseFloat(itemCost),
        item_price: parseFloat(price),
      };
    } else {
      newBatchData = {
        badge_id: badgeId,
        item_id: product?.item_id,
        pharmacy_id: pharmacy?.pharmacy_id,
        expiracy_date: expiracyDate,
        badge: batch,
        quantity: parseFloat(quantity),
        item_cost: parseFloat(itemCost),
        item_price: parseFloat(price),
      };
    }

    // Filter batches
    const filteredBatches = batches.filter((b) => b.badge_id !== badgeId);
    const updatedBatches = [...filteredBatches, newBatchData];
    setBatches(updatedBatches);
  }

  // Update product
  async function updateProduct() {
    try {
      // Validate fields
      if (!validateInputs([price, itemCost, status])) {
        toast.error("Debes llenar todos los campos");
        return;
      }

      if (addQuantity && !validateInputs([quantity, batch, expiracyDate])) {
        toast.error("Debes llenar todos los campos");
        return;
      }

      // Make request
      const body = {
        price: parseFloat(price),
        item_cost: parseFloat(itemCost),
        quantity: parseFloat(quantity),
        entry_date: formatDate(new Date()),
        pharmacy_id: pharmacy.pharmacy_id,
        registered_by: user.employee_id,
        badge: batch,
        expiracy_date: expiracyDate,
        status,
        is_adding_quantity: addQuantity,
      };
      const headers = {
        authToken: userToken,
      };
      const url = `https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/inventory/update/${product.item_id}`;
      const res = await axios.put(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;

      // Update product and inventory
      const updatedItem = {
        ...product,
        price: body.price,
        item_cost: body.item_cost,
        status: body.status,
      };
      if (addQuantity) updatedItem.quantity = product.quantity + body.quantity;

      setProductOnView(updatedItem);
      inventory[product.sku] = updatedItem;

      // Add new batch data to inventory
      if (addQuantity) updateBatchInContext(res.data?.body?.badge_id);

      // Alert and navigate
      toast.success("Producto editado exitosamente");
      navigate("/item/view");
    } catch (res) {
      console.log("EDIT PRODUCT INVENTORY ERROR:", res);
      toast.error(getResponseError(res, "Hubo un error editando el producto"));
    }
  }

  return (
    <PageContainer fitWidth>
      <div className="product_form">
        {/* Back Btn */}
        <BackPageBtn noMargin path="/item/view" />

        {/* Title */}
        <h1>Editar Inventario</h1>

        {/* Inputs */}
        <TextField
          label="Precio de Venta"
          className="input"
          value={price}
          type="number"
          onChange={(e) => setPrice(e.target.value)}
        />

        <TextField
          label="Costo por Unidad"
          className="input"
          value={itemCost}
          type="number"
          onChange={(e) => setItemCost(e.target.value)}
        />

        <FormControl variant="outlined">
          <InputLabel id="status-select">Estado</InputLabel>
          <Select
            labelId="status-select"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            label="Estado"
          >
            <MenuItem value="" disabled />
            <MenuItem value="active">Activo</MenuItem>
            <MenuItem value="deleted">Eliminado</MenuItem>
            <MenuItem value="unavailable">No Disponible</MenuItem>
          </Select>
        </FormControl>

        {/* Add Product Quantity */}
        <FormControlLabel
          control={
            <Switch
              checked={addQuantity}
              onChange={(e) => setAddQuantity(e.target.checked)}
            />
          }
          label="Añadir Cantidad"
          sx={{ marginTop: "20px", marginBottom: "20px" }}
        />

        {addQuantity && (
          <>
            <TextField
              label="Cantidad"
              className="input"
              value={quantity}
              type="number"
              onChange={(e) => setQuantity(e.target.value)}
            />
            <TextField
              label="Lote"
              className="input"
              value={batch}
              onChange={(e) => setBatch(e.target.value)}
            />
            <TextField
              label="Fecha de Caducidad"
              className="input"
              value={expiracyDate}
              type="date"
              onChange={(e) => setExpiracyDate(e.target.value)}
            />
          </>
        )}

        {/* Button */}
        <Button
          onClick={() => setShowConfirmModal(true)}
          className="btn"
          variant="contained"
        >
          Editar Producto
        </Button>
      </div>

      {/* Confirm Modal */}
      {showConfirmModal && (
        <GenericModal
          title="Editar Producto"
          onCancel={() => setShowConfirmModal(false)}
          onContinue={updateProduct}
        >
          <p className="label">
            ¿Estás seguro que quieres editar los datos inventario de este
            producto?
          </p>
        </GenericModal>
      )}
    </PageContainer>
  );
}
