/* eslint-disable no-unused-vars */
import axios from "axios";
import { desencriptar, encriptar } from "helpers/encrypt";
import { getResponseError, checkResponse } from "helpers/requests";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import toast from "react-hot-toast";

// Get categories
export async function getCategories(setCategories) {
  try {
    // Make request
    const url =
      "https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/product-categories";
    const res = await axios.get(url);

    // Check for errors
    if (!checkResponse(res)) throw res;

    // Set categories
    setCategories(res.data?.body?.categories);
  } catch (res) {
    console.log("GET CATEGORIES ERROR:", res);
    toast.error(
      getResponseError(res),
      "Hubo un error obteniendo las categorías"
    );
  }
}

// Get batches
export async function getBatches(pharmacyId, authToken, setBatches) {
  try {
    // Make request
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/batches/pharmacy/${pharmacyId}`;
    const res = await axios.get(url, { headers: { authToken } });

    // Check for errors
    if (!checkResponse(res)) throw res;

    // Set categories
    setBatches(res.data?.body?.batches);
  } catch (res) {
    console.log("GET BATCHES ERROR:", res);
    toast.error(getResponseError(res), "Hubo un error obteniendo los lotes");
  }
}

// Get pharmacy inventory
export async function getPharmacyInventory(userToken, pharmacy, setInventory) {
  try {
    // Make request
    const headers = { authToken: userToken };
    const url = `https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/inventory/pharmacy/${pharmacy.pharmacy_id}`;
    const res = await axios.get(url, { headers });

    // Check for errors
    if (!checkResponse(res)) throw res;

    // Create inventory map
    const inventory = {};
    const items = res.data?.body?.inventory;
    items.forEach((item) => {
      inventory[item.sku] = item;
    });
    setInventory(inventory);
  } catch (res) {
    console.log("GET PHARMACY INVENTORY ERROR:", res);
    toast.error(
      getResponseError(res),
      "Hubo un error obteniendo el inventario de la farmacia"
    );
  }
}

// Search local storage
export async function refreshSession(setUser, setUserToken) {
  try {
    // Get values from ls
    const lsKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;
    const tokenEncrypted = localStorage.getItem("mf360-user-token");

    if (!tokenEncrypted) return null;

    // Decrypt values
    const tokenStr = await desencriptar(lsKey, tokenEncrypted);
    const body = {
      authToken: tokenStr,
    };

    // Make request
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/auth/pos/refresh-session`;
    const res = await axios.post(url, body);
    if (!checkResponse(res)) throw res;
    // Set values
    const { user } = res.data.body;
    const { token } = res.data.body;
    setUser(user);
    setUserToken(token);

    // Save new token on ls
    localStorage.setItem("mf360-user-token", await encriptar(lsKey, token));
    return user;
  } catch (error) {
    console.log(`REFRESH SESSION ERROR: ${getResponseError(error)}`);
    setUser(null);
    setUserToken(null);
    return null;
  }
}

// Serach in LS for physical cash register info
export async function getPhysicalCashRegister(authToken, pharmacyId) {
  // Get encrypted
  const encryptedData = localStorage.getItem("mf360-pcr");

  // If there is not encrypted data
  if (!encryptedData) return null;

  // Decrypt
  const lsKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;
  const cashRegisterId = await desencriptar(lsKey, encryptedData);

  // Get pcr data from db
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/physical-registers/${cashRegisterId}`;
    const res = await axios.get(url, { headers: { authToken } });

    if (!checkResponse(res)) throw Error("Error en fetch");

    // Check if cash register if from currente pharmacy
    if (pharmacyId !== res.data?.body?.data?.pharmacy_id) return null;

    return res.data?.body?.data;
  } catch (error) {
    localStorage.removeItem("mf360-pcr"); // Remove data from ls
    console.log("GET PCR DATA ERROR:", error);
    toast.error("Hubo un error obteniendo los datos de la caja registradora");
    return null;
  }
}

// Get cash register last operation
export async function getCashRegisterLastOperation(
  userToken,
  cash_register_id,
  pharmacy_id,
  setIsOpenCashRegister
) {
  try {
    // Make request
    const headers = { authToken: userToken };
    const params = { cash_register_id, pharmacy_id };
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/cash-register/last-operation`;
    const res = await axios.get(url, { headers, params });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return operation
    const operation = res?.data?.body?.operation;
    if (!res?.data?.body?.operation) setIsOpenCashRegister(false);
    else if (operation?.operation_type === "open") setIsOpenCashRegister(true);
    else if (operation?.operation_type === "close")
      setIsOpenCashRegister(false);
  } catch (res) {
    console.log("GET CASH REGISTER STATUS", res);
    toast.error(
      getResponseError(res, "Hubo un error obteniendo el estado de la caja")
    );
  }
}

// Get pharmacy data
export async function getPharmacyData(pharmacyId, userToken, setPharmacy) {
  try {
    // Make request
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/pharmacys/${pharmacyId}`;
    const headers = { authToken: userToken };
    const res = await axios.get(url, { headers });

    // Check response
    if (!checkResponse(res)) throw res;

    // Set pharmacy
    setPharmacy(res?.data?.body?.pharmacy);
  } catch (res) {
    console.log("GET PHARMACY DATA ERROR", res);
    toast.error(
      getResponseError(res, "Hubo un error obteniendo los datos de la farmacia")
    );
  }
}

export async function initFirebase(setGoogleAnalytics) {
  try {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
    };

    // Initialize Firebase
    const firebaseApp = initializeApp(firebaseConfig);
    const analytics = getAnalytics(firebaseApp);
    setGoogleAnalytics(analytics);
  } catch (error) {
    console.log(`Analytics error: ${error}`);
    setGoogleAnalytics(null);
  }
}
