import { Button } from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import "./ProfitTable.sass";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import { useAppContext } from "contexts/AppContext";
import { getProfitMovementType } from "helpers/translators";
import ChangePageBtn from "components/General/ChangePageBtn/ChangePageBtn";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  calculateProfit,
  downloadMovements,
  getMovements,
} from "./ProfitTableActions";

export default function ProfitTable() {
  const { pharmacy, userToken } = useAppContext();
  const [movements, setMovements] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [monthOnView, setMonthOnView] = React.useState(
    new Date().toISOString().split("T")[0]
  );

  // Totals
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalIva, setTotalIva] = React.useState(0);
  const [totalCharges, setTotalCharges] = React.useState(0);
  const [totalCredit, setTotalCredit] = React.useState(0);

  async function getMovementsHandler() {
    const res = await getMovements(
      pharmacy?.pharmacy_id,
      page,
      monthOnView,
      userToken
    );

    if (res.success) {
      setMovements(res?.movements);
      setLoading(false);
    }
  }

  async function calculateProfitHandler() {
    const res = await calculateProfit(
      pharmacy?.pharmacy_id,
      monthOnView,
      userToken
    );

    if (res.success) {
      setTotalAmount(res.data.total_ammount);
      setTotalIva(res.data.total_iva);
      setTotalCharges(res.data.total_charges);
      setTotalCredit(res.data.total_credits);
    }
  }

  async function downloadExcelHandler() {
    // Obtener todos los movimientos del mes
    const res = await getMovements(
      pharmacy?.pharmacy_id,
      null,
      monthOnView,
      userToken
    );

    if (res.success) {
      downloadMovements(res.movements);
    }
  }

  React.useEffect(() => {
    getMovementsHandler();
  }, [page, monthOnView]);

  React.useEffect(() => {
    calculateProfitHandler();
  }, [monthOnView]);

  return (
    <div className="profit_table">
      <div className="top_section">
        <div>
          <h3>Totales del Mes:</h3>
          <div className="totals">
            <div className="labels">
              <p className="label">Balance:</p>
              <p className="label">IVA:</p>
              <p className="label">Cargos:</p>
              <p className="label">Crédito:</p>
            </div>
            <div className="values">
              <p className="value">$ {totalAmount?.toFixed(2)}</p>
              <p className="value">$ {totalIva?.toFixed(2)}</p>
              <p className="value">$ {totalCharges?.toFixed(2)}</p>
              <p className="value">$ {totalCredit?.toFixed(2)}</p>
            </div>
          </div>
        </div>

        <div className="actions">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Seleccionar Mes"
              views={["month", "year"]}
              openTo="month"
              value={monthOnView}
              onChange={(newValue) => {
                const formatedDate = new Date(newValue)
                  .toISOString()
                  .split("T")[0];
                setMonthOnView(formatedDate);
                setPage(1);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            size="small"
            sx={{ ml: "10px" }}
            onClick={downloadExcelHandler}
          >
            Descargar Excel
          </Button>
        </div>
      </div>

      <div className="movements">
        {!loading ? (
          <>
            <table className="profit_movements">
              <thead>
                <tr>
                  <th>Movimiento</th>
                  <th>Fecha</th>
                  <th className="amount_header">Cargo</th>
                  <th className="amount_header">Crédito</th>
                  <th className="amount_header">IVA</th>
                  <th className="amount_header">Total</th>
                </tr>
              </thead>

              <tbody>
                {movements.map((m, i) => (
                  <tr key={i}>
                    <td>
                      {getProfitMovementType(m.movement) || "Sin información"}
                    </td>
                    <td>{new Date(m.date).toDateString()}</td>
                    <td className="amount_cell">
                      {m.movement === "invoice" ||
                      m.movement === "product_return"
                        ? `$ ${m.ammount.toFixed(2)}`
                        : "-"}
                    </td>
                    <td className="amount_cell">
                      {m.movement === "sale"
                        ? `$ ${m.ammount.toFixed(2)}`
                        : "-"}
                    </td>
                    <td className="amount_cell">
                      {m?.iva !== null ? `$ ${m.iva.toFixed(2)}` : "-"}
                    </td>
                    <td className={`amount_cell ${m.movement}`}>
                      $ {m.ammount.toFixed(2) || "Sin información"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <ChangePageBtn
              page={page}
              setPage={setPage}
              data={movements}
              isArray={false}
              defaultBehaviour={false}
            />
          </>
        ) : (
          <LoadingTable nCols={3} />
        )}
      </div>
    </div>
  );
}
