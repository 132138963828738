import React from "react";
import "./ProductCategories.sass";
import PageContainer from "components/General/PageContainer/PageContainer";
import { Button, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useAppContext } from "contexts/AppContext";
import GenericModal from "components/General/GenericModal/GenericModal";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import toast from "react-hot-toast";

export default function ProductCategories() {
  // Variables
  const { categories, setCategories } = useAppContext();
  const [isOpenAddCategoryModal, setIsOpenAddCategoryModal] =
    React.useState(false);
  const [isOpenEditCategoryModal, setIsOpenEditCategoryModal] =
    React.useState(false);
  const [addCatInput, setAddCatInput] = React.useState("");
  const [editCatInput, setEditCatInput] = React.useState("");
  const [editCategoryId, setEditCategoryId] = React.useState(null);

  // Add category
  async function addCategory() {
    try {
      // Validate fields
      if (!validateInputs([addCatInput])) {
        toast.error("El nombre de la nueva categoría es inválido");
        return;
      }

      // Make request
      const url =
        "https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/product-categories";
      const body = {
        category_name: addCatInput,
      };
      const res = await axios.post(url, body);

      // Check for errors
      if (!checkResponse(res)) throw res;

      // Set new categories
      setCategories([
        ...categories,
        {
          category_id: res?.data?.body?.item_id,
          category_name: addCatInput,
        },
      ]);
      setIsOpenAddCategoryModal(false);
      toast.success("Categoría añadida correctamente.");
    } catch (res) {
      console.log("ADD CATEGORY ERROR:", res);
      toast.error(
        getResponseError(res),
        "Hubo un error añadiendo la categoría"
      );
    }
  }

  // Edit category
  async function editCategory() {
    try {
      // Validate fields
      if (!validateInputs([editCatInput])) {
        toast.error("El nombre de la categoría es inválido");
        return;
      }

      // Make request
      const url =
        "https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/product-categories";
      const body = {
        category_id: editCategoryId,
        category_name: editCatInput,
      };
      const res = await axios.put(url, body);

      // Check for errors
      if (!checkResponse(res)) throw res;

      // Set new categories
      setCategories(
        categories.map((c) => {
          if (c.category_id === editCategoryId)
            return { category_id: c.category_id, category_name: editCatInput };
          return c;
        })
      );
      setIsOpenEditCategoryModal(false);
      toast.success("Categoría editada correctamente.");
    } catch (res) {
      console.log("EDIT CATEGORY ERROR:", res);
      toast.error(getResponseError(res), "Hubo un error editando la categoría");
    }
  }

  return (
    <PageContainer fitWidth>
      {/* Navigate back btn  */}
      <BackPageBtn />
      <div className="product_categories">
        {/* Add button */}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          size="small"
          onClick={() => setIsOpenAddCategoryModal(true)}
        >
          Añadir Categoría
        </Button>

        {/* List of categories */}
        <div className="categories">
          <div className="headers">
            <p className="c1">Categoría</p>
            <p className="c2">Editar</p>
          </div>

          {/* Map categories */}
          {categories.map((c) => (
            <div className="item" key={c.category_id}>
              <p className="c1">{c.category_name}</p>

              <div className="c2">
                <IconButton
                  variant="solid"
                  onClick={() => {
                    setEditCategoryId(c.category_id);
                    setEditCatInput(c.category_name);
                    setIsOpenEditCategoryModal(true);
                  }}
                >
                  <EditIcon className="icon" />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Add Category Modal */}
      {isOpenAddCategoryModal && (
        <GenericModal
          title="Agregar Categoría"
          onContinue={addCategory}
          onCancel={() => setIsOpenAddCategoryModal(false)}
        >
          <TextField
            fullWidth
            label="Nombre de Categoría"
            variant="outlined"
            value={addCatInput}
            onChange={(e) => setAddCatInput(e.target.value)}
          />
        </GenericModal>
      )}

      {/* Edit Category Modal */}
      {isOpenEditCategoryModal && (
        <GenericModal
          title="Editar Categoría"
          onContinue={editCategory}
          onCancel={() => setIsOpenEditCategoryModal(false)}
        >
          <TextField
            fullWidth
            label="Nuevo nombre de Categoría"
            variant="outlined"
            value={editCatInput}
            onChange={(e) => setEditCatInput(e.target.value)}
          />
        </GenericModal>
      )}
    </PageContainer>
  );
}
