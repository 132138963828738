import { Button, Chip } from "@mui/material";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./AccountStatement.sass";
import { useAppContext } from "contexts/AppContext";
import toast from "react-hot-toast";
import axios from "axios";
import { checkResponse } from "helpers/requests";
import { getAccountMovementType } from "helpers/translators";
import TuneIcon from "@mui/icons-material/Tune";
import ChangePageBtn from "components/General/ChangePageBtn/ChangePageBtn";
import FilterByDatesModal from "components/AccountStatement/FilterByDatesModal/FilterByDatesModal";
import { Link } from "react-router-dom";

export default function AccountStatement() {
  const { pharmacy, userToken } = useAppContext();
  const [movements, setMovements] = React.useState([]);
  const [initialDate, setInitialDate] = React.useState(null);
  const [finalDate, setFinalDate] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [showFilterDatesModal, setShowFilterDatesModal] = React.useState(false);
  const [pastDebt, setPastDebt] = React.useState(0);
  const [lastMonthDebt, setLastMonthDebt] = React.useState(0);

  async function getMovements() {
    try {
      // Hacer petición
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/account-movements/${pharmacy?.pharmacy_id}`;
      const params = { page, initial_date: initialDate, final_date: finalDate };
      const headers = { authToken: userToken };
      const res = await axios.get(url, { params, headers });

      // Checar respuesta
      if (!checkResponse(res)) throw res;

      setMovements(res?.data?.body?.movements);
    } catch (error) {
      console.log("GET ACCOUNT MOVEMENTS ERROR:", error);
      toast.error("Hubo un error obteniendo los movimientos");
    }
  }

  async function getLastMontDebt() {
    try {
      // Si la farmacia no tien
      if (pharmacy?.status === "active") {
        setLastMonthDebt(0);
        setPastDebt(pharmacy?.debt);
        return;
      }

      // Hacer petición
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/account-movements/${pharmacy?.pharmacy_id}/last-month-charges`;
      const headers = { authToken: userToken };
      const res = await axios.get(url, { headers });

      // Checar respuesta
      if (!checkResponse(res)) throw res;

      // Calcular resultados
      const resLastMonth = res?.data?.body?.last_month_charges;
      setLastMonthDebt(resLastMonth);
      if (pharmacy.debt === 0) {
        setPastDebt(0);
        return;
      }
      setPastDebt(pharmacy?.debt - resLastMonth);
    } catch (error) {
      console.log("GET ACCOUNT MOVEMENTS ERROR:", error);
      toast.error("Hubo un error obteniendo los movimientos");
    }
  }

  React.useEffect(() => {
    getLastMontDebt();
    getMovements();
  }, [initialDate, finalDate, page]);

  return (
    <PageContainer>
      <BackPageBtn path="/my-pharmacy" />

      <div className="account_statement">
        <h1 className="page_title">Estado de Cuenta</h1>

        {/* Top Container */}
        <div className="top_container">
          <div className="data_item">
            <p className="label">Deuda Meses Anteriores</p>
            <p className="value">$ {pastDebt} pesos</p>
          </div>

          <div className="data_item">
            <p className="label">Cobro Mes Pasado</p>
            <p className="value">$ {lastMonthDebt} pesos</p>
          </div>

          <div className="data_item">
            <p className="label">Total a Pagar</p>
            <p className="value">$ {pharmacy?.debt} pesos</p>
          </div>
        </div>

        {/* Bottom Container */}
        <div className="bottom_container">
          <div className="headers">
            <h1>Movimientos de Cuenta</h1>

            <div>
              <Button
                variant="outlined"
                startIcon={<TuneIcon />}
                size="small"
                sx={{ marginRight: "10px" }}
                onClick={() => setShowFilterDatesModal(true)}
              >
                Filtrar
              </Button>
              {/* Solo mostrar boton de pagar si la deuda de la farmacia es mayor a 10 */}
              {pharmacy.debt > 10 && (
                <Link to="payments/new">
                  <Button
                    variant="outlined"
                    startIcon={<AddCircleIcon />}
                    size="small"
                  >
                    Realizar pago
                  </Button>
                </Link>
              )}
            </div>
          </div>

          {initialDate && finalDate && (
            <div className="date_range">
              <Chip
                color="info"
                variant="outlined"
                label={`Fecha Inicial: ${initialDate}`}
                sx={{ marginRight: "10px" }}
              />
              <Chip
                color="info"
                variant="outlined"
                label={`Fecha Final: ${finalDate}`}
              />
            </div>
          )}

          <table className="account_movements">
            <thead>
              <tr>
                <th>Movimiento</th>
                <th>Fecha</th>
                <th>Cantidad</th>
              </tr>
            </thead>

            <tbody>
              {movements.map((m) => (
                <tr key={m.movement_id}>
                  <td>
                    {getAccountMovementType(m.movement_type) ||
                      "Sin información"}
                  </td>
                  <td>{new Date(m.created_at).toDateString()}</td>
                  <td className={`amount_cell ${m.movement_type}`}>
                    $ {m.ammount || "Sin información"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <ChangePageBtn
            page={page}
            setPage={setPage}
            data={movements}
            isArray={false}
            defaultBehaviour={false}
          />
        </div>
      </div>

      {/* Filter By Dates Modal */}
      {showFilterDatesModal && (
        <FilterByDatesModal
          showModal={setShowFilterDatesModal}
          outInitialDate={initialDate}
          setOutInitialDate={setInitialDate}
          outFinalDate={finalDate}
          setOutFinalDate={setFinalDate}
          setPage={setPage}
        />
      )}
    </PageContainer>
  );
}
