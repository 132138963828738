import { Button, TextField } from "@mui/material";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import { encriptar } from "helpers/encrypt";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./EditMyProfile.sass";
import fileService from "services/fileService";

export default function EditMyProfile({ newUserMode }) {
  // Variables
  const { user, setUser, userToken, pharmacy } = useAppContext();
  const [imgChanged, setImgChanged] = React.useState(false);

  // Input values
  const [imgFile, setImgFile] = React.useState(null);
  const [name, setName] = React.useState(user.name || "");
  const [lastName, setLastName] = React.useState(user.last_name || "");
  const [email, setEmail] = React.useState(user.mail || "");
  const [phone, setPhone] = React.useState(user.phone_num || "");

  // Tools
  const navigate = useNavigate();

  // Edit my profile
  async function editProfile() {
    try {
      // Validate fields
      if (!validateInputs([name, lastName, email])) {
        toast.error("Debes llenar los campos obligatorios");
        return;
      }

      // Create body
      const body = {
        name,
        last_name: lastName,
        phone_num: phone === "" ? null : phone,
        role: user.role,
        status: user.status,
        employee_photo: user.employee_photo,
      };

      // If the user img changed update new image
      let imgUrl = null;
      const oldEmployeePhoto = user.employee_photo;
      if (imgChanged) {
        imgUrl = await fileService.uploadFile(
          imgFile,
          pharmacy?.pharmacy_id,
          user?.employee_id,
          "employees-images"
        );
        if (!imgUrl) toast.error("Hubo un error subiendo la imágen");
        else body.employee_photo = imgUrl;
      }

      // If new user
      if (newUserMode) body.status = "active";

      // Update Data
      const headers = { authToken: userToken };
      const url = `${process.env.REACT_APP_API_URL}/employees/${user.employee_id}`;
      const res = await axios.put(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;

      // Set user in context
      const newUser = { ...user, ...body };
      setUser(newUser);

      // Save user local storage
      const lsKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;
      const userStr = JSON.stringify(newUser);
      localStorage.setItem("gataca-user", await encriptar(lsKey, userStr));

      // Alert
      toast.success("Datos actualizados correctamente");

      // If user img was updated, delete old img
      if (imgChanged && imgUrl) fileService.deleteFile(oldEmployeePhoto);

      // Go to my profile or next step
      navigate("/my-profile");
    } catch (res) {
      console.log("EDIT PROFILE ERROR:", res);
      toast.error(
        getResponseError(res, "Hubo un error actualizando la cuenta")
      );
    }
  }

  return (
    <PageContainer fitWidth>
      <div className="edit_my_profile">
        {/* Back Button */}
        {!newUserMode && (
          <div className="back_btn_container">
            <BackPageBtn noMargin />
          </div>
        )}

        {/* Title */}
        <h1>Editar Mi Perfil</h1>

        {/* Img */}
        <img
          src={
            imgFile
              ? URL.createObjectURL(imgFile)
              : user?.employee_photo || "/imgs/no_user.jpg"
          }
          className="user_img"
          alt="User Profile"
        />
        <Button
          className="change_img_btn"
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            const imgInput = document.querySelector(".img_input");
            imgInput.click();
          }}
        >
          Cambiar Imágen
        </Button>

        <input
          type="file"
          className="img_input"
          accept="image/*"
          onChange={(e) => {
            setImgFile(e.target.files[0]);
            setImgChanged(true);
          }}
        />

        {/* Info */}
        <div className="card-info">
          <div className="row">
            <TextField
              label="Nombre"
              variant="standard"
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              label="Apellidos"
              variant="standard"
              className="input"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className="row">
            <TextField
              label="Correo"
              variant="standard"
              className="input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              label="Telefóno (Opcional)"
              variant="standard"
              className="input"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>

        <Button
          className="edit_button"
          variant="contained"
          onClick={editProfile}
        >
          Guardar Datos
        </Button>
      </div>
    </PageContainer>
  );
}
