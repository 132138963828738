import "./PharmaciesTable.sass";
import { IconButton } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { getDateString } from "helpers/date_formaters";
import { translatePharmacyStatus } from "helpers/translators";
import { useAppContext } from "contexts/AppContext";
import { useNavigate } from "react-router-dom";

export default function PharmaciesTable({ pharmacies }) {
  const { setPharmacy, setAdminVisibility, setUser } = useAppContext();
  const navigate = useNavigate();

  function handleViewPharmacy(pharmacy) {
    setPharmacy(pharmacy);
    setAdminVisibility(true);
    setUser((user) => {
      return {
        ...user,
        role: "pharmacy_owner",
        pharmacy_id: pharmacy?.pharmacy_id,
      };
    });
    navigate("/my-profile");
  }

  return (
    <table className="pharmacy-table">
      <thead className="header">
        <tr>
          <th scope="col">Logo</th>
          <th scope="col">Farmacia</th>
          <th scope="col">Ultimo Pago</th>
          <th scope="col">Estado</th>
          <th scope="col">Detalles</th>
        </tr>
      </thead>
      <tbody id="employees">
        {pharmacies.map((pharmacy) => (
          <tr key={pharmacy.pharmacy_id}>
            <td className="pharmacy">
              <img
                src={
                  pharmacy.logo_url ? pharmacy.logo_url : "/imgs/no-image.png"
                }
                alt="pharmacy"
              />
            </td>
            <td>
              <p>{pharmacy.pharmacy_name}</p>
            </td>
            <td>
              {pharmacy.last_time_paid !== "None"
                ? getDateString(pharmacy.last_time_paid, false)
                : "-"}
            </td>
            <td>{translatePharmacyStatus(pharmacy.status)}</td>
            <td>
              <IconButton
                variant="solid"
                onClick={() => handleViewPharmacy(pharmacy)}
              >
                <Visibility className="icon" />
              </IconButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
