import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "components/payments/CheckoutForm";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import axios from "axios";
import toast from "react-hot-toast";
import "./PaymentScreen.sass";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import LoadingComponent from "components/General/LoadingComponent/LoadingComponent";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function PaymentScreen() {
  const { user, pharmacy } = useAppContext();
  const [clientSecret, setClientSecret] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  async function createPaymentIntent() {
    try {
      const body = {
        email: user.mail,
        // La cantidad en stripe es en centavos, por lo que se debe multiplixar por 100 para obtener el valor normal
        amount: parseFloat(pharmacy.debt) * 100,
      };
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const apiUrl = process.env.REACT_APP_PAYMENT_INTENT_URL;
      const res = await axios.post(apiUrl, body, config);
      console.log(res);

      // Levantar errrores en caso de que los haya
      if (res.status !== 200) {
        throw res.data;
      }
      setClientSecret(res?.data?.paymentIntent);
      setLoading(false);
    } catch (res) {
      console.log("CREATE PAYMENT INTENT ERROR:", res);
      toast.error(
        "Se produjo un error al intnentar realizar el pago, intentelo de nuevo"
      );
    }
  }

  React.useEffect(() => {
    createPaymentIntent();
  }, []);

  return (
    <div className="checkout">
      <PageContainer>
        <BackPageBtn />
        {/* Parte superior para seleccionar cantidad a pagar  */}
        <div className="select-ammount">
          <div className="left">
            <div className="texts">
              <h3>
                Cantidad a pagar: <span>${pharmacy.debt}</span>{" "}
              </h3>
            </div>
          </div>
        </div>
        {loading === false ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm isLoading={loading} ammount={pharmacy.debt} />
          </Elements>
        ) : (
          <LoadingComponent />
        )}
      </PageContainer>
    </div>
  );
}

export default PaymentScreen;
