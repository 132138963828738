import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import LoadingTable from "components/General/LoadingTable/LoadingTable";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import { checkResponse, getResponseError } from "helpers/requests";
import shortid from "shortid";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./ClosureRequests.sass";

export default function ClosureRequests() {
  // Variables
  const { userToken, pharmacy, setClosureRequestOnView } = useAppContext();
  const [operations, setOperations] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  // Get Closure Requests
  async function getClosureRequests() {
    try {
      // Make request
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/cash-register/closure-requests`;
      const headers = { authToken: userToken };
      const params = { pharmacy_id: pharmacy.pharmacy_id };
      const res = await axios.get(url, { headers, params });

      // Check respose
      if (!checkResponse(res)) throw res;

      // Set operations
      setOperations(res?.data?.body?.operations);
      setLoading(false);
    } catch (error) {
      console.log("GET CLOSURE REQUESTS ERROR:", error);
      toast.error(
        getResponseError(
          error,
          "Hubo un error obteniendo las solicitudes de cierre"
        )
      );
    }
  }

  // On render
  React.useEffect(() => {
    getClosureRequests();
  }, []);

  return (
    <PageContainer fitWidth>
      <div className="closure_requests">
        {/* Back Button */}
        <BackPageBtn noMargin path="/cash-registers" />

        {/* Title */}
        <h1>Solicitudes de Cierres</h1>

        {/* Table */}
        {!loading ? (
          <table className="employee-table" key={shortid.generate()}>
            <thead className="employees_header">
              <tr>
                <th scope="col">Empleado</th>
                <th scope="col">Fecha de Cierre</th>
                <th scope="col">Ver</th>
              </tr>
            </thead>
            <tbody id="employees">
              {operations?.map((op) => (
                <tr key={op.operation_id}>
                  <td>
                    {op.name} {op.last_name}
                  </td>
                  <td>{op.operation_date}</td>
                  <td>
                    <IconButton
                      variant="solid"
                      onClick={() => {
                        setClosureRequestOnView(op);
                        navigate("/cash-registers/closure-requests/view");
                      }}
                    >
                      <Visibility className="icon" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <LoadingTable nCols={3} />
        )}
      </div>
    </PageContainer>
  );
}
