// Function to cut a text to a specific amount of characters
export function cutDisplayText(text, limit) {
  // If text length is bigger than limit
  if (text.length > limit) {
    return text.substring(0, limit).concat("...");
  }
  // If not return original text
  return text;
}

export function test() {
  return "test";
}
