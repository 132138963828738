export const COLORS_ARRAY = [
  "rgba(75, 192, 104, 0.5)",
  "rgba(255, 99, 132, 0.5)",
  "rgba(54, 162, 235, 0.5)",
  "rgba(255, 159, 64, 0.5)",
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 205, 86, 0.5)",
  "rgba(201, 203, 207, 0.5)",
];

export const BORDER_COLORS_ARRAY = [
  "rgba(75, 192, 104)",
  "rgba(255, 99, 132)",
  "rgba(54, 162, 235)",
  "rgba(255, 159, 64)",
  "rgba(153, 102, 255)",
  "rgba(255, 205, 86)",
  "rgba(201, 203, 207)",
];

export const COLORS_MAP = {
  red: "rgba(255, 99, 132, 0.5)",
  orange: "rgba(255, 159, 64, 0.5)",
  yellow: "rgba(255, 205, 86, 0.5)",
  green: "rgba(75, 192, 104, 0.5)",
  blue: "rgba(54, 162, 235, 0.5)",
  purple: "rgba(153, 102, 255, 0.5)",
  grey: "rgba(201, 203, 207, 0.5)",
};

export const BORDER_COLORS_MAP = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 104)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};
