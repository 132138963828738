import LoadingComponent from "components/General/LoadingComponent/LoadingComponent";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import React from "react";
import toast from "react-hot-toast";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import { formatDate } from "helpers/date_formaters";
import CountBills from "components/CashRegister/CountBills/CountBills";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { initialCount, validateBillsCount } from "helpers/bills";
import {
  getSessionSales,
  getSessionResults,
  closeCashRegister,
} from "./actionsCloseCashRegister";
import "./CloseCashRegister.sass";

export default function CloseCashRegister() {
  // Variables
  const {
    userToken,
    user,
    pharmacy,
    setIsOpenCashRegister,
    physicalCashRegister,
  } = useAppContext();
  const [loaded, setLoaded] = React.useState(false);
  const [sessionTotal, setSessionTotal] = React.useState(null);
  const [cashTotal, setCashTotal] = React.useState(null);
  const [cashPositive, setCashPositive] = React.useState(null);
  const [cardVisaTotal, setCardVisaTotal] = React.useState(null);
  const [cardMCTotal, setCardMCTotal] = React.useState(null);
  const [cardAMEXTotal, setCardAMEXTotal] = React.useState(null);
  const [voucherTotal, setVoucherTotal] = React.useState(null);
  const [withdrawalTotal, setWithdrawalTotal] = React.useState(null);
  const [productReturnsTotal, setProductReturnsTotal] = React.useState(null);
  const [openOp, setOpenOp] = React.useState(null);
  const [billsCount, setBillsCount] = React.useState(initialCount);
  const navigate = useNavigate();

  // Get results of the session
  async function getSessionResultsHandler() {
    try {
      // Get last operation
      const { sales, openOperation } = await getSessionSales(
        userToken,
        physicalCashRegister.cash_register_id,
        pharmacy.pharmacy_id
      );
      setOpenOp(openOperation);

      // Get results of the session
      const totals = getSessionResults(sales);
      setSessionTotal(totals.sessionTotal);
      setCashPositive(totals.cashPositiveTotal);
      setCashTotal(totals.cashTotal);
      setCardVisaTotal(totals.cardVisaTotal);
      setCardMCTotal(totals.cardMCTotal);
      setCardAMEXTotal(totals.cardAMEXTotal);
      setVoucherTotal(totals.voucherTotal);
      setWithdrawalTotal(totals.withdrawalTotal);
      setProductReturnsTotal(totals.productReturnsTotal);
      setLoaded(true);
    } catch (error) {
      toast.error(error);
    }
  }

  // Close Cash Register Handler
  async function closeCashRegisterHandler() {
    try {
      // Create body
      const body = {
        cash_register_id: physicalCashRegister.cash_register_id,
        employee_id: user.employee_id,
        pharmacy_id: pharmacy.pharmacy_id,
        operation_type: "close",
        cash_ammount: cashTotal,
        voucher_ammount: voucherTotal,
        visa_ammount: cardVisaTotal,
        mc_ammount: cardMCTotal,
        amex_ammount: cardAMEXTotal,
        status: "pending",
        operation_date: formatDate(new Date()),
        bills_count: billsCount,
      };

      // Validate bills
      const billsError = validateBillsCount(billsCount);
      if (billsError) throw billsError;

      // Close
      await closeCashRegister(userToken, body);

      toast.success("Solicitud de cierre enviada correctamente");
      setIsOpenCashRegister(false);
      navigate("/cash-register");
    } catch (error) {
      toast.error(error.message);
    }
  }

  // On render
  React.useEffect(() => {
    getSessionResultsHandler();
  }, []);

  return (
    <PageContainer fitWidth>
      <div className="close_cash_register">
        {loaded ? (
          <>
            {/* Back Btn */}
            <BackPageBtn noMargin />

            {/* Title */}
            <h1>Cerrar Caja Registradora</h1>

            {/* General Info */}
            <div className="section">
              <h2>Información del Cierre</h2>

              <div className="info_item">
                <p>Fecha de Apertura:</p>
                <span>{openOp?.operation_date || "Sin información"}</span>
              </div>
              <div className="info_item">
                <p>Monto de Apertura:</p>
                <span>${openOp?.opening_ammount || "Sin información"}</span>
              </div>
              <hr className="divider" />
              <div className="info_item">
                <p>Ganancia Total:</p>
                <span>${sessionTotal}</span>
              </div>
              <div className="info_item">
                <p>Total Actual en Caja:</p>
                <span>
                  $
                  {sessionTotal + openOp?.opening_ammount
                    ? sessionTotal + openOp?.opening_ammount
                    : "Sin información"}
                </span>
              </div>
            </div>

            {/* Totals */}
            <div className="section">
              <h2>Totales Registrados</h2>

              <div className="total_item">
                <p>
                  <AddCircleIcon className="icon" />
                  Ingresos en Efectivo:
                </p>
                <span>${cashPositive}</span>
              </div>
              <div className="total_item">
                <p>
                  <AddCircleIcon className="icon" />
                  Monto de Apertura:
                </p>
                <span>${openOp?.opening_ammount}</span>
              </div>
              <div className="total_item withdrawal">
                <p>
                  <RemoveCircleIcon className="icon" />
                  Retiros en Efectivo:
                </p>
                <span>${withdrawalTotal}</span>
              </div>
              {productReturnsTotal > 0 && (
                <div className="total_item withdrawal">
                  <p>
                    <RemoveCircleIcon className="icon" />
                    Devolución de Productos:
                  </p>
                  <span>${productReturnsTotal}</span>
                </div>
              )}

              <hr className="divider" />

              <div className="total_item">
                <p>
                  <PaymentsIcon className="icon" />
                  Total en Efectivo:
                </p>
                <span>
                  $
                  {cashTotal + openOp?.opening_ammount
                    ? cashTotal + openOp?.opening_ammount
                    : "Sin información"}
                </span>
              </div>
              <div className="total_item">
                <p>
                  <CreditCardIcon className="icon" /> Total en Visa:
                </p>
                <span>${cardVisaTotal}</span>
              </div>
              <div className="total_item">
                <p>
                  <CreditCardIcon className="icon" /> Total en Master Card:
                </p>
                <span>${cardMCTotal}</span>
              </div>
              <div className="total_item">
                <p>
                  <CreditCardIcon className="icon" /> Total en American Express:
                </p>
                <span>${cardAMEXTotal}</span>
              </div>
              <div className="total_item">
                <p>
                  <RequestPageIcon className="icon" />
                  Total en Vales:
                </p>
                <span>${voucherTotal}</span>
              </div>
            </div>

            {/* Bills */}
            <div className="section">
              <h2>Cuenta de Billetes y Monedas</h2>
              <CountBills
                billsCount={billsCount}
                setBillsCount={setBillsCount}
              />
            </div>

            {/* Buttons */}
            <div className="buttons">
              <Button
                variant="outlined"
                fullWidth
                className="btn"
                color="error"
                onClick={() => navigate(-1)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="btn"
                onClick={closeCashRegisterHandler}
              >
                Confirmar
              </Button>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </PageContainer>
  );
}
