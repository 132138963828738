import React from "react";
import LandingNavbar from "components/Landing/LandingNavbar/LandingNavbar";
import "./ContactLayout.sass";

function ContactLayout({ children }) {
  return (
    <div className="contact_layout">
      {/* Navbar */}
      <LandingNavbar />

      <div className="layout_container">
        {/* Left Side */}
        <div className="left_side">
          <img src="/imgs/register.png" alt="Medicines" />
        </div>
        <div className="right_side">{children}</div>
      </div>
    </div>
  );
}

export default ContactLayout;
