// import EmptyStateScreen from "components/General/EmptyStateScreen/EmptyStateScreen";
import { Check, Delete } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import axios from "axios";
import EmptyStateScreen from "components/General/EmptyStateScreen/EmptyStateScreen";
import { useAppContext } from "contexts/AppContext";
import { checkResponse, validateInputs } from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import GenericModal from "components/General/GenericModal/GenericModal";
import "./ViewProducts.sass";
import { formatDate } from "helpers/date_formaters";
import { useNavigate } from "react-router-dom";
import ChangePageBtn from "components/General/ChangePageBtn/ChangePageBtn";

export default function ViewProducts({
  products,
  setProducts,
  invoiceProvider,
  setInvoiceProvider,
  invoiceTotalCost,
  setInvoiceTotalCost,
  invoiceDate,
}) {
  // Variables
  const { user, userToken, pharmacy, inventory } = useAppContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [onView, setOnView] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();

  // Get total cost
  function getTotalCost() {
    let cost = 0;
    products.forEach((product) => {
      cost += product?.cost * product?.quantity;
    });
    return cost.toFixed(2);
  }

  function removeItemFromProducts(product) {
    const result = products.filter((item) => item.batch !== product.batch);
    setProducts(result);
  }
  // Register invoice
  async function registerInvoice() {
    try {
      setShowConfirmModal(false);

      // Validate fields
      if (!validateInputs([invoiceProvider, invoiceTotalCost, invoiceDate])) {
        toast.error("Debes llenar todos los campos");
        return;
      }
      if (products.length <= 0) {
        toast.error("Debes de agregar algún producto");
        return;
      }

      // Separte items from catalogue products
      const items = [];
      const catalogue_products = [];
      products.forEach((product) => {
        if (product.item_id) items.push(product);
        else catalogue_products.push(product);
      });

      // Make request
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/invoices/pharmacy/register-invoice`;
      const body = {
        pharmacy_id: pharmacy.pharmacy_id,
        provider_id: invoiceProvider,
        employee_id: user.employee_id,
        invoice_total_cost: invoiceTotalCost,
        invoice_date: invoiceDate,
        created_at: formatDate(new Date()),
        items,
        catalogue_products,
      };
      const headers = { authToken: userToken };
      const res = await axios.post(url, body, { headers });

      // Check response
      if (!checkResponse(res)) throw res;

      // Update data in context
      items.forEach((item) => {
        const inventoryData = inventory[item.sku];
        inventory[item.sku] = {
          ...item,
          item_cost: item.cost,
          quantity: item.quantity + inventoryData.quantity,
        };
      });
      catalogue_products.forEach((product) => {
        inventory[product.sku] = {
          ...product,
          item_cost: product.cost,
          pharmacy_id: pharmacy.pharmacy_id,
          items_sold: 0,
          status: "active",
        };
      });
      const newItemIds = res?.data?.body?.item_ids;
      newItemIds.forEach((pair) => {
        const inventoryData = inventory[pair.sku];
        inventory[pair.sku] = {
          ...inventoryData,
          item_id: pair.item_id,
        };
      });

      // Success
      setInvoiceProvider("");
      setInvoiceTotalCost("");
      setProducts([]);
      localStorage.removeItem("MF360-Invoice-Products");

      toast.success("Inventario modificado correctamente");
      navigate("/inventory");
    } catch (error) {
      console.log("REGISTER INVOICE ERROR: ", error);
      toast.error(error, "Hubo un erro registrando la factura");
    }
  }

  // Handle submit
  async function handleSubmit() {
    if (parseFloat(getTotalCost()) !== parseFloat(invoiceTotalCost))
      setShowConfirmModal(true);
    else registerInvoice();
  }
  // Cambiar los productos cada vez que cambie la pagina
  React.useEffect(() => {
    const productsPerPage = 5;
    // Calcular desde donde se muestran los productos basandose en el num de pagina
    // Ej: page: 2   from: (2*5) - 5 = 5;
    const from = page * productsPerPage - productsPerPage;
    setOnView(Object.values(products).slice(from, from + productsPerPage));
  }, [page, products]);

  // Cambiar de pagina cuando se llegue al limite de la pagina actual
  React.useEffect(() => {
    if (onView.length === 5) {
      setPage(page + 1);
    }
  }, [products]);

  return (
    <div className="view_products">
      {/* titulo */}
      <h3 className="title">Productos</h3>

      {/* Tabla de productos */}
      {onView.length !== 0 ? (
        <>
          <table className="product_table">
            <thead className="product_header">
              <tr>
                <th scope="col">Imagen</th>
                <th scope="col name">Nombre</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Costo Unitario</th>
                <th scope="col">Costo Total</th>
                <th scope="col">Eliminar</th>
              </tr>
            </thead>
            <tbody id="products">
              {onView.map((product) => (
                <tr key={product?.key}>
                  <td>
                    <img
                      src={product?.photo_url || "/imgs/no-image.png"}
                      alt="product"
                    />
                  </td>
                  <td className="name">{product?.product_name}</td>
                  <td>{product?.quantity}</td>
                  <td>$ {product?.cost}</td>
                  <td>$ {(product?.cost * product?.quantity).toFixed(2)}</td>
                  <td>
                    <IconButton
                      onClick={() => removeItemFromProducts(product)}
                      color="danger"
                    >
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Costo total */}
          <p className="total_cost">
            <span>Costo Total:</span> ${getTotalCost()}{" "}
          </p>

          {/* Submit button */}
          <div className="submit-btn">
            <Button
              variant="contained"
              startIcon={<Check />}
              onClick={handleSubmit}
            >
              Actualizar Inventario
            </Button>
          </div>
          <ChangePageBtn
            page={page}
            setPage={setPage}
            data={products}
            itemsPerPage={5}
          />
        </>
      ) : (
        <EmptyStateScreen
          imgSrc="/imgs/no-inventory.png"
          title="Los productos que agregues apareceran aqui"
          subtitle="Agregalos desde la barra lateral izquierda"
        />
      )}

      {/* Confirm Modal */}
      {showConfirmModal && (
        <GenericModal
          title="Costo de Factura Incorrecto"
          onCancel={() => setShowConfirmModal(false)}
          onContinue={registerInvoice}
        >
          <p className="label">
            El costo total de los productos es diferente al costo de la factura,
            ¿Desea continuar con el registro?
          </p>
        </GenericModal>
      )}
    </div>
  );
}
