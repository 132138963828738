/* eslint-disable no-unused-vars */
import { Button } from "@mui/material";
import { useAppContext } from "contexts/AppContext";
import { binaryStatus, getCategoryNameById } from "helpers/translators";
import React from "react";
import { Link } from "react-router-dom";
import "./ProductData.sass";
import EditIcon from "@mui/icons-material/Edit";

export default function ProductData({ product }) {
  const { user, pharmacy } = useAppContext();
  const [isParmacyAdmin] = React.useState(user?.role === "pharmacy_admin");
  const [isParmacyOwner] = React.useState(user?.role === "pharmacy_owner");

  // Variables
  const { categories } = useAppContext();

  // // Checar si el producto puede ser editado por el usuario
  // function checkEditProductAvailability() {
  //   // Si no es un admin de farmacia o un dueño de farmacia no puede
  //   if (!(isParmacyAdmin || isParmacyOwner)) return false;

  //   // Si el producto es público no puede
  //   if (product?.published) return false;

  //   // Si el producto no es de esta farmacia no puede
  //   if (product?.pharmacy_id !== pharmacy?.pharmacy_id) return false;

  //   // Si pasa las anteriores condiciones si puede
  //   return true;
  // }

  return (
    <div className="product_data">
      {/* Image */}
      <img src={product.photo_url || "/imgs/no-image.png"} alt="Producto" />

      {/* Info */}
      <div className="info">
        {/* Product Name */}
        <div className="title_container">
          <h1>{product.product_name}</h1>

          {/* Editar producto si es de la farmacia */}

          <Link to="/product-catalogue/edit" className="edit_btn">
            <Button variant="outlined" size="small" startIcon={<EditIcon />}>
              Editar Producto
            </Button>
          </Link>
        </div>

        {/* Short Fields */}
        <div className="short_fields">
          <div className="field">
            <p className="label">Código de Barras</p>
            <p className="value">{product.sku || "Sin información"}</p>
          </div>

          <div className="field">
            <p className="label">Laboratorio</p>
            <p className="value">{product.laboratory || "Sin información"}</p>
          </div>

          <div className="field">
            <p className="label">Categoría</p>
            <p className="value">
              {getCategoryNameById(product?.category_id, categories) ||
                "Sin información"}
            </p>
          </div>

          <div className="field">
            <p className="label">Gramaje</p>
            <p className="value">{product.grammage || "Sin información"}</p>
          </div>

          <div className="field">
            <p className="label">Tiene IVA</p>
            <p className="value">
              {binaryStatus(product.has_iva_charge) || "Sin información"}
            </p>
          </div>

          <div className="field">
            <p className="label">Requiere Preescripción</p>
            <p className="value">
              {binaryStatus(product.requires_prescription) || "Sin información"}
            </p>
          </div>
        </div>

        {/* Long Fields */}
        <div className="long_fields">
          <div className="field">
            <p className="label">Numero Sat</p>
            <p className="value">{product.sat_number || "Sin información"}</p>
          </div>
          <div className="field">
            <p className="label">Descripcion</p>
            <p className="value">
              {product.product_description || "Sin información"}
            </p>
          </div>
          <div className="field">
            <p className="label">Sales</p>
            <p className="value">{product.salt || "Sin información"}</p>
          </div>

          <div className="field">
            <p className="label">Advertencias</p>
            <p className="value">{product.warnings || "Sin información"}</p>
          </div>

          <div className="field">
            <p className="label">Dosis</p>
            <p className="value">{product.dose || "Sin información"}</p>
          </div>

          <div className="field">
            <p className="label">Contradicciones</p>
            <p className="value">
              {product.contradictions || "Sin información"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
