import React from "react";
import { Button, InputAdornment, TextField } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "components/Auth/AuthLayout";
import toast from "react-hot-toast";
import { checkResponse } from "helpers/requests";
import axios from "axios";

export default function RecoverPassword() {
  const [email, setEmail] = React.useState("");
  const [showPhoneViewAlert, setShowPhoneViewAlert] = React.useState(false);
  const navigate = useNavigate();

  async function sendRecoveryEmail(url, urlEndpoint) {
    try {
      await axios.post(url, {
        email,
        url_endpoint: urlEndpoint,
      });
      return true;
    } catch (error) {
      console.error(`SEND EMAIL ERROR: ${error.toString()}`);
      toast.error(
        "Se produjo un error al enviar el correo, intentalo de nuevo"
      );
      return false;
    }
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      const apiUrl = process.env.REACT_APP_API_URL;
      const sendMailUrl = `${apiUrl}/auth/recover/send-mail`;
      const generateLinkEndpontUrl = `${apiUrl}/auth/recover/generate-url-endpoint`;

      const request = await axios.post(generateLinkEndpontUrl, {
        email,
        user_type: "employee",
      });
      if (!checkResponse(request)) throw request;
      const urlEndpoint = request.data.body.endpoint;

      const success = await sendRecoveryEmail(sendMailUrl, urlEndpoint);

      if (success) {
        navigate(-1);
        toast.success(
          "Se envio un correo con el cual podrás recuperar tu contraseña"
        );
        return;
      }
      setEmail("");
    } catch (error) {
      console.error(`RESEST PWD ERROR: ${error}`);
      toast.error("Se produjo un error intentalo de nuevo");
    }
  }

  React.useEffect(() => {
    if (window.matchMedia("(max-width: 900px)").matches) {
      setShowPhoneViewAlert(true);
    }
  }, []);

  return (
    <AuthLayout>
      <div className="right_container">
        <form className="form" onSubmit={(e) => handleSubmit(e)}>
          <img src="imgs/logo.png" alt="" className="logo" />

          <h3>Recupera tu Contraseña</h3>

          <TextField
            fullWidth
            className="input"
            label="Correo Electrónico"
            required
            type="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon className="icon" />
                </InputAdornment>
              ),
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            className="btn"
            variant="contained"
            fullWidth
            size="large"
            type="submit"
          >
            Enviar Correo
          </Button>

          <Link to="/login" className="forgot_psw">
            <p>Ir a login</p>
          </Link>
        </form>
      </div>
      {/* Phone View Alert */}
      {showPhoneViewAlert && (
        <div className="phone_view_alert_container">
          <div className="alert">
            <h1>Vista Incorrecta</h1>
            <p>
              La app Mi Farmacia 360 fue diseñada para usarse en computadoras.
              Para una mejor experiencia cambia a un dispositivo con pantalla
              más amplia.
            </p>
          </div>
        </div>
      )}
    </AuthLayout>
  );
}
