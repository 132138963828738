import { React } from "react";
import "./ProductTable.sass";
import { IconButton } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useAppContext } from "contexts/AppContext";
import { useNavigate } from "react-router-dom";

export default function ProductTable({ products }) {
  // Variables
  const { setProductOnView } = useAppContext();

  // Tools
  const navigate = useNavigate();

  function getClassNameByQuantity(quantity) {
    if (quantity >= 20) {
      return "good";
    }
    if (quantity >= 10 && quantity <= 19) {
      return "moderate";
    }
    return "bad";
  }

  return (
    <table className="product_table">
      <thead className="product_header">
        <tr>
          <th scope="col">Imagen</th>
          <th scope="col name">Nombre</th>
          <th scope="col">Código de Barras</th>
          {/* Si es item de inventario */}
          {products && products[0]?.item_id ? (
            <>
              <th scope="col">Cantidad</th>
              <th scope="col">Precio</th>
            </>
          ) : (
            <>
              <th scope="col">Gramaje</th>
              <th scope="col">Laboratorio</th>
            </>
          )}
          <th scope="col">Detalles</th>
        </tr>
      </thead>
      <tbody id="products">
        {products &&
          products.map((product) => (
            <tr key={product.item_id ? product.item_id : product.product_id}>
              <td>
                <img
                  src={product.photo_url ?? "/imgs/no-image.png"}
                  alt="product"
                />
              </td>
              <td className="name">{product.product_name}</td>
              <td>{product.sku}</td>

              {/* Si es item de inventario */}
              {product.item_id ? (
                <>
                  <td className={getClassNameByQuantity(product.quantity)}>
                    {product.quantity}
                  </td>
                  <td>$ {product.price}</td>
                </>
              ) : (
                <>
                  <td>{product.grammage}</td>
                  <td id="product-lab">{product.laboratory}</td>
                </>
              )}

              <td className="details">
                <IconButton
                  variant="solid"
                  onClick={() => {
                    setProductOnView(product);
                    // If it's an inventory item go to item view
                    navigate(product.item_id ? "/item/view" : "/product/view");
                  }}
                >
                  <Visibility className="icon" />
                </IconButton>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
