import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import "./PageNotFound.sass";

export default function PageNotFound() {
  return (
    <div className="page_not_found">
      <div className="img_div">
        <img src="/imgs/404.png" alt="Sin resultados" />
        <p>No se encontró la página</p>
      </div>
      <NavLink to="/">
        <Button variant="contained">Ir a Inicio</Button>
      </NavLink>
    </div>
  );
}
