/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import "./EditCatalogueProduct.sass";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import PageContainer from "components/General/PageContainer/PageContainer";
import { getResponseError, validateInputs } from "helpers/requests";
import { useAppContext } from "contexts/AppContext";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import fileService from "services/fileService";
import editProductData from "./actionsEditProduct";

export default function EditCatalogueProduct() {
  // Variables
  const {
    user,
    categories,
    productOnView: product,
    userToken,
    setProductOnView,
    pharmacy,
  } = useAppContext();
  const [isNabuAdmin] = React.useState(user?.role === "nabu");
  const [isAnafarmexAdmin] = React.useState(user?.role === "anafarmex");

  // Input Values
  const [file, setFile] = React.useState(null);
  const [photoUrl] = React.useState(product.photo_url);
  const [productName, setProductName] = React.useState(
    product.product_name || ""
  );
  const [laboratory, setLaboratory] = React.useState(product.laboratory || "");
  const [grammage, setGrammage] = React.useState(product.grammage || "");
  const [category, setCategory] = React.useState(product.category_id);
  const [hasIVA, setHasIVA] = React.useState(product.has_iva_charge || 0);
  const [description, setDescription] = React.useState(
    product.product_description || ""
  );
  const [salts, setSalts] = React.useState(product.salt || "");
  const [reqPres, setReqPres] = React.useState(
    product.requires_prescription || 0
  );
  const [satNum, setSatNum] = React.useState(product.sat_number || "");
  const [warnings, setWarnings] = React.useState(product.warnings || "");
  const [dose, setDose] = React.useState(product.dose || "");
  const [contradictions, setContradictions] = React.useState(
    product.contradictions || ""
  );
  // eslint-disable-next-line no-unused-vars
  const [isPublic, setPublic] = React.useState(product.published || false);
  const navigate = useNavigate();

  // Render produuct img
  function renderProductImg(photo_url) {
    if (file) return URL.createObjectURL(file);
    if (photo_url) return photo_url;
    return "imgs/no-image.png";
  }

  // Add product
  async function addProduct() {
    try {
      // Validate fields
      if (!validateInputs([productName, grammage, hasIVA, category, reqPres])) {
        toast.error(
          `Los campos: 
          Nombre de Producto, Descripción, Laboratorio,
          Gramaje, Tiene IVA, Categoría, Requiere Prescripción
          no pueden estar vacíos`
        );
        return;
      }

      // If there is a selected img in the input file, upload it to s3
      let new_photo_url = null;
      if (file) {
        new_photo_url = await fileService.uploadFile(
          file,
          pharmacy?.pharmacy_id,
          productName.replace(/\s+/g, "_").toLowerCase(),
          "product-images"
        );
        if (!new_photo_url) toast.error("Hubo un error subiendo la imágen");
      }

      // Update product data
      const body = {
        product_name: productName,
        product_description: description,
        photo_url: new_photo_url || photoUrl,
        laboratory,
        grammage,
        salt: salts.trim() ? salts : null,
        has_iva_charge: hasIVA,
        category_id: category,
        requires_prescription: reqPres,
        warnings: warnings.trim() ? warnings : null,
        dose: dose.trim() ? dose : null,
        contradictions: contradictions.trim() ? contradictions : null,
        sat_number: satNum === "" ? null : satNum,
        published: isPublic,
      };

      // Check for errors
      const resData = await editProductData(
        product.product_id,
        body,
        userToken
      );
      if (!resData.success) {
        toast.error(resData.msg);
        return;
      }

      // Update context
      setProductOnView({ ...product, ...body }); // Update product on view

      // Success
      toast.success("Se guardo la información correctamente");
      navigate("/product/view");

      // If photo was updated, delete old photo
      if (new_photo_url && product?.photo_url)
        fileService.deleteFile(product?.photo_url);
    } catch (res) {
      console.log("EDIT CATALOGUE PRODUCT ERROR:", res);
      toast.error(getResponseError(res), "Hubo un error editando el producto");
    }
  }

  return (
    <PageContainer>
      <div className="edit_catalogue_product">
        {/* Go Back Btn */}
        <BackPageBtn />

        {/* Título */}
        <h1>Editar Producto</h1>

        {/* Formularios */}
        <div className="forms">
          {/* Formulario de Imágen */}
          <form className="img_form">
            <Button
              variant="outlined"
              onClick={() => {
                const imgInput = document.querySelector(".img_input");
                imgInput.click();
              }}
            >
              Seleccionar Imágen
            </Button>

            <input
              type="file"
              className="img_input"
              accept="image/*"
              onChange={(e) => setFile(e.target.files[0])}
            />

            <div className="img_container">
              <img src={renderProductImg(product.photo_url)} alt="Producto" />
            </div>
          </form>

          {/* Formulario de Datos */}
          <form className="data_form">
            <TextField
              label="Nombre de Producto"
              variant="outlined"
              className="text_input"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />

            <TextField
              label="Laboratorio"
              variant="outlined"
              className="text_input"
              value={laboratory}
              onChange={(e) => setLaboratory(e.target.value)}
            />

            <TextField
              label="Gramaje"
              variant="outlined"
              className="text_input"
              value={grammage}
              onChange={(e) => setGrammage(e.target.value)}
            />

            <FormControl className="select_input">
              <InputLabel id="category-label">Categoría</InputLabel>
              <Select
                labelId="category-label"
                label="Categoría"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {categories.map((c) => (
                  <MenuItem value={c.category_id} key={c.category_id}>
                    {c.category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className="select_input">
              <InputLabel id="iva-label">Tiene Iva</InputLabel>
              <Select
                labelId="iva-label"
                label="Tiene IVA"
                value={hasIVA}
                onChange={(e) => setHasIVA(e.target.value)}
              >
                <MenuItem value={1}>Si</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>

            <TextField
              className="text_input"
              label="Numero Sat"
              variant="outlined"
              value={satNum}
              onChange={(e) => setSatNum(e.target.value)}
            />

            <TextField
              className="text_area"
              label="Descripción del Producto"
              multiline
              rows={3}
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <TextField
              label="Sales"
              variant="outlined"
              type="text"
              className="text_input"
              value={salts}
              onChange={(e) => setSalts(e.target.value)}
            />

            <FormControl className="select_input">
              <InputLabel id="req-pres">Requiere Prescripción</InputLabel>
              <Select
                labelId="req-pres"
                label="Requiere Prescripción"
                value={reqPres}
                onChange={(e) => setReqPres(e.target.value)}
              >
                <MenuItem value={1}>Si</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>

            <TextField
              className="text_area"
              label="Advertencias"
              multiline
              rows={3}
              variant="outlined"
              value={warnings}
              onChange={(e) => setWarnings(e.target.value)}
            />

            <TextField
              className="text_area"
              label="Dosis"
              multiline
              rows={3}
              variant="outlined"
              value={dose}
              onChange={(e) => setDose(e.target.value)}
            />

            <TextField
              className="text_area"
              label="Contraindicaciones"
              multiline
              rows={3}
              variant="outlined"
              value={contradictions}
              onChange={(e) => setContradictions(e.target.value)}
            />

            {(isNabuAdmin || isAnafarmexAdmin) && (
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    value="first_checkbox"
                    checked={isPublic}
                    onChange={() => setPublic(!isPublic)}
                  />{" "}
                  Publicar en catalogo
                </label>
              </div>
            )}

            <Button
              variant="contained"
              size="large"
              onClick={addProduct}
              className="add_btn"
            >
              Guardar Datos
            </Button>
          </form>
        </div>
      </div>
    </PageContainer>
  );
}
