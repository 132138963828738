import "./LoadingTable.sass";
import React from "react";
import shortid from "shortid";
import Skeleton from "@mui/material/Skeleton";

export default function LoadingTable({ nCols }) {
  const cols = new Array(nCols).fill("");

  return (
    <table className="loading">
      <thead className="loading-header">
        <tr>
          {cols.map(() => (
            <th scope="col" key={shortid.generate()}>
              <Skeleton variant="text" animation="wave" width={100} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody id="loading-body">
        <tr>
          {cols.map(() => (
            <td key={shortid.generate()}>
              <Skeleton variant="text" animation="wave" width={100} />
            </td>
          ))}
        </tr>
        <tr>
          {cols.map(() => (
            <td key={shortid.generate()}>
              <Skeleton variant="text" animation="wave" width={100} />
            </td>
          ))}
        </tr>
        <tr>
          {cols.map(() => (
            <td key={shortid.generate()}>
              <Skeleton variant="text" animation="wave" width={100} />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
