import React, { useState, useEffect } from "react";
import "./Navbar.sass";
import { NavLink } from "react-router-dom";
import { useAppContext } from "contexts/AppContext";
import authRoutes from "router/routes/authRoutes";

export default function Navbar() {
  // Variables
  const { user, pharmacy } = useAppContext();
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    if (user.role === "nabu") {
      setImgUrl("/imgs/nabu.jpeg");
      return;
    }
    if (user.role === "anafarmex") {
      setImgUrl("/imgs/pascual.jpg");
      return;
    }

    setImgUrl(pharmacy?.logo_url);
  }, [pharmacy]);
  return (
    <div className="navbar">
      {/* Pharmacy Img */}
      <img
        className="pharmacy_logo"
        src={imgUrl || "/imgs/no_img.png"}
        alt="Logo de Farmacia"
      />

      {/* Links */}
      <div className="links">
        {authRoutes.map(
          (route) =>
            route.navbarLink && // If route is a navbar route
            route.allowedRoles.find((role) => role === user.role) && ( // If it allows the user role
              <NavLink key={route.path} to={`/${route.path}`} className="link">
                {route.navbarIcon}
                <p>{route.navbarLink}</p>
              </NavLink>
            )
        )}
      </div>
    </div>
  );
}
