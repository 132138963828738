import "./RegisterInventoryInvoice.sass";
import React from "react";
import AddProducts from "components/Inventory/RegisterInvoice/AddProducts/AddProducts";
import ViewProducts from "components/Inventory/RegisterInvoice/ViewProducts/ViewProducts";
import InvoiceForm from "components/Inventory/RegisterInvoice/InvoiceForm/InvoiceForm";
import toast from "react-hot-toast";
import { checkResponse, getResponseError } from "helpers/requests";
import { useAppContext } from "contexts/AppContext";
import axios from "axios";

export default function RegisterInventoryInvoice() {
  // Varibales
  const { userToken, pharmacy } = useAppContext();
  const [loading, setLoading] = React.useState(true);
  const [providers, setProviders] = React.useState(null);
  const [products, setProducts] = React.useState(() => {
    const stringArray = localStorage.getItem("MF360-Invoice-Products");
    if (!stringArray) {
      return [];
    }
    return JSON.parse(stringArray);
  });

  // Invoice inputs
  const [invoiceProvider, setInvoiceProvider] = React.useState("");
  const [invoiceTotalCost, setInvoiceTotalCost] = React.useState("");
  const [invoiceDate, setInvoiceDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );

  // Get providers
  async function getProviders() {
    try {
      // Make request
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/providers`;
      const headers = { authToken: userToken };
      const params = { pharmacy_id: pharmacy.pharmacy_id };
      const res = await axios.get(url, { headers, params });

      // Check respose
      if (!checkResponse(res)) throw res;

      // Set state
      setProviders(res?.data?.body?.providers);
      setLoading(false);
    } catch (error) {
      console.log("GET PROVIDERS ERROR", error);
      toast.error(
        getResponseError(error, "Hubo un error obteniendo los proveedores")
      );
    }
  }

  // On render
  React.useEffect(() => {
    getProviders();
  }, []);

  return (
    !loading && (
      <div className="register-products">
        {/* Top */}
        <div className="invoice_top">
          <InvoiceForm
            providers={providers}
            invoiceProvider={invoiceProvider}
            setInvoiceProvider={setInvoiceProvider}
            invoiceTotalCost={invoiceTotalCost}
            setInvoiceTotalCost={setInvoiceTotalCost}
            invoiceDate={invoiceDate}
            setInvoiceDate={setInvoiceDate}
          />
        </div>

        {/* Bottom */}
        <div className="invoice_bottom">
          <AddProducts products={products} setProducts={setProducts} />
          <ViewProducts
            products={products}
            setProducts={setProducts}
            invoiceProvider={invoiceProvider}
            setInvoiceProvider={setInvoiceProvider}
            invoiceTotalCost={invoiceTotalCost}
            setInvoiceTotalCost={setInvoiceTotalCost}
            invoiceDate={invoiceDate}
          />
        </div>
      </div>
    )
  );
}
