import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { CHART_COLORS } from "helpers/charts";
import "./BarsGraph.sass";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BarsGraph({ title, chartLabels, chartData, width }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        backgroundColor: chartData?.map(
          (item, i) => CHART_COLORS[i % CHART_COLORS.length]
        ),
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="graph_container bars_graph" style={{ width: `${width}%` }}>
      <h1>{title}</h1>

      <div className="graph_div">
        <Bar data={data} options={options} width="400px" height="350px" />
      </div>
    </div>
  );
}
