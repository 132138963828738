import axios from "axios";
import LoadingComponent from "components/General/LoadingComponent/LoadingComponent";
import SalesTable from "components/Sales/SalesTable";
import { useAppContext } from "contexts/AppContext";
import { formatDate } from "helpers/date_formaters";
import { checkResponse, getResponseError } from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import BarsGraph from "../GraphContainers/BarsGraph/BarsGraph";
import LineGraph from "../GraphContainers/LineGraph/LineGraph";
import PieGraph from "../GraphContainers/PieGraph/PieGraph";
import ReturnedProductsTable from "./ReturnedProductsTable/ReturnedProductsTable";
import "./SalesSection.sass";

export default function SalesSection({ dateRange }) {
  // VARIABLES DE USO
  const { pharmacy, userToken } = useAppContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [salesByTimeLabels, setSalesByTimeLabels] = React.useState(null);
  const [salesByTimeValues, setSalesByTimeValues] = React.useState(null);
  const [salesByEmployeeValues, setSalesByEmployeeValues] =
    React.useState(null);
  const [salesByEmployeeLabels, setSalesByEmployeeLabels] =
    React.useState(null);

  async function getSalesByTime() {
    try {
      const config = {
        headers: {
          authToken: userToken,
        },
      };
      const today = formatDate(new Date());
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/reports/sales/time/${pharmacy.pharmacy_id}?date_range=${dateRange}&today=${today}`;
      const res = await axios.get(url, config);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;
      setSalesByTimeValues(res.data.body.data.values);
      setSalesByTimeLabels(res.data.body.data.labels);
      setIsLoading(false);
    } catch (res) {
      console.log("GET SALES ERROR:", res);
      toast.error(getResponseError(res));
    }
  }

  async function getSalesByEmployee() {
    try {
      const config = {
        headers: {
          authToken: userToken,
        },
      };
      const today = formatDate(new Date());
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/reports/employees/${pharmacy.pharmacy_id}?date_range=${dateRange}&today=${today}`;
      const res = await axios.get(url, config);
      // Levantar errrores en caso de que los haya
      if (!checkResponse(res)) throw res;
      setSalesByEmployeeValues(res.data.body.data.values);
      setSalesByEmployeeLabels(res.data.body.data.labels);
      setIsLoading(false);
    } catch (res) {
      console.log("GET SALES ERROR:", res);
      toast.error(getResponseError(res));
    }
  }

  React.useEffect(() => {
    getSalesByTime();
    getSalesByEmployee();
  }, [dateRange]);

  return (
    <div className="section">
      {/* Graphs */}
      {isLoading ? (
        <LoadingComponent />
      ) : (
        // Graficas de ventas
        <>
          <div className="flex">
            <SalesByTimeGraph
              dateRange={dateRange}
              salesByTimeLabels={salesByTimeLabels}
              salesByTimeValues={salesByTimeValues}
            />
            <PieGraph
              title="Ventas por Empleado"
              chartData={salesByEmployeeValues}
              chartLabels={salesByEmployeeLabels}
              width={45}
            />
          </div>

          {/*  Tabla de ventas */}
          <SalesTable />

          {/* Productos Devueltos */}
          <ReturnedProductsTable />
        </>
      )}
    </div>
  );
}

function SalesByTimeGraph({ dateRange, salesByTimeValues, salesByTimeLabels }) {
  if (dateRange === "weekly" || dateRange === "yearly") {
    return (
      <BarsGraph
        id="chart"
        title="Número de Ventas por Tiempo"
        chartData={salesByTimeValues}
        chartLabels={salesByTimeLabels}
        width={45}
      />
    );
  }

  return (
    <LineGraph
      id="chart"
      title="Número de Ventas por Tiempo"
      chartData={salesByTimeValues}
      chartLabels={salesByTimeLabels}
      width={45}
    />
  );
}
