import React from "react";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import "./ProductView.sass";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import ProductData from "components/ProductCatalogue/ProductData/ProductData";
import ItemData from "components/ProductCatalogue/ItemData/ItemData";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import { scrollPageContainer } from "helpers/page";
import { Link } from "react-router-dom";
import { getResponseError } from "helpers/requests";
import toast from "react-hot-toast";

export default function ProductView() {
  // Variables
  const { pharmacy, productOnView, setProductOnView, user } = useAppContext();
  const [itemData, setItemData] = React.useState("loading");

  // User roles
  const [isPharmacyAdmin] = React.useState(user.role === "pharmacy_admin");
  const [isAdmin] = React.useState(
    user.role === "anafarmex" || user.role === "nabu"
  );
  const [isEmployee] = React.useState(user.role === "employee");
  const [isPharmacyOwner] = React.useState(user.role === "pharmacy_owner");

  // Get item data
  async function getItemData() {
    try {
      // Make request
      const params = {
        pharmacy_id: pharmacy.pharmacy_id,
        product_id: productOnView.product_id,
      };
      const url =
        "https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/inventory/item";
      const res = await axios.get(url, { params });

      // Set itemData
      const item = res?.data?.body?.item;
      setItemData(item || null);

      // Mix item data with product data
      if (item) {
        setProductOnView({ ...productOnView, ...item });
      }

      // Mix item data with product on view
    } catch (res) {
      console.log("GET ITEM DATA ERROR:", res);
      toast.error(
        getResponseError(
          res,
          "Hubo un error obteniendo la información del item"
        )
      );
    }
  }

  // On render
  React.useEffect(() => {
    scrollPageContainer();
    if (!isAdmin) {
      getItemData();
    }
  }, []);

  return (
    <PageContainer>
      <div id="top" className="product_view">
        {/* Go Back Btn */}
        <BackPageBtn />

        {/* Item Data */}
        {(() => {
          // Si es anafarmex mostrar botón de editar producto
          if (isAdmin) {
            return (
              <div className="add_btn_container">
                <Link to="/product-catalogue/edit">
                  <Button variant="outlined" startIcon={<EditIcon />}>
                    Editar Producto
                  </Button>
                </Link>
              </div>
            );
          }
          // Si es admin de farmacia o de empleado mostrar data de item
          if (isPharmacyOwner || isPharmacyAdmin || isEmployee) {
            if (itemData === "loading") return <div />;
            if (itemData) return <ItemData item={itemData} />;
            if (!itemData)
              return (
                <div className="add_btn_container">
                  <Link to="/product/add-to-inventory">
                    <Button variant="contained" startIcon={<AddIcon />}>
                      Agregar a Inventario
                    </Button>
                  </Link>
                </div>
              );
          }
          return <div />;
        })()}
        {/* Product Data */}
        <ProductData product={productOnView} />
      </div>
    </PageContainer>
  );
}
