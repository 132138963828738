import { Button, IconButton } from "@mui/material";
import PageContainer from "components/General/PageContainer/PageContainer";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React from "react";
import "./ProvidersView.sass";
import { Link } from "react-router-dom";
import LoadingComponent from "components/General/LoadingComponent/LoadingComponent";
import toast from "react-hot-toast";
import { checkResponse, getResponseError } from "helpers/requests";
import { useAppContext } from "contexts/AppContext";
import axios from "axios";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";

export default function ProvidersView() {
  // Variables
  const { pharmacy, user, userToken } = useAppContext();
  const [providers, setProviders] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [isAdmin] = React.useState(
    user.role === "nabu" || user.role === "anafarmex"
  );

  // Get providers
  async function getProviders() {
    try {
      // Make request
      const headers = { authToken: userToken };
      const params = {};
      if (!isAdmin) params.pharmacy_id = pharmacy.pharmacy_id;
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/providers`;
      const res = await axios.get(url, { headers, params });

      // Check response
      if (!checkResponse(res)) throw res;

      // Set providers
      setProviders(res?.data?.body?.providers);
      setLoading(false);
    } catch (error) {
      console.log("GET PROVIDERS ERROR: ", error);
      toast.error(
        getResponseError(error, "Hubo un error obteniendo los proveedores")
      );
    }
  }

  // Get providers
  React.useEffect(() => {
    getProviders();
  }, []);

  return (
    <PageContainer fitWidth>
      <BackPageBtn noMargin />
      <div className="providers_view">
        {/* Add button */}
        <div className="add_btn_container">
          <Link to="/providers/add">
            <Button variant="outlined" startIcon={<AddIcon />} size="small">
              Añadir
            </Button>
          </Link>
        </div>

        {/* List of providers */}
        {!loading ? (
          <div className="list_providers">
            <div className="headers">
              <p className="c1">Proveedor</p>
              <p className="c2">Ver</p>
            </div>

            {/* Map categories */}
            {providers.map((p) => (
              <div className="item" key={p.provider_id}>
                <p className="c1">{p.name || "Sin nombre"}</p>

                <div className="c2">
                  <IconButton variant="solid">
                    <VisibilityIcon className="icon" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="providers_loading">
            <LoadingComponent />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
