import React from "react";
import "./GoToItemDetailsPageBtn.sass";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

export default function GoToItemDetailsPageBtn({ item, path }) {
  return (
    <Link to={path}>
      <button type="button" className="go_to_btn">
        Ver {item}
        <ArrowForwardIcon className="icon" />
      </button>
    </Link>
  );
}
