// Routes
import PageLayout from "components/General/PageLayout/PageLayout";
import AddressForm from "pages/Adresses/AddressForm/AddressForm";
import AddressView from "pages/Adresses/AddressView/AddressView";
import ChangePassword from "pages/Auth/ChangePassword/ChangePassword";
import EditMyProfile from "pages/Auth/EditMyProfile/EditMyProfile";
import MyProfile from "pages/Auth/MyProfile/MyProfile";
import ItemView from "pages/Catalogue/ItemView/ItemView";
import ProductCatalogue from "pages/Catalogue/ProductCatalogue/ProductCatalogue";
import ProductView from "pages/Catalogue/ProductView/ProductView";
import Inventory from "pages/Inventory/InventoryPage/Inventory";
import RegisterInventoryInvoice from "pages/Inventory/RegisterInventoryInvoice/RegisterInventoryInvoice";
import OrderDetails from "pages/Orders/OrderDetails/OrderDetails";
import OrdersPage from "pages/Orders/OrdersPage/OrdersPage";
import CashWithdrawal from "pages/pos/ChashRegister/CashWithdrawal/CashWithdrawal";
import ChashRegister from "pages/pos/ChashRegister/ChashRegister";
import CloseCashRegister from "pages/pos/CloseCashRegister/CloseCashRegister";
import AddProductToCatalogue from "pages/Catalogue/AddProductToCatalogue/AddProductToCatalogue";
import EditCatalogueProduct from "pages/Catalogue/EditCatalogueProduct/EditCatalogueProduct";
import ProductCategories from "pages/Catalogue/ProductCategories/ProductCategories";
import EmployeePage from "pages/Employees/EmployeePage/EmployeePage";
import EmployeeDetails from "pages/Employees/EmployeeDetails/EmployeeDetails";
import MyPharmacy from "pages/Pharmacy/MyPharmacy/MyPharmacy";
import EditMyPharmacy from "pages/Pharmacy/EditMyPharmacy/EditMyPharmacy";
import CreateEmployee from "pages/Employees/CreateEmployee/CreateEmployee";
import Graphs from "pages/Graphs/Graphs";
import CashRegisters from "pages/pos/CashRegisters/CashRegisters";
import ClosureRequests from "pages/pos/CashRegisters/ClosureRequests/ClosureRequests";
import ClosureRequestView from "pages/pos/CashRegisters/ClosureRequestView/ClosureRequestView";
import RegisterDifference from "pages/pos/CashRegisters/RegisterDifference/RegisterDifference";
import AddProductToInventory from "pages/Catalogue/AddProductToInventory/AddProductToInventory";
import EditInventoryItem from "pages/Catalogue/EditInventoryItem/EditInventoryItem";
import ProvidersView from "pages/Providers/ProvidersView/ProvidersView";
import AccountStatement from "pages/Pharmacy/AccountStatement/AccountStatement";
import TermsAndConditions from "components/NewUser/TermsAndConditions/TermsAndConditions";
import PharmacyAdministration from "pages/PharmaciesAdmin/PharmacyAdministration/PharmacyAdministration";
import RegisterPharmacy from "pages/PharmaciesAdmin/RegisterPharmacy/RegisterPharmacy";
import ReturnProducts from "pages/pos/ReturnProducts/ReturnProducts";
import PhysicalCashRegisterView from "components/CashRegister/PhysicalCashRegisterView/PhysicalCashRegisterView";

// Navbar Icons
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import BallotIcon from "@mui/icons-material/Ballot";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MedicationIcon from "@mui/icons-material/Medication";
import StoreIcon from "@mui/icons-material/Store";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddProvider from "pages/Providers/AddProvider/AddProvider";
import PaymentScreen from "pages/Pharmacy/payments/PaymentScreen";
import { Storefront } from "@mui/icons-material";

const routes = [
  // ____________________ Mi Farmacia ____________________
  {
    path: "my-pharmacy",
    element: <PageLayout title="Mi Farmacia" Page={MyPharmacy} />,
    allowedRoles: ["pharmacy_owner", "employee"],
    navbarLink: "Mi Farmacia",
    navbarIcon: <StoreIcon className="icon" />,
  },
  {
    path: "my-pharmacy/edit",
    element: <PageLayout title="Mi Farmacia" Page={EditMyPharmacy} />,
    allowedRoles: ["pharmacy_owner"],
  },
  {
    path: "my-pharmacy/account-statement",
    element: <PageLayout title="Mi Farmacia" Page={AccountStatement} />,
    allowedRoles: ["pharmacy_owner"],
  },
  {
    path: "my-pharmacy/account-statement/payments/new",
    element: <PageLayout title="Realizar un pago" Page={PaymentScreen} />,
    allowedRoles: ["pharmacy_owner"],
  },

  // ____________________ Mi Perfil ____________________
  {
    path: "my-profile",
    element: <PageLayout title="Mi Perfil" Page={MyProfile} />,
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
    navbarLink: "Mi Perfil",
    navbarIcon: <AccountCircleIcon className="icon" />,
  },
  {
    path: "my-profile/edit",
    element: <PageLayout title="Mi Perfil" Page={EditMyProfile} />,
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
  },
  {
    path: "my-profile/change-password",
    element: <PageLayout title="Mi Perfil" Page={ChangePassword} />,
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
  },
  {
    path: "terminos-y-condiciones",
    element: (
      <PageLayout title="Términos y Condiciones" Page={TermsAndConditions} />
    ),
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
  },

  // ____________________ Caja Registradora ____________________
  {
    path: "cash-register",
    element: <PageLayout title="Caja Registradora" Page={ChashRegister} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
    navbarLink: "Caja Registradora",
    navbarIcon: <PointOfSaleIcon className="icon" />,
  },
  {
    path: "cash-register/view",
    element: (
      <PageLayout
        title="Detalles de Caja Registradora"
        Page={PhysicalCashRegisterView}
      />
    ),
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
  },
  {
    path: "cash-register/close",
    element: <PageLayout title="Caja Registradora" Page={CloseCashRegister} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
  },
  {
    path: "cash-register/withdrawal",
    element: <PageLayout title="Caja Registradora" Page={CashWithdrawal} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
  },
  {
    path: "cash-registers",
    element: <PageLayout title="Cajas Registradoras" Page={CashRegisters} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin"],
    navbarLink: "Cajas Registradoras",
    navbarIcon: <RequestQuoteIcon className="icon" />,
  },
  {
    path: "cash-registers/closure-requests",
    element: <PageLayout title="Cajas Registradoras" Page={ClosureRequests} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin"],
  },
  {
    path: "cash-registers/closure-requests/view",
    element: (
      <PageLayout title="Cajas Registradoras" Page={ClosureRequestView} />
    ),
    allowedRoles: ["pharmacy_owner", "pharmacy_admin"],
  },
  {
    path: "cash-registers/closure-requests/difference",
    element: (
      <PageLayout title="Cajas Registradoras" Page={RegisterDifference} />
    ),
    allowedRoles: ["pharmacy_owner", "pharmacy_admin"],
  },
  {
    path: "cash-register/return-products",
    element: <PageLayout title="Consultar Venta" Page={ReturnProducts} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
  },

  // ____________________ Inventario ____________________
  {
    path: "inventory",
    element: <PageLayout title="Mi Inventario" Page={Inventory} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
    navbarLink: "Inventario",
    navbarIcon: <BallotIcon className="icon" />,
  },
  {
    path: "item/view",
    element: <PageLayout title="Detalles de Producto" Page={ItemView} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
  },
  {
    path: "inventory/register",
    element: (
      <PageLayout title="Alta de Inventario" Page={RegisterInventoryInvoice} />
    ),
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
  },
  {
    path: "product/add-to-inventory",
    element: (
      <PageLayout title="Añadir a Inventario" Page={AddProductToInventory} />
    ),
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
  },
  {
    path: "product/edit",
    element: <PageLayout title="Editar Producto" Page={EditInventoryItem} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
  },
  {
    path: "providers",
    element: <PageLayout title="Proveedores" Page={ProvidersView} />,
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
  },
  {
    path: "providers/add",
    element: <PageLayout title="Proveedores" Page={AddProvider} />,
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
  },

  // ____________________ Catálogo de Productos ____________________
  {
    path: "product-catalogue",
    element: <PageLayout title="Catálogo General" Page={ProductCatalogue} />,
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
    navbarLink: "Catálogo Productos",
    navbarIcon: <MedicationIcon className="icon" />,
  },
  {
    path: "product/view",
    element: <PageLayout title="Detalles de Producto" Page={ProductView} />,
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
  },
  {
    path: "product-catalogue/add-new",
    element: (
      <PageLayout title="Catálogo General" Page={AddProductToCatalogue} />
    ),
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
  },
  {
    path: "product-catalogue/edit",
    element: (
      <PageLayout title="Catálogo General" Page={EditCatalogueProduct} />
    ),
    allowedRoles: ["nabu", "anafarmex", "pharmacy_owner", "pharmacy_admin"],
  },
  {
    path: "product-categories",
    element: (
      <PageLayout title="Categorías de Producto" Page={ProductCategories} />
    ),
    allowedRoles: ["nabu", "anafarmex"],
  },

  // ____________________ Ordenes ____________________
  {
    path: "orders",
    element: <PageLayout title="Pedidos" Page={OrdersPage} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
    navbarLink: "Ordenes",
    navbarIcon: <ShoppingBasketIcon className="icon" />,
  },

  {
    path: "orders/order-details",
    element: <PageLayout title="Pedido" Page={OrderDetails} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin", "employee"],
  },

  // ____________________ Direcciones ____________________
  {
    path: "address/:type/:id",
    element: <PageLayout title="Dirección" Page={AddressView} />,
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
  },
  {
    path: "address/:type/:id/:action",
    element: <PageLayout title="Dirección" Page={AddressForm} />,
    allowedRoles: [
      "nabu",
      "anafarmex",
      "pharmacy_owner",
      "pharmacy_admin",
      "employee",
    ],
  },

  // ____________________ Empleados ____________________
  {
    path: "employees",
    element: <PageLayout title="Administrar empleados" Page={EmployeePage} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin"],
    navbarLink: "Empleados",
    navbarIcon: <PeopleAltIcon className="icon" />,
  },
  {
    path: "employees/employee-details",
    element: <PageLayout title="Detalles de empleado" Page={EmployeeDetails} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin"],
  },
  {
    path: "employees/create",
    element: <PageLayout title="Registrar un empleado" Page={CreateEmployee} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin"],
  },

  // ____________________ Gráficas ____________________
  {
    path: "graphs",
    element: <PageLayout title="Reportes y Gráficas" Page={Graphs} />,
    allowedRoles: ["pharmacy_owner", "pharmacy_admin"],
    navbarLink: "Reportes",
    navbarIcon: <AssessmentIcon className="icon" />,
  },
  {
    path: "farmacias",
    element: (
      <PageLayout
        title="Administración de Farmacias"
        Page={PharmacyAdministration}
      />
    ),
    allowedRoles: ["anafarmex", "nabu"],
    navbarLink: "Farmacias",
    navbarIcon: <Storefront className="icon" />,
  },
  {
    path: "farmacias/nueva",
    element: <PageLayout title="Crear" Page={RegisterPharmacy} />,
    allowedRoles: ["nabu"],
  },
];

export default routes;
