import "./EmployeeTable.sass";
import { IconButton } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "contexts/AppContext";
import { translateEmployeeStatus, translateRole } from "helpers/translators";

export default function EmployeeTable({ employees }) {
  const navigate = useNavigate();
  const { setUserOnView } = useAppContext();

  const handleNavigate = (employee) => {
    // ir a pantalla de detalles y mandarle el usuario
    setUserOnView(employee);
    navigate("/employees/employee-details");
  };

  return (
    <table className="employee-table">
      <thead className="employees_header">
        <tr>
          <th scope="col">Empleado</th>
          <th scope="col">Email</th>
          <th scope="col">Rol</th>
          <th scope="col">Estado</th>
          <th scope="col">Detalles</th>
        </tr>
      </thead>
      <tbody id="employees">
        {employees.map((employee) => (
          <tr key={employee.employee_id}>
            <td className="person">
              <img
                src={
                  employee.employee_photo
                    ? employee.employee_photo
                    : "/imgs/no_user.jpg"
                }
                alt="person"
              />
              <p>
                {employee.name} {employee.last_name}
              </p>
            </td>
            <td>{employee.mail}</td>
            <td>{translateRole(employee.role)}</td>
            <td>{translateEmployeeStatus(employee.status)}</td>
            <td>
              <IconButton
                variant="solid"
                onClick={() => handleNavigate(employee)}
              >
                <Visibility className="icon" />
              </IconButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
