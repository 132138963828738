import axios from "axios";
import ChangePageBtn from "components/General/ChangePageBtn/ChangePageBtn";
import { useAppContext } from "contexts/AppContext";
import { checkResponse } from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import "./ReturnedProductsTable.sass";

export default function ReturnedProductsTable() {
  // Variables
  const { pharmacy, userToken } = useAppContext();
  const [returnedProducts, setReturnedProducts] = React.useState([]);
  const [page, setPage] = React.useState(1);

  async function getReturnedProducts() {
    try {
      // Hacer petición
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/orders/pharmacy/${pharmacy?.pharmacy_id}/returned-products`;
      const params = { page };
      const headers = { authToken: userToken };
      const res = await axios.get(url, { params, headers });

      // Checar respuesta
      if (!checkResponse(res)) throw res;

      setReturnedProducts(res?.data?.body?.orders);
    } catch (error) {
      console.log("GET RETURNED PRODUCTS ERROR:", error);
      toast.error("Hubo un error obteniendo los productos devueltos");
    }
  }

  React.useEffect(() => {
    getReturnedProducts();
  }, [page]);

  return (
    <div className="returned_products_table">
      <h2>Devoluciones</h2>

      <table className="table">
        <thead>
          <tr>
            <th>Producto</th>
            <th>Ticket</th>
            <th>Precio</th>
            <th>Fecha de Compra</th>
            <th>Fecha de Devolución</th>
          </tr>
        </thead>

        <tbody>
          {returnedProducts.map((p) => (
            <tr key={p?.ordered_item_id}>
              <td>{p?.product_name}</td>
              <td>{p?.order_id}</td>
              <td>${p?.price}</td>
              <td>{p?.ordered_date}</td>
              <td>{p?.returned_date}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <ChangePageBtn
        page={page}
        setPage={setPage}
        data={returnedProducts}
        isArray={false}
        defaultBehaviour={false}
      />
    </div>
  );
}
