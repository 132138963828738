import { Button } from "@mui/material";
import BackPageBtn from "components/General/BackPageBtn/BackPageBtn";
import GenericModal from "components/General/GenericModal/GenericModal";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./PhysicalCashRegisterView.sass";

export default function PhysicalCashRegisterView() {
  const { physicalCashRegister, setPhysicalCashRegister } = useAppContext();
  const [showConfirmChangeModal, setShowConfirmChangeModal] =
    React.useState(false);
  const navigate = useNavigate();

  function cleanPhysicalCashRegister() {
    // Remove current pcr from localstorage
    localStorage.removeItem("mf360-pcr");

    // Go to my profile
    setShowConfirmChangeModal(false);
    setPhysicalCashRegister(null);
    navigate("/my-profile");
  }

  return (
    <PageContainer fitWidth>
      <div className="pcr_view">
        <BackPageBtn noMargin path="/cash-register" />
        <h1>Detalles de Caja Registradora</h1>

        <div className="details">
          <p className="detail">
            <span>Nombre:</span> {physicalCashRegister?.name}
          </p>
          <p className="detail">
            <span>Tipo de Conexión:</span>
            {physicalCashRegister?.printer_type?.toUpperCase()}
          </p>
        </div>

        <div className="btn_container">
          <Button
            color="error"
            variant="outlined"
            onClick={() => setShowConfirmChangeModal(true)}
          >
            Cambiar Caja
          </Button>
        </div>
      </div>

      {/* Confirm change */}
      {showConfirmChangeModal && (
        <GenericModal
          title="Confirmar Cambio de Caja"
          onCancel={() => setShowConfirmChangeModal(false)}
          onContinue={cleanPhysicalCashRegister}
        >
          <p className="label">
            ¿Estás seguro que quieres cambiar de caja?. Los datos de la caja no
            se borrarán, solamente podrás seleccionar otra caja diferente para
            realizar ventas. La caja actual y sus datos no se borrarán y podrás
            volver a seleccionarla.
          </p>
        </GenericModal>
      )}
    </PageContainer>
  );
}
