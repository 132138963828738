import TermsAndConditions from "components/NewUser/TermsAndConditions/TermsAndConditions";
import Welcome from "components/NewUser/Welcome/Welcome";
import { useAppContext } from "contexts/AppContext";
import ChangePassword from "pages/Auth/ChangePassword/ChangePassword";
import EditMyProfile from "pages/Auth/EditMyProfile/EditMyProfile";
import React from "react";

const steps = {
  welcome: 0,
  termsAndConditions: 1,
  changePassword: 2,
  editMyProfile: 3,
};

export default function NewUser() {
  const { user } = useAppContext();
  const [step, setStep] = React.useState(steps.welcome);

  // Change to next step
  function nextNewUserStep() {
    if (step === steps.welcome && user.role === "pharmacy_owner") {
      // In welcome, go to terms if user is pharamcy owner
      setStep(steps.termsAndConditions);
    } else if (step === steps.welcome) {
      // In welcome go to change password
      setStep(steps.changePassword);
    } else if (step === steps.termsAndConditions) {
      // In terms, go to change password
      setStep(steps.changePassword);
    } else if (step === steps.changePassword) {
      // In change password go to edit profile
      setStep(steps.editMyProfile);
    }
  }

  // Update step status
  React.useEffect(() => {}, [step]);

  // Return component depending on the step
  switch (step) {
    case steps.welcome:
      return <Welcome nextNewUserStep={nextNewUserStep} />;

    case steps.termsAndConditions:
      return (
        <TermsAndConditions newUserMode nextNewUserStep={nextNewUserStep} />
      );

    case steps.changePassword:
      return <ChangePassword newUserMode nextNewUserStep={nextNewUserStep} />;

    case steps.editMyProfile:
      return <EditMyProfile newUserMode />;

    default:
      return <Welcome nextNewUserStep={nextNewUserStep} />;
  }
}
