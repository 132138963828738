/* eslint-disable prefer-destructuring */
import { Print, RemoveShoppingCart } from "@mui/icons-material";
import { Button, FormControlLabel, Switch } from "@mui/material";
import PageContainer from "components/General/PageContainer/PageContainer";
import { useAppContext } from "contexts/AppContext";
import printTicket from "helpers/print_ticket";
import React from "react";
import "./OrderedProducts.sass";

export default function OrderedProducts({
  returnProductsMode,
  returnProducts,
  addProductToReturn,
  removeProductToReturn,
}) {
  const { orderOnView, physicalCashRegister, user, pharmacy } = useAppContext();
  const products = orderOnView.products;
  const isSale = orderOnView.header.order_location === "pos";

  async function handlePrintTicker() {
    try {
      const paymentMethods = {
        cashMoney: orderOnView.header.cash_ammount,
        cardAMEXMoney: orderOnView.header.amex_ammount,
        cardVisaMoney: orderOnView.header.visa_ammount,
        cardMCMoney: orderOnView.header.mc_ammount,
        voucherMoney: orderOnView.header.voucher_ammount,
      };
      await printTicket(
        orderOnView.header.total_ammount + orderOnView.header.iva,
        products,
        user,
        pharmacy,
        physicalCashRegister,
        orderOnView.header.iva,
        orderOnView.header.total_ammount,
        orderOnView.header.order_id,
        paymentMethods,
        orderOnView?.header?.ordered_date
          ? new Date(orderOnView?.header?.ordered_date)
          : new Date()
      );
    } catch (error) {
      console.log(`error while printing ticket: ${error}`);
    }
  }

  return (
    <PageContainer smallMargins>
      <div className="order_products">
        <h1>{!isSale ? "Productos del pedido" : "Productos de la Venta"} </h1>
        <table className="ordered-products">
          <thead className="table-header">
            <tr className="row_header">
              <th scope="col">Imagen</th>
              <th scope="col name">Nombre</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Precio Unitario</th>
              <th scope="col">Precio Total</th>
              {returnProductsMode && <th scope="col">Devolución</th>}
            </tr>
          </thead>
          <tbody id="products">
            {products.map((product) => (
              <tr key={product.item_id}>
                <td>
                  <img
                    src={product.photo_url || "/imgs/no-image.png"}
                    alt="product"
                  />
                </td>
                <td className="name">{product.product_name}</td>
                <td>{product.quantity}</td>
                <td>${product.sold_at || product.price}</td>
                <td>
                  ${(product.sold_at || product.price) * product.quantity}
                </td>
                {/* Modo Regresar Productos */}
                {returnProductsMode && (
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!!product?.returned}
                          onChange={(e) => {
                            if (e.target.checked)
                              addProductToReturn(
                                product?.ordered_item_id,
                                product?.item_id,
                                product?.badge_id,
                                product?.price
                              );
                            else
                              removeProductToReturn(product?.ordered_item_id);
                          }}
                        />
                      }
                      label={product?.returned ? "Devuelto" : "Devolver"}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>

        {returnProductsMode && (
          <div className="return_products_inputs">
            {physicalCashRegister && (
              <Button
                onClick={handlePrintTicker}
                sx={{ mx: 1 }}
                variant="outlined"
                startIcon={<Print />}
              >
                Imprimir Ticket
              </Button>
            )}
            <Button
              onClick={returnProducts}
              variant="outlined"
              startIcon={<RemoveShoppingCart />}
            >
              Devolver Productos
            </Button>
          </div>
        )}
      </div>
    </PageContainer>
  );
}
