import axios from "axios";
import { checkResponse } from "helpers/requests";
import toast from "react-hot-toast";
import shortid from "shortid";

// Get low inventory notifications
async function getLowInventoryNotifications(userToken, pharmacy_id) {
  return new Promise((resolve, reject) => {
    const errorReturn = { status: false };

    // Make request
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/notifications/pos/low-inventory`;
    const headers = { authToken: userToken };
    const params = { pharmacy_id };
    axios
      .get(url, { headers, params })
      .then((res) => {
        // Check response
        if (!checkResponse(res)) reject(errorReturn);

        // Create notifications
        const products = res?.data?.body?.products;
        const notifications = [];
        products.forEach((product) => {
          notifications.push({
            id: shortid.generate(),
            type: "lowInventory",
            msg: `Producto agotado: ${product.product_name}`,
            data: product,
          });
        });
        resolve(notifications);
      })
      .catch((error) => {
        console.log("GET LOW INVENTORY ERROR", error);
        toast.error(
          "Hubo un error obteniendo notificaciones de inventario bajo"
        );
        reject(errorReturn);
      });
  });
}

/* 
Obtener notificaciones
Esta función llama a las funciones que obtienen notificaciones de diferentes tipos.
Los resultados de esas funciones se van acumulando en el array notifications
Cada notificación debe tener el formato:
{
  id, // Para usarlo como key cuando se mapeen en la lista de notificaciones y no marque error
  type, // Tipo de notificación
  msg, // Mensaje que aparecerá
  data, // Los datos que se necesitaran en algún punto, ejemplo para settear una variable onView
}
*/
export default async function getNotifications(
  userToken,
  pharmacy_id,
  setNotifications
) {
  let notifications = [];

  // Low inventory notifications
  const lowInv = await getLowInventoryNotifications(userToken, pharmacy_id);
  notifications = [...notifications, ...lowInv]; // Join notifications

  // Set all notifications in context
  setNotifications(notifications);
}
