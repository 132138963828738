import { Tab, Tabs, Box } from "@mui/material";
import React from "react";
import "./AccountingSection.sass";
import ProfitTable from "./ProfitTable/ProfitTable";
import UtilitiesByBatchTable from "./UtilitiesByBatchTable/UtilitiesByBatchTable";
import UtilitiesByProductTable from "./UtilitiesByProductTable/UtilitiesByProductTable";

export default function AccountingSection() {
  const [tableOnView, setTableOnView] = React.useState("profit");

  function renderTable(table) {
    switch (table) {
      case "profit":
        return <ProfitTable />;
      case "utilities-product":
        return <UtilitiesByProductTable />;
      case "utilities-batch":
        return <UtilitiesByBatchTable />;
      default:
        return <ProfitTable />;
    }
  }

  return (
    <div className="pharmacy_accounting">
      {/* Select table */}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tableOnView}
          onChange={(event, newValue) => setTableOnView(newValue)}
          aria-label="basic tabs example"
        >
          <Tab label="Contabilidad" value="profit" />
          <Tab label="Utilidad por Producto" value="utilities-product" />
          <Tab label="Utilidad por Lote" value="utilities-batch" />
        </Tabs>
      </Box>

      {/* Table on view */}
      {renderTable(tableOnView)}
    </div>
  );
}
