import React from "react";
import "./Inventory.sass";
import PageContainer from "components/General/PageContainer/PageContainer";
import { TextField, Button, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import { Link } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import AddIcon from "@mui/icons-material/Add";
import { useAppContext } from "contexts/AppContext";
import toast from "react-hot-toast";
import EmptyStateScreen from "components/General/EmptyStateScreen/EmptyStateScreen";
import FilterProductsModal from "components/Inventory/FilterProductsModal/FilterProductsModal";
import ProductTable from "components/ProductCatalogue/ProductTable/ProductTable";
import ChangePageBtn from "components/General/ChangePageBtn/ChangePageBtn";
import { Inventory2 } from "@mui/icons-material";
import ToolsBar from "components/General/ToolsBar/ToolsBar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

export default function Inventory() {
  // Variables
  const { pharmacy, inventory } = useAppContext();
  const [products, setProducts] = React.useState(inventory);
  const [showFilterModal, setShowFilterModal] = React.useState(false);

  // Current page showing
  const [page, setPage] = React.useState(1);
  const [onView, setOnView] = React.useState(products);

  // Filter values
  const [query, setQuery] = React.useState("");

  // Cambiar los productos cada vez que cambie la pagina
  React.useEffect(() => {
    const productsPerPage = 10;
    // Calcular desde donde se muestran los productos basandose en el num de pagina
    // Ej: page: 2   from: (2*5) - 5 = 5;
    const from = page * productsPerPage - productsPerPage;
    setOnView(Object.values(products).slice(from, from + productsPerPage));
  }, [page, products]);

  async function handleSearch(e) {
    try {
      // Prevenir que se refresque la pagina
      e.preventDefault();

      // Si la busqueda está restablecer los valores
      if (!validateInputs([query])) {
        setProducts(inventory);
        return;
      }

      // Buscar los productos en el backend
      const config = {
        params: {
          pharmacy_id: pharmacy.pharmacy_id,
          query_string: query,
        },
      };
      const url =
        "https://oqsh1tohdd.execute-api.us-east-1.amazonaws.com/v1/inventory/search";
      const res = await axios.get(url, config);

      // Checar si hay errores
      if (!checkResponse(res)) throw res;

      // Cambiar los productos
      setProducts(res?.data?.body?.products);
    } catch (res) {
      console.log("SEARCH INVENTORY ERROR:", res);
      toast.error(getResponseError(res), "Hubo un error con la búsqueda");
    }
  }

  return (
    <PageContainer>
      {/* ToolsBar */}
      <ToolsBar>
        <Link to="/product-catalogue" className="link">
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            className="tool_button"
            size="small"
          >
            Añadir Producto
          </Button>
        </Link>

        {/* Botón de para navegar a añadir producto */}
        <Link to="/inventory/register" className="link">
          <Button
            variant="outlined"
            startIcon={<Inventory2 />}
            className="tool_button"
            size="small"
          >
            Alta de inventario por factura
          </Button>
        </Link>

        {/* Proveedores */}
        <Link to="/providers" className="link">
          <Button
            variant="outlined"
            startIcon={<LocalShippingIcon />}
            className="tool_button"
            size="small"
          >
            Proveedores
          </Button>
        </Link>
      </ToolsBar>

      <div className="inventory">
        {/* Parte de arriba */}
        <form className="forms" onSubmit={(e) => handleSearch(e)}>
          {/* Buscar y ordenar por */}
          <TextField
            label="Buscar por nombre o código de barras"
            id="searchProductField"
            variant="outlined"
            className="text_input"
            size="small"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="outlined"
            startIcon={<TuneIcon />}
            onClick={() => setShowFilterModal(true)}
          >
            Filtrar Búsqueda
          </Button>
        </form>

        {/* Tabla de productos */}
        {onView.length >= 1 ? (
          <ProductTable products={onView} />
        ) : (
          <EmptyStateScreen
            imgSrc="/imgs/no-inventory.png"
            title="Aún no agregas productos al inventario de la farmacia"
            subtitle="Para gestionar y administrar tu inventario, empieza por agregar productos desde el catalogo general"
          />
        )}

        {/* Boton para paginacion de productos */}
        <ChangePageBtn
          page={page}
          setPage={setPage}
          data={products}
          isArray={false}
        />
      </div>

      {/* Fitler Products */}
      {showFilterModal && (
        <FilterProductsModal
          setShowFilterModal={setShowFilterModal}
          setProducts={setProducts}
          query={query}
          setQuery={setQuery}
          setPage={setPage}
          mode="inventory"
        />
      )}
    </PageContainer>
  );
}
